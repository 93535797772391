.chatResendModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;
}
.chatResendModal {
  position: relative;
  width: 100%;
  max-width: 570px;
  height: calc(100vh - 160px);
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .chatResendModalHeader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #EAEDF3;

    .chatResendModalHeaderTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;
      h3 {
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .chatResendModalHeaderBottom {
      display: flex;
      padding: 32px 32px 16px 32px;

      .chatResendModalHeaderBottomSearch {
        position: relative;
        width: 100%;

        i {
          position: absolute;
          top: 14px;
          left: 14px;
        }
        input {
          padding-left: 34px;
        }
      }
    }
  }
  .chatResendModalBody {
    margin-top: 143px;
    height: calc(100% - 143px);
    overflow: auto;

    .chatResendModalBodyEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 26px 32px;
      height: 100%;

      i {
        margin-bottom: 24px;

        svg path {
          fill: #7B8F9C;
        }
      }

      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        opacity: 0.65;
      }
    }
    .chatResendModalBodyItems {
      display: flex;
      flex-direction: column;

      .chatResendModalBodyItemsItem {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 32px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background: rgba(18, 128, 206, 0.05);

          i svg path {
            fill: #1280CE;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 32px;
          width: calc(100% - 64px);
          height: 1px;
          background: #EAEDF3;
        }
        i svg path {
          transition: all 0.3s ease-in-out;
        }
        .chatResendModalBodyItemsItemContent {
          display: flex;
          align-items: center;

          .chatResendModalBodyItemsItemContentAvatar {
            margin-right: 16px;
            width: 48px;
            height: 48px;
            background: #EAEDF3;
            border-radius: 48px;

            img {
              width: 100%;
              border-radius: 48px;
              object-fit: cover;
            }
          }
          .chatResendModalBodyItemsItemContentData {
            display: flex;
            flex-direction: column;

            p {
              color: #262626;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }
            span {
              color: #262626;
              font-size: 13px;
              line-height: 19px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}