.trainingTestStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    margin-bottom: 24px;
    width: 100%;
    max-width: 550px;
    border-radius: 5px;
  }
  h3 {
    margin-bottom: 8px;
    color: #262626;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 24px;

    li {
      padding: 0 12px;
      border-right: 1px solid #EAEDF3;

      &:last-child {
        border-right: none;
      }
      span, b {
        color: #262626;
        font-size: 13px;
        line-height: 150%;
      }
      span {
        margin-right: 4px;
      }
      b {
        font-weight: bolder;
      }
    }
  }
  .trainingTestStartDelimiter {
    margin-bottom: 24px;
    width: 100%;
    max-width: 550px;
    height: 1px;
    background: #EAEDF3;
  }
  p {
    margin-bottom: 24px;
    width: 100%;
    max-width: 550px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
  .trainingTestStartButtons {
    display: flex;
    align-items: center;

    div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}