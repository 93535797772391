@import "src/mixins/index.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  cursor: pointer;

  &:hover {
    svg, path {
      fill: var(--color-blue);
    }
  }
}

.hover-red {
  &:hover {
    svg, path {
      fill: var(--color-red);
    }
  }
}