@import "src/mixins/index.scss";

.actions {
    padding: 0 12px;
    .actionsWrapper {
        display: flex;
        align-items: center;

        .buttonsWrapper {
            display: flex;
            flex-grow: 1;
            gap: rem(16px);
        }

        p {
            margin-right: 24px;
            padding: 16px 0;
            color: #262626;
            font-size: 14px;
            line-height: 21px;
            flex-shrink: 0;

            @media (max-width: 1024px) {
                display: none;
            }
        }
    }

    .clearSelection {
        margin-top: rem(16px);
        cursor: pointer;
        font-size: 14px;
        color: var(--color-blue);
    }
}
.table {
    padding: 0 24px;

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0;
    }
    &.tableMobile {
        min-width: auto !important;
    }
    .thead {
        .tr {
            .th {
                align-items: center !important;
                padding: 12px 12px;
                color: rgba(38, 38, 36, 0.5);
                font-size: 10px;
                font-weight: 600;
                line-height: 10px;
                letter-spacing: 0.05em;
                text-align: left;
                text-transform: uppercase;
                cursor: pointer;

                @media (max-width: 1024px) {
                    &.hidden {
                        display: none !important;
                    }
                }
                .sort, .sortDesc, .sortAsc {
                    display: flex;
                    flex-direction: column;
                    margin-left: 8px;

                    i:last-child {
                        margin-top: 2px;
                        transform: rotate(180deg);
                    }
                }

                .sortDesc {
                    i:first-child {
                        display: none !important;
                    }

                    i:last-child {
                        margin-top: 0;
                    }
                }

                .sortAsc {
                    i:last-child {
                        display: none !important;
                    }
                }
            }
        }
    }
    .tbody {
        .empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px;

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;
                background: #E5F3FC;
                border-radius: 54px;

                svg {
                    width: 24px;
                    height: 24px;

                    path {
                        fill: #7B8F9C;
                    }
                }
            }
            p {
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 13px;
                text-align: center;
            }
        }
        .tr {
            min-height: 72px;

            &.rowLine {
                border-bottom: 1px solid var(--color-grayBlue);
            }

            &.unread {
                background-color: rgba(18, 128, 206, 0.05);
            }

            &.pointer {
                cursor: pointer;
            }

            &.test {
                background-color: black;
            }

            &:last-child {
                border-bottom: none;
            }

            .td {
                display: flex;
                flex-direction: column;
                padding: 16px 12px;
                color: #262626;
                font-size: 13px;
                font-weight: normal;
                line-height: 19px;
                word-break: break-word;

                @media (min-width: 768px) and (max-width: 1024px) {
                    padding: 16px 8px;
                }
                @media (max-width: 1024px) {
                    &.hidden {
                        display: none !important;
                    }
                }
                a {
                    color: #1280CE;
                    font-weight: 500;
                    line-height: 19px;
                }

                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 19px;
                }

                span {
                    color: #262626;
                    font-size: 13px;
                    line-height: 19px;
                    opacity: 0.5;
                }

                .toolbarContainer {
                    align-self: flex-end;
                    width: 100%;
                    max-width: 92px;

                    .toolbarContent {

                    }

                    .toolbar {
                        display: flex;
                        justify-content: flex-end;

                        & > * {
                            padding-left: rem(16px);
                        }

                        button {
                            svg path {
                                fill: #7B8F9C;
                            }

                            &:hover svg path {
                                fill: #1280CE;
                            }
                        }

                        .iconActive {
                            svg path {
                                fill: #1280CE;
                            }
                        }
                    }
                }
            }
        }
    }
    .th, .table:not(.tableMobile) .td {
        margin: 0;
        padding: 0.5rem;

        position: relative;

        .resizer {
            position: absolute;
            top: 0;
            right: -5px;
            width: 10px;
            height: 100%;
            opacity: 1;
            z-index: 1;
            touch-action: none;

            &.isResizing {
                background: red;
            }
        }
    }
    .th {
        &:last-of-type {
            .resizer {
                right: 0;
            }
        }
    }
}
.tableMobile {
    padding: 0;

    .actions {
        padding: rem(16px) rem(12px);

        .buttonsWrapper {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    .toolbarContainer.mobileToolbar {
        position: absolute;
        top: 16px;
        right: 16px;
        width: auto;
        max-width: 16px !important;
        display: none;
        flex-direction: column;

        @media (min-width: 768px) {
            & {
                right: 24px;
            }
        }
        &.mobileToolbarShow {
            display: flex;
        }
    }

    .tbody {
        &--mobile-tiles {
            padding: 16px;
            gap: 16px;
            display: flex;
            flex-wrap: wrap;
        }

        .tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            padding: rem(8px) rem(16px);
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            border-radius: 5px;
            font-size: rem(13px);
            position: relative;
            box-shadow: 0 1px 2px 0 #0000000D;

            &:first-child {
                border-radius: 0 0 5px 5px;
            }
            @media (min-width: 768px) {
                & {
                    padding-left: rem(24px);
                    padding-right: rem(24px);
                }
            }

            @media (min-width: 768px) and (max-width: 1024px) {
                flex-direction: row;
                flex-wrap: wrap;

                & .td {
                    width: 50% !important;
                }
            }
            &--mobile-tile {
                width: 100%;
                flex-grow: 0 !important;
                border-radius: 5px;
                // border: 1px solid #EAEDF3 !important;
                background-color: unset !important;

                & .td.marginRight {
                    margin-right: 0 !important;
                }
                @media (min-width: 768px) and (max-width: 1024px) {
                    flex-direction: column;
                    width: calc(50% - 8px);
                    border: 1px solid #EAEDF3 !important;

                    & .td {
                        width: 100% !important;
                    }
                }
            }

            .mobileCheckbox {
                position: absolute;
                top: rem(16px);
                width: 18px;
                height: 18px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #E8E8E8;

                &.active {
                    background-color: var(--color-blue);
                    border: none;
                }
            }
            .td {
                padding: rem(8px) 0;

                p {
                    font-weight: 500;
                    font-size: rem(14px);
                    line-height: 100%;
                    color: #262626;
                }
                span {
                    font-size: rem(13px);
                    line-height: 150%;
                    color: #262626;
                    opacity: 0.5;
                }
                &.marginLeft {
                    margin-left: 30px;
                }
                &.marginRight {
                    margin-right: 28px;
                }
            }
        }

        .rowLine {
            border-bottom: 1px solid var(--color-grayBlue);
        }
    }
}
.tags {
    padding: 0 0 10px 30px;
    border-bottom: 1px solid var(--color-grayBlue);
}
.td__grow_0 {
    flex-grow: 0 !important;
}