@import "src/mixins/index.scss";

.content {
  @include platform-spacing(padding);
  padding-top: rem(24px);

  .header {
    display: flex;
    align-items: center;
    margin-bottom: rem(24px);

    h1 {
      padding-left: 16px;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }
  }

  .form {
    margin-bottom: rem(24px);

    .line {
      height: 1px;
      border-bottom: 1px solid #EAEDF3;
    }

    .hint {
      color: #262626;
      opacity: 0.5;
      font-size: 13px;
      line-height: 150%;
    }

    .section {
      display: flex;

      .sectionName {
        width: 250px;
        color: #262626;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;

        @media (min-width: 768px) and (max-width: 1024px) {
          min-width: 180px;
          width: 180px;
        }
      }

      .rights {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 4px;

        label + label {
          margin-top: 23px;
        }
      }
    }

    .toolbar {
      padding: 1rem 1.5rem 0 rem(24px);
      margin: rem(32px) rem(-24px) 0;
      display: flex;
      border-top: 1px solid #EAEDF3;

      div + div {
        margin-left: 1rem;
      }
    }
  }

  &.mobile {
    .section {
      flex-direction: column;
      .sectionName {
        margin-bottom: rem(16px);
      }
    }
    .toolbar {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0;
        &:last-child {
          margin-bottom: rem(16px);
        }
      }
    }
  }
}

.mt1 {
  margin-top: 1rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mt15 {
  margin-top: rem(32px);
}

.mb05 {
  margin-bottom: rem(8px);
}

.blockName {
  color: #262626;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
