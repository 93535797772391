.chatContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  .chatContent {
    width: 100%;

    .chatEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      i {
        margin-bottom: 24px;

        svg path {
          fill: #7B8F9C;
        }
      }
      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        opacity: 0.65;
      }
    }
  }

  &.mobile {

  }
}