
  .chatWindowBodyItem {
    margin-bottom: 16px;

    &:first-child {
      margin-top: auto;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .chatWindowBodyItemRead {
      margin-top: 8px;
      margin-bottom: 24px;
      margin-left: -16px;
      padding: 6px 0;
      width: calc(100% + 32px);
      background: rgba(18, 128, 206, 0.05);
      color: #7B8F9C;
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
    }
    .chatWindowBodyItemMessage {
      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;

      &.chatWindowBodyItemMessageSelf {
        flex-direction: row-reverse;

        .chatWindowBodyItemMessageSelector {
          margin-right: 8px;
          margin-left: 16px;
        }
        .chatWindowBodyItemMessageContent {
          background: #1280CE;
          border: 1px solid transparent;
          box-shadow: none;

          p {
            color: #FFFFFF;
          }
          span {
            color: #FFFFFF;
          }
          a {
              color: #fff;

              &:hover {
                  text-decoration: underline;
              }
          }
        }
        span {
          margin-left: 0;
          margin-right: 8px;
        }
      }
      .chatWindowBodyItemMessageSelector {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        margin-right: 24px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: rgba(18, 128, 206, 0.15);
        cursor: pointer;
      }
      .chatWindowBodyItemMessageAvatar {
        margin-right: 8px;
        padding: 0;
        min-width: 40px;
        height: 40px;
        background: #EAEDF3;
        border-radius: 100px;

        img {
          width: 100%;
          border-radius: 100px;
          object-fit: cover;
        }
      }
      .chatWindowBodyItemMessageContent {
        margin-right: 8px;
        padding: 8px 12px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 15px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        white-space: pre-wrap;

        h5 {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;
        }
        .chatWindowBodyItemMessageContentReply {
          margin-bottom: 4px;
          padding: 8px 16px;
          width: 100%;
          background: #188FE3;
          border-radius: 5px;

          h5 {
            color: #FFFFFF;
          }
          p {
            color: #FFFFFF;
          }
        }
        p {
          color: #262626;
          font-size: 14px;
          font-weight: normal;
          line-height: 21px;
          word-break: break-word;
          hyphens: auto;
          hyphenate-limit-chars: 6 3 3;
          user-select: text;

          i {
            font-size: 20px;
          }
        }
        span {
          margin-top: 4px;
          color: #262626;
          font-size: 11px;
          font-weight: 500;
          line-height: 11px;
          opacity: 0.5;
        }

        a:hover {
          text-decoration: underline;
        }
      }
      span {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;
      }
      &.chatWindowBodyItemMessageSelected {
        background: rgba(18, 128, 206, 0.15);
        border-radius: 5px;

        .chatWindowBodyItemMessageSelector {
          background: #1280CE;
        }
      }
    }
  }
