.editor {
    margin-bottom: 8px;
    z-index: 106;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .label {
            display: block;
            margin-bottom: 4px;
            color: #262626;
            font-size: 13px;
            line-height: 18px;
            opacity: 0.75;

            .required {
                color: #FF6973;
            }
        }
        .menu {
            display: flex;
            align-items: center;

            .import,
            .experimentalSwitch {
                display: flex;
                align-items: center;
                margin-left: 16px;
                color: #1280CE;
                font-size: 13px;
                cursor: pointer;

                i svg path {
                    fill: #1280CE;
                }
                span {
                    margin-left: 4px;

                    &.desktop {
                        display: block;
                    }
                    &.mobile {
                        display: none;
                    }
                    @media (max-width: 768px) {
                        &.desktop {
                            display: none;
                        }
                        &.mobile {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
