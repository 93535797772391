.trainingTestViewReports {
  display: flex;
  flex-direction: column;

  .trainingTestViewReportsHead {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;

    .trainingTestViewReportsHeadItem {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      & > div {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
      .trainingTestViewReportsHeadItemInner {
        display: flex;
        align-items: center;
        width: 100%;

        & > div {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .trainingTestViewReportsHeadButtons {
      display: flex;
      align-items: center;
      margin-top: 8px;

      div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .trainingTestViewReportsBody {
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  .trainingTestViewReports {
    .trainingTestViewReportsHead {
      padding: 12px;
    }
  }
}