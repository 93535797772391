.react-date-picker,
.react-time-picker {
    width: 100%;
}

.react-datetimerange-picker__wrapper,
.react-daterange-picker__wrapper,
.react-date-picker__wrapper,
.react-time-picker__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    font-family: 'NotoSans', serif;
    font-size: 14px;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
}

.react-date-picker__wrapper:hover {
  border: 1px solid var(--color-input-border-hover);
  cursor: pointer;
}

.disabled .react-date-picker__wrapper:hover {
    border: 1px solid #E8E8E8;
    cursor: default;
}

.react-date-picker--disabled,
.react-time-picker--disabled {
    background: #FBFBFD;
}
.react-date-picker--open .react-date-picker__wrapper {
    border: 2px solid var(--color-input-border-focus);
}

.error .react-daterange-picker__wrapper,
.error .react-date-picker__wrapper,
.error .react-time-picker__wrapper {
    background-color: var(--color-alert-20);
}

.react-daterange-picker__inputGroup {
    padding: 0;
    min-width: auto;
    width: 100%;
}

.react-daterange-picker__inputGroup > input {
    color: #616060;
}

.react-daterange-picker__range-divider {
    margin: 0 1em;
}

.react-daterange-picker__calendar-button > svg,
.react-daterange-picker__clear-button > svg,
.react-date-picker__clear-button > svg,
.react-date-picker__calendar-button > svg,
.react-time-picker__clear-button > svg,
.react-time-picker__calendar-button > svg {
    stroke: #616060;
}

.react-calendar__empty .react-date-picker__inputGroup input::placeholder, .react-date-picker__inputGroup__divider {
    color: #262626;
    opacity: 0.5;
}
.react-calendar__not-empty .react-date-picker__inputGroup .react-date-picker__inputGroup__divider {
    opacity: 1;
}

.react-calendar__empty button.react-daterange-picker__clear-button,
.react-calendar__empty button.react-date-picker__clear-button,
.react-calendar__empty button.react-time-picker__clear-button {
    display: none;
}

.react-date-picker__calendar {
    top: 100% !important;
}

.react-date-picker, .react-datetime-picker {
    position: relative;

    @media (hover: none) {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}
