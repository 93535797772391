.colorPicker {
    width: 100%;

    .saturation, .hue, .alpha {
        position: relative;
        width: 100%;
        margin-bottom: 12px;
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
    }

    .saturation {
        height: 100px;
    }

    .hue, .alpha {
        height: 8px;
    }

    @media (max-width: 1024px) {
        .saturation > div > div > div:first-child + div {
            position: sticky !important;
            width: 24px;

            & > div {
                width: 24px !important;
                height: 24px !important;
            }
        }
        .hue, .alpha {
            margin-bottom: 0;
            height: 26px;

            & > div {
                top: 9px !important;
                height: 8px;

                & > div > div {
                    position: sticky !important;
                    width: 24px;

                    div {
                        position: absolute;
                        top: -8px;
                        width: 24px !important;
                        height: 24px !important;
                    }
                }
            }
        }
    }
}

.fieldsWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.fields {
    display: flex;
    margin-left: 40px;
};
.field {
    input {
        font-size: 11px;
        width: 38px;
        height: 26px;
        text-align: center;
        border-left: 1px solid #dadada;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        border-right: none;
    }

    &:first-of-type input {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    &:last-of-type input {
        border-right: 1px solid #dadada;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
};

.dropdown {
    svg path {
        stroke: rgba(38,38,38, 0.8)
    }
}

.dropdownMenu {
    left: 0;
    top: 24px !important;
    min-width: 64px !important;
}

.dropdownItem {
    display: flex;
    padding: 8px;
    cursor: pointer;

    &:hover {
        color: rgba(51, 139, 194, 1);
        background-color: rgba(39, 155, 217, 0.1);
    }

    .selected {
        color: rgba(51, 139, 194, 1);
        background-color: rgba(39, 155, 217, 0.1);
    }
}
