.trainingQuestionAdd {
  margin-top: 16px;

  .trainingQuestionAddDelimiter {
    margin: 8px 0;
    width: 100%;
    height: 1px;
    background: #EAEDF3;
  }
  .trainingQuestionAddQuestions {
    display: flex;
    flex-direction: column;

    .trainingQuestionAddQuestion {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        .trainingQuestionAddQuestionTitle {
          .trainingQuestionAddQuestionTitleLeft i {
            display: inherit !important;
          }
          .trainingQuestionAddQuestionTitleRight {
            display: flex;
          }
        }
      }
      img {
        margin-bottom: 16px;
        width: 160px;
        height: 90px;
        border-radius: 5px;
        object-fit: cover;
      }
      .trainingQuestionAddQuestionTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .trainingQuestionAddQuestionTitleLeft {
          display: flex;
          align-items: center;

          i {
            display: none !important;
            margin-right: 10px;
          }
          h3 {
            color: #262626;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .trainingQuestionAddQuestionTitleRight {
          display: none;
          align-items: center;

          li {
            margin-left: 16px;

            svg path {
              fill: #1280CE;
            }
            &:nth-child(4) {
              svg path {
                fill: #FF6973;
              }
            }
          }
        }
      }
      p {
        margin-top: 8px;
        color: #000000;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;

        span:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .trainingQuestionAddButton {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    .trainingQuestionAddButtonIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 22px;
      height: 22px;
      background: #50B678;
      border-radius: 22px;
    }
    p {
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;

      span {
        color: #1280CE;
        cursor: pointer;
      }
    }
  }
  .trainingQuestionAddForm {
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .trainingQuestionAddFormField {
      margin-bottom: 24px;
    }
    .trainingQuestionAddFormTop {
      padding: 24px;

      h3 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;

        &:last-child {
          margin-top: 24px;
        }
      }
    }
    .trainingQuestionAddFormBottom {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;

      button {
        margin-right: 16px;
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingQuestionAdd {
    .trainingQuestionAddQuestions {
      .trainingQuestionAddQuestion {
        padding: 12px;
      }
    }
    .trainingQuestionAddButton {
      padding: 24px 12px;
    }
    .trainingQuestionAddForm {
      .trainingQuestionAddFormTop {
        padding: 12px;

        h3 {
          text-align: center;
        }
      }
    }
  }
}