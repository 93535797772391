@import "src/mixins/index.scss";

.userContainer {
  width: 100%;

  h2 {
    font-size: rem(22px);
    font-weight: 500;
    margin-bottom: rem(25px);
    line-height: rem(30px);
  }

  .tableContainer {
    margin-top: 12px;
  }

  .table {
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      z-index: 1;
    }

    .filter {
      padding: 24px;
      border-bottom: 1px solid #EAEDF3;
    }

    .pagination {
      padding: 16px 24px;
      background: #FFFFFF;
      border-top: 1px solid #EAEDF3;
      border-radius: 0 0 5px 5px;
    }
  }
}

.nameCell {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #262626 !important;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
  }

  .description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    opacity: 0.5;
  }

  &:hover {
    .title {
      color: #1280CE;
    }
  }
}