@import "src/mixins/index.scss";

@mixin inputWrapper {
  text-align: left;
  width: 100%;
}

@mixin inputLabel {
  display: block;
  font-size: rem(13px);
  line-height: rem(18px);
  color: var(--color-textBlack);
  opacity: 0.75;
  margin-bottom: rem(4px);
}

@mixin input {
  height: rem(40px);
  font-size: rem(14px);
  line-height: rem(19px);
  background: var(--color-white);
  border: 1px solid var(--color-stroke);
  box-sizing: border-box;
  border-radius: rem(5px);
  padding: rem(10px) 0 rem(10px) rem(13px);
  width: 100%;
  box-shadow: none;
  appearance: none;

  &::-webkit-input-placeholder {
    color: var(--color-textBlack);
    opacity: .5;
  }
  &::-moz-placeholder {
    color: var(--color-textBlack);
    opacity: .5;
  }
  &:-moz-placeholder {
    color: var(--color-textBlack);
    opacity: .5;
  }
  &:-ms-input-placeholder {
    color: var(--color-textBlack);
    opacity: .5;
  }

  &:hover {
    border: 1px solid var(--color-blue);
  }

  &:focus {
    border: 2px solid var(--color-blue);
    outline: none;
  }

  &:disabled {
    background: var(--color-backgroundGray);
    border-color: var(--color-stroke);
    box-shadow: none;
  }
}

@mixin inputError {
  background: var(--color-alert-20);
  color: var(--color-alert);
  margin-bottom: rem(4px);
  border: none;

  &::-webkit-input-placeholder {
    color: var(--color-alert);
  }
  &::-moz-placeholder {
    color: var(--color-alert);
  }
  &:-moz-placeholder {
    color: var(--color-alert);
  }
  &:-ms-input-placeholder {
    color: var(--color-alert);
  }
}

@mixin inputErrorMessage {
  color: var(--color-alert);
  font-size: rem(12px);
  line-height: rem(16px);
}