.chatWindow {
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;

  .chatWindowHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;

    .chatWindowHeaderBackBtn {
      margin-right: 16px;
    }
    .chatWindowHeaderTarget {
      display: flex;
      align-items: center;
      cursor: pointer;

      .chatWindowHeaderTargetAvatar {
        position: relative;
        margin-right: 16px;
        width: 48px;
        height: 48px;
        background: #EAEDF3;
        border-radius: 100px;

        img {
          width: 100%;
          border-radius: 100px;
          object-fit: cover;
        }
        .chatWindowHeaderTargetStatusOnline,
        .chatWindowHeaderTargetStatusOffline {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 12px;
          height: 12px;
          border: 2px solid #FFFFFF;
          border-radius: 12px;
        }
        .chatWindowHeaderTargetStatusOnline {
          background: #50B678;
        }
        .chatWindowHeaderTargetStatusOffline {
          background: #FF6973;
        }
      }
      .chatWindowHeaderTargetContent {
        display: flex;
        flex-direction: column;

        p {
          color: #262626;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
        }
        span {
          font-size: 13px;
          line-height: 19px;

          &.chatWindowHeaderTargetContentStatusOnline {
            color: #1280CE;
          }
          &.chatWindowHeaderTargetContentStatusOffline {
            color: #262626;
            opacity: 0.5;
          }
        }
      }
    }
    .chatWindowHeaderMenu {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
          height: 15px;

          button svg path {
            fill: #7B8F9C !important;
          }
          button svg:hover path {
            fill: #1280CE !important;
          }
        }
      }
      .chatWindowHeaderMenuCalendar {
        & > div:first-child {
          display: block;
          margin-right: 0;
        }
        & > div:last-child {
          height: 0;

          & > div:first-child > div:first-child {
            display: none;
          }
        }
      }
    }
  }
  .chatWindowSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(38, 38, 38, 0.15);

    .chatWindowSearchInput {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 5px;

      i {
        width: 100% !important;
        cursor: pointer;
      }
      .chatWindowSearchInputSearch {
        max-width: 16px;
      }
      input {
        border: none;
        height: 24px;
        max-width: 150px;
      }
      .chatWindowSearchInputDelimiter {
        margin-right: 16px;
        height: 24px;
        width: 1px;
        background: #E8E8E8;
        border-radius: 1px;
      }
      p {
        margin-right: 16px;
        color: rgba(38, 38, 38, 0.65);
        font-size: 13px;
        line-height: 18px;
      }
      .chatWindowSearchInputDown,
      .chatWindowSearchInputUp {
        margin-right: 16px;
        max-width: 12px;
      }
      .chatWindowSearchInputUp {
        transform: rotate(180deg);
      }
      .chatWindowSearchInputClose {
        max-width: 20px;
      }
    }
  }
  .chatWindowSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(38, 38, 38, 0.15);

    .chatWindowSelectedContainer {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;

          svg path {
            fill: #FF6973;
          }
        }
        svg path {
          fill: #1280CE;
        }
        span {
          margin-right: 6px;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: #1280CE;
          border-radius: 100px;
          color: #FFFFFF;
          font-size: 13px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
  }
  .chatWindowBody {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    height: 100%;
    overflow: auto;

    .chatWindowBodyEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        margin-bottom: 16px;
        width: 56px;
        height: 56px;
      }
      p {
        margin-bottom: 4px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
      }
      span {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
        opacity: 0.65;
      }
    }
    .chatWindowBodyItem {
      margin-bottom: 16px;

      &:first-child {
        margin-top: auto;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .chatWindowBodyItemRead {
        margin-top: 8px;
        margin-bottom: 24px;
        margin-left: -16px;
        padding: 6px 0;
        width: calc(100% + 32px);
        background: rgba(18, 128, 206, 0.05);
        color: #7B8F9C;
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
      }
      .chatWindowBodyItemDate {
        margin-bottom: 16px;
        width: 100%;
        color: #7B8F9C;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        transition: all 0.3s ease-in-out;
      }
      .chatWindowBodyItemMessage {
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;

        &.chatWindowBodyItemMessageSelf {
          flex-direction: row-reverse;

          .chatWindowBodyItemMessageSelector {
            margin-right: 8px;
            margin-left: 16px;
          }
          .chatWindowBodyItemMessageContent {
            background: #1280CE;
            border: 1px solid transparent;
            box-shadow: none;

            p {
              color: #FFFFFF;
            }
            span {
              color: #FFFFFF;
            }
          }
          span {
            margin-left: 0;
            margin-right: 8px;
          }
        }
        .chatWindowBodyItemMessageSelector {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          margin-right: 24px;
          width: 24px;
          height: 24px;
          border-radius: 24px;
          background: rgba(18, 128, 206, 0.15);
          cursor: pointer;
        }
        .chatWindowBodyItemMessageAvatar {
          margin-right: 8px;
          padding: 0;
          min-width: 40px;
          height: 40px;
          background: #EAEDF3;
          border-radius: 100px;

          img {
            width: 100%;
            border-radius: 100px;
            object-fit: cover;
          }
        }
        .chatWindowBodyItemMessageContent {
          margin-right: 8px;
          padding: 8px 12px;
          background: #FFFFFF;
          border: 1px solid #EAEDF3;
          border-radius: 15px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

          h5 {
            color: #1280CE;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
          }
          .chatWindowBodyItemMessageContentReply {
            margin-bottom: 4px;
            padding: 8px 16px;
            width: 100%;
            background: #188FE3;
            border-radius: 5px;

            h5 {
              color: #FFFFFF;
            }
            p {
              color: #FFFFFF;
            }
          }
          p {
            color: #262626;
            font-size: 14px;
            font-weight: normal;
            line-height: 21px;
            word-break: break-all;
            user-select: text;

            i {
              font-size: 20px;
            }
          }
          span {
            margin-top: 4px;
            color: #262626;
            font-size: 11px;
            font-weight: 500;
            line-height: 11px;
            opacity: 0.5;
          }
        }
        span {
          color: #262626;
          font-size: 13px;
          line-height: 19px;
          opacity: 0.5;
        }
        &.chatWindowBodyItemMessageSelected {
          background: rgba(18, 128, 206, 0.15);
          border-radius: 5px;

          .chatWindowBodyItemMessageSelector {
            background: #1280CE;
          }
        }
      }
    }
  }
  .chatWindowFooter {
    position: relative;
    padding: 8px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;

    .chatWindowFooterMessage {
      display: flex;
      align-items: center;
      height: 56px;

      .chatWindowFooterMessageCross {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .chatWindowFooterMessageEdit {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        width: 100%;
        max-width: 720px;
        background: #FBFBFD;
        border-radius: 5px;

        label {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 150%;
        }
        p {
          color: #262626;
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
    .chatWindowFooterSend {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;

      & > div:first-child {
        margin-right: 8px;
        cursor: pointer;

        &:hover + .chatWindowFooterEmodji {
          display: block;
        }
      }
      .chatWindowFooterEmodji {
        display: none;
        position: absolute;
        bottom: 18px;
        left: 16px;
        padding-bottom: 54px;
        width: 100%;
        max-width: 276px;
        height: 250px;
        z-index: 10;

        &:hover {
          display: block;
        }
        .chatWindowFooterEmodjiItems {
          display: flex;
          flex-wrap: wrap;
          align-content: baseline;
          padding: 8px;
          height: 100%;
          background: #FFFFFF;
          border: 1px solid #EAEDF3;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          overflow: auto;

          .chatWindowFooterEmodjiItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            font-size: 20px;
            cursor: pointer;
            transition: background 0.3s ease-in-out;

            &:hover {
              background: rgba(18, 128, 206, 0.05);
              border: 1px solid #EAEDF3;
            }
          }
        }
      }
      .chatWindowFooterInput input {
        border: none;
      }
      & > div:last-child {
        margin-left: 24px;
      }
    }
  }
  .scrollTopBtn {
    position: fixed;
    bottom: 80px;
    right: 24px;
    cursor: pointer;

    div:first-of-type {
      margin-bottom: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: #50B678;
      border-radius: 9999px;

      & svg path {
        fill: #FFFFFF;
      }
    }
  }
}
