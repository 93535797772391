.scrollTopBtn {
    position: fixed;
    bottom: 24px;
    right: 24px;
    font-weight: 500;
    color: #1280ce;
    text-align: center;
    cursor: pointer;

    @media (max-width: 768px) {
        display: none;
    }

    div:first-of-type {
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: #1280CE;
        border-radius: 9999px;

        & svg path {
            fill: #fff;
        }
    }
}
