@import "src/mixins/index.scss";

.activity {
  padding: 24px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #EAEDF3;
  background: #FFFFFF;
  overflow: auto;

  .items {
    display: flex;
    flex-direction: column;

    .item {
      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin-bottom: 24px;
        margin-left: 0;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-align: left;
      }

      .users {
        .user {
          margin-bottom: 8px;

          .head {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .avatar {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              background: #ABC9D7;
              background-size: cover;
              border-radius: 32px;
            }

            a {
              color: #1280CE;
              font-size: 12px;
              font-weight: 500;
              line-height: 150%;
            }
          }

          .records {
            display: flex;
            flex-direction: column;

            .record {
              display: flex;
              align-items: center;
              margin-bottom: 16px;

              span {
                margin-right: 8px;
                color: #262626;
                font-size: 12px;
                line-height: 150%;
                opacity: 0.5;
              }

              p {
                color: #262626;
                font-size: 12px;
                line-height: 150%;
                word-break: break-all;
              }

              a {
                color: #1280CE;
                font-size: 12px;
                line-height: 150%;
                word-break: break-all;
              }
            }
          }

          .body {
            display: flex;
            padding-top: 20px;

            span {
              @include text-small;
              color: #9F9F9F;
            }

            .separator {
              margin: -20px 12px 0 10px;
              width: 2px;
              height: 50px;
              background: #E7E7E7;

              &:before {
                content: "";
                display: block;
                margin-top: 23px;
                margin-left: -3px;
                width: 8px;
                height: 8px;
                background: #EDF1F5;
                border-radius: 8px;
              }
            }

            p {
              @include text-small;
              color: #9F9F9F;

              a {
                color: #279BD9;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}