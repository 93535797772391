@import "src/mixins/index.scss";

.commentsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 88px);
}

.comments {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-left: 32px;
  padding-right: 32px;
}

.commentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 56px;
  border-top: 1px solid #EAEDF3;

  input {
    width: 100%;
    height: 100%;
    padding: 16px;
    border: 0;
  }

  .commentSubmitButton {
    height: 100%;
    padding-left: 8px;
    padding-right: 26px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.modalOverlay {
  position: fixed;
  padding: rem(32px);
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background: rgba(#275677, 0.65);
  z-index: 10;

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
}

.modal {
  position: relative;
  padding: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 770px;
  background-color: #fff;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding-top: rem(24px);
  padding-left: rem(24px);
  padding-right: rem(24px);

  h3 {
    font-size: rem(22px);
    font-weight: 500;
  }

  .closeIcon {
    cursor: pointer;
  }
}

.modalBody {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: rem(32px);

  video {
    width: 100%;
  }

  .inputWrapper {
    width: 100%;
    padding-bottom: rem(24px);
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: rem(16px) rem(24px);
  border-top: 1px solid #EAEDF3;

  > *:not(:last-child) {
    margin-right: rem(16px);
  }
}