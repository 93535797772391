@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-end-p1 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-end-p2 {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 40;
  }
}

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40;
  }
  100% {
    stroke-dashoffset: 240;
  }
}