.modalContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 32px 0;
    width: 570px;

    .modalContentTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        padding: 0 24px;

        h3 {
            color: #262626;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
        }
    }
    .modalContentRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0 32px;

        &:last-child {
            margin-bottom: 0;
        }
        span {
            color: #262626;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            opacity: 0.5;
        }
        p {
            margin: 0;
            color: #262626;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }
}
