@import './rem.scss';

@mixin text-smallest {
  font-family: 'NotoSans', sans-serif;
  font-size: rem(10px);
  line-height: rem(11px);
}

@mixin text-small {
  font-family: 'NotoSans', sans-serif;
  font-size: rem(12px);
  line-height: rem(14px);
}

@mixin text-normal {
  font-family: 'NotoSans', sans-serif;
  font-size: rem(14px);
  line-height: rem(16px);
}

@mixin text-middle {
  font-family: 'NotoSans', sans-serif;
  font-size: rem(16px);
  line-height: rem(18px);
}

@mixin text-large {
  font-family: 'NotoSans', sans-serif;
  font-size: rem(21px);
  line-height: rem(24px);
}

@mixin header-large {
  font-family: 'NotoSans', serif;
  font-size: rem(24px);
  line-height: rem(33px);
  font-weight: 600;
}

@mixin header-normal {
  font-family: 'NotoSans', serif;
  font-size: rem(18px);
  line-height: rem(17px);
}

@mixin content {
  padding: rem(24px);

  p {
    margin-bottom: rem(20px);
    font-size: rem(14px);
    line-height: rem(21px);
  }


  h1, h2, h3, h4, h5, h6 {
    margin: inherit;
    padding: inherit;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  h5 {
    font-size: 13px;
    font-weight: 500;
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  ol {
    list-style: decimal;
    padding: inherit;
  }

  ul {
    list-style: initial;
    padding: inherit;
  }

  li {
    line-height: 1;
  }

  table, td {
    border: 1px solid #ccc;
  }

  table td, table th {
    padding: 4px;
  }

  table[border="0"] {
    td {
      border: none;
    }

    border: none;
  }

  sub {
    vertical-align: sub;
    font-size: 0.85em;
  }

  sup {
    vertical-align: super;
    font-size: 0.85em;
  }

  i {
    font-style: italic;
  }
}


@mixin platform-spacing ($property) {
  #{$property}: rem(16px);
  @media (min-width: 1024px) {
    #{$property}: rem(24px);
  }
}

@mixin invisible-scroll() {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}
