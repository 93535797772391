@import "src/mixins/index.scss";

.loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 111;
}

.smallLoader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100svh - 57px);

  &.withNewsTicker {
    min-height: calc(100svh - 90px) !important;
}

  .projects {
    @include platform-spacing(padding);
    padding-top: rem(24px);

    .pageTitle {
      @include header-large;
      margin: 0 0 1rem 0;
    }

    .projectCards {
      display: grid;
      gap: rem(24px);
      grid-template-columns: 1fr;
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: 1920px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      // ie 10-11
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .projectCardWrapper {
        min-width: 300px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex-grow: 1;
          flex-basis: 40%;
        }
      }
    }
  }
}
