$kms--standard-easing: cubic-bezier(0.5, 0, 0.1, 1);
$kms--ease-out: cubic-bezier(0, 0, 0.25, 1);

@mixin animation__loading--spin {
  animation-name: rotate;
  animation-duration: 690ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  svg circle {
    animation-name: init-stroke;
    animation-duration: 10ms;
    animation-timing-function: $kms--standard-easing;
  }
}

@mixin animation__loading--stop {
  animation: rotate-end-p1 700ms $kms--ease-out forwards,
  rotate-end-p2 700ms $kms--ease-out 700ms forwards;

  & svg circle {
    animation-name: stroke-end;
    animation-duration: 700ms;
    animation-timing-function: $kms--ease-out;
    animation-delay: 700ms;
    animation-fill-mode: forwards;
  }
}