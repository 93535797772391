@import "src/mixins/index.scss";

.integrationsModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;
}

.integrationsModal {
  position: relative;
  width: 100%;
  max-width: 600px;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .integrationsModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    padding: 24px 24px 0 24px;

    h3 {
      margin-bottom: 0;
      color: #262626;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .integrationsModalBody {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      width: 100%;
      max-width: 270px;
      height: 170px;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border: 2px solid #1280CE;

        svg path {
          fill: #1280CE;
        }
      }

      i {
        margin-bottom: 16px;
      }

      span {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);

  .integrationsTitle {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .integrationsTitleIcon {
      margin-right: 16px;
    }

    h1 {
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }

  .integrationsWrapper, .integrationsWrapperProcess {
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .integrationsWrapperNotification {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding: 16px 24px;
      background: #FBFBFD;
      border-radius: 5px;

      i {
        margin-right: 8px;

        svg path {
          fill: #1280CE;
        }
      }

      span {
        color: #000000;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;
      }
    }

    .integrationsWrapperBlock {
      position: relative;
      margin-bottom: 8px;
      @include platform-spacing(padding);
      padding-top: rem(24px);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
        background: #EAEDF3;
      }

      &:nth-child(3):after {
        display: none;
      }

      h2 {
        margin-bottom: 32px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .integrationsWrapperBlockRow {
        display: flex;

        label {
          display: block;
          margin-bottom: 4px;
          color: #262626;
          font-size: 13px;
          line-height: 18px;
          opacity: 0.5;
        }

        .host {
          margin-bottom: 24px;
          margin-right: 24px;
          width: 100%;
          max-width: 280px;
        }

        .port {
          margin-bottom: 24px;
          width: 100%;
          max-width: 120px;
        }

        .login {
          margin-bottom: 8px;
          margin-right: 24px;
          width: 100%;
          max-width: 420px;
        }

        .password {
          margin-bottom: 8px;
          width: 100%;
          max-width: 420px;
        }

        .interval {
          margin-bottom: 24px;
          margin-right: 24px;
          width: 100%;
          max-width: 420px;
        }

        .time {
          margin-bottom: 24px;
          width: 100%;
          max-width: 420px;
        }

        .fromDate {
          margin-bottom: 8px;
          margin-right: 24px;
          width: 100%;
          max-width: 420px;
        }

        .toDate {
          margin-bottom: 8px;
          width: 100%;
          max-width: 420px;
        }

        .time, .fromDate, .toDate {
          & > div:first-child {
            margin-bottom: 4px;
            color: #262626;
            font-size: 13px;
            line-height: 18px;
            opacity: 0.5;
          }
        }
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 24px;
          width: 100%;
          max-width: 270px;
          height: 170px;
          background: #FFFFFF;
          border: 1px solid #EAEDF3;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border: 2px solid #1280CE;

            svg path {
              fill: #1280CE;
            }
          }

          div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            i {
              margin-right: 16px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          span {
            padding: 0 24px;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: center;
          }
        }
      }
    }

    .integrationsWrapperButtons {
      display: flex;
      align-items: center;
      @include platform-spacing(padding);
      padding-top: rem(16px);
      padding-bottom: rem(16px);
      border-top: 1px solid #EAEDF3;

      button:first-child {
        margin-right: 16px;
      }
    }
  }

  .integrationsWrapperProcess {
    .integrationsWrapperNotification {
      margin: 24px 24px 32px 24px;
    }

    h3 {
      margin-bottom: 8px;
      padding: 0 24px;
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
    }

    .integrationsWrapperLoader {
      position: relative;
      margin: 0 24px 8px 24px;
      width: calc(100% - 48px);
      height: 24px;
      background: #EAEDF3;
      border-radius: 6px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: repeating-linear-gradient(45deg, #298CD3, #298CD3 24px, #1280CE 0, #1280CE 40px);
        border-radius: 6px;
        transition: all 1s ease-in-out;
      }
    }

    p {
      margin-bottom: 32px;
      padding: 0 24px;
      color: #000000;
      font-size: 14px;
      line-height: 19px;
      opacity: 0.5;
    }
  }

  &.mobile {
    .integrationsWrapperBlockRow {
      flex-wrap: wrap;
      > * {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: none !important;
      }
    }
    .integrationsComponents {
      flex-direction: column;
      > * {
        max-width: none !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        &:not(:last-child) {
          margin-bottom: rem(16px);
        }
      }
    }
    .integrationsWrapperButtons {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        &:last-child {
          margin-bottom: rem(16px);
        }
      }
    }
  }
}