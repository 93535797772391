.splitter-layout {
    width: 100%;
}

.splitter-layout > .layout-splitter {
    width: 9px;
    border-left: 4px solid #fff;
    background-color: #EAEDF3;
    border-top: 0;
    border-bottom: 0;
    border-right: 4px solid #fbfbfd;
}

.splitter-layout > .layout-splitter:hover {
    border-color: #EAEDF3;
    background-color: #EAEDF3;
}