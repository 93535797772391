.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;

    h2 {
        margin-right: 8px;
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
    }
}

.modalBody {
    padding: 24px;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid #eaedf3;

    div {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    .left {
      justify-content: flex-start;
    }

    .center {
      justify-content: center;
    }

    .between {
      justify-content: space-between;
    }
}
