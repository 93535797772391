@import "src/mixins/index.scss";

.toast {
  width: 100%;
  max-width: 472px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: rem(16px);
  margin-bottom: rem(16px);
  border-radius: 5px;
  background-color: #aaa;

  @media print {
    display: none;
  }

  svg path {
    fill: #fff;
  }

  & > div:last-of-type {
    padding-left: 16px;
    cursor: pointer;
  }

  &.red {
    background-color: rgba(#F45633, 0.8);
  }

  &.green {
    background-color: rgba(#40A668, 0.8);
  }

  &.blue {
    background-color: rgba(#1280CE, 0.8);
  }
}

.toastMessage {
  display: flex;
  align-items: center;
  color: #fff;

  i {
    margin-right: 16px;

  }
}