@import "src/mixins/index.scss";

.content {
  .field {
    margin-bottom: rem(8px);
  }


    .projectsContainer {
        margin-top: rem(16px);
        display: flex;
        flex-direction: column;

        .projectContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: rem(56px);
            border-bottom: 1px solid #EAEDF3;

            .projectInfo {
                cursor: pointer;
                display: flex;
                align-items: center;

                .projectName {
                    font-size: 13px;
                    line-height: 150%;
                }

                img {
                    width: rem(24px);
                    height: rem(24px);
                    border-radius: 50%;
                    margin-right: rem(8px);
                }
            }

            .projectButtons {
                display: flex;
                align-items: center;

                p {
                    font-size: 13px;
                    line-height: 100%;
                    margin-left: rem(8px);
                }

                .buttonsContainer {
                    display: flex;
                    align-items: center;
                    margin-left: 16px;
                    height: 32px;

                    .iconButton {
                        margin-left: 16px;
                    }
                }
            }
        }
    }


    .infoPanel {
        width: 100%;
        height: 53px;
        background: #FBFBFD;
        border-radius: 5px;
        display: flex;
        padding-left: 19px;
        align-items: center;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
        span {
            margin-left: 10px;
        }
    }

    .input {
        width: 420px;
        margin-bottom: 16px;
    }

    .addButton {
        display: flex;
        align-items: center;
        height: rem(56px);
        background: rgba(39, 155, 217, 0.1);
        border: 1px solid rgba(39, 155, 217, 0.15);
        border-radius: 5px;
        cursor: pointer;
        padding: rem(18px) rem(16px);

        p {
            color: #1280CE;
            font-size: 14px;
            line-height: 19px;
            margin-left: rem(8px);
        }
    }

  .titleBlock {
    font-weight: 600;
    font-size: rem(14px);
    line-height: 150%;
    margin-bottom: 1rem;
  }

  .divider {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 0;
  }

  .tool {
    display: flex;
    padding-top: 1rem;

    button {
        margin-right: 1rem;
    }
  }

  &.mobile {
    .avatarPanel {
      align-items: flex-start;
      .infoContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: rem(32px);

        > *:not(input) {
          width: 166px;

          button {
            width: 100%;
          }
        }
      }
    }

    .tool {
      flex-direction: column-reverse;
      & > div:first-child {
        margin-top: rem(16px);
      }
    }
  }
}


