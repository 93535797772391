@import "src/mixins/index.scss";

.title {
  @include header-large;
  width: 100%;
  padding-bottom: rem(24px);
}

.table {
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }

  .filter {
    padding: rem(24px);
    border-bottom: 1px solid #EAEDF3;
  }


  .pagination {
    padding: 16px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-radius: 0 0 5px 5px;
  }
}

.removingMessage {
  p {
    padding-bottom: 8px;
  }
}

.nameCell {
  &:hover {
    .fileName {
      color: #1280CE;
    }
  }

  &.mobileNameCell {
    .iconWrapper {
      align-self: center;
    }
  }
}

.nameCell, .fileWrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .iconWrapper {
    padding-right: rem(12px);
  }

  .descWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .fileName {
    padding-bottom: rem(8px);
    font-size: rem(14px);
    font-weight: 500;
  }

  .fileDescription {
    color: rgba(#262626, 0.5);

    .fileType {
      text-transform: uppercase;
    }
  }
}

.emptyId {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.modal {
  position: relative;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 568px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.modalOverlay {
  position: fixed;
  padding: rem(32px);
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background: rgba(#275677, 0.65);
  z-index: 10;

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
}

.editModal {
  position: relative;
  padding: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 770px;
  background-color: #fff;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.editModalOverlay {
  position: fixed;
  padding: rem(32px);
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background: rgba(#275677, 0.65);
  z-index: 1000;

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
}

.subheader {
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #262626;
  opacity: 0.5;
  margin-bottom: rem(6px);
}