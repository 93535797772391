@import "src/mixins/index.scss";

.body {
  position: relative;
  // top: 57px;
  // height: 100%;
  max-height: calc(100svh - 57px);
  min-height: calc(100svh - 57px);
  //height: -moz-available;
  //height: -webkit-fill-available;
  //height: fill-available;
  height: 100svh;
  background: #FBFBFD;

  &.mobileBody {
    top: 57px;
    // height: calc(100% - 57px);
    // max-height: initial;
    // min-height: initial;
  }
}

.scrollable {
  overflow: auto;
}

.withNewsTicker {
  max-height: calc(100svh - 86px);
  min-height: calc(100svh - 86px);
}
