.base {
    padding: 24px;

    h1 {
        font-weight: 500;
        font-size: 22px;
    }
    & > * + * {
        margin-top: 23px;
    }
    .info {
        padding: 24px;
        border: 1px solid #EAEDF3;
        background-color: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        .row {
            display: flex;
            color: #262626;

            & > div:first-child {
                flex-grow: 1;
                opacity: 0.5
            }
        }
        & > div {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .activeLicenses {
        .subheader {
            font-weight: 600;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #262626;
            opacity: 0.5;
            margin-bottom: rem(6px);
        }
        .activeLicensesTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            .addBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 9px 16px;
                background: #50B678;
                border: 1px solid transparent;
                border-radius: 5px;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;

                &:hover {
                    background: #52C57F;
                    box-shadow: 0 2px 4px rgba(80, 182, 120, 0.35);
                }
                &:focus {
                    background: #42B36E;
                }
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 6px;
                    width: 16px;
                    height: 16px;
                    background: #FFFFFF;
                    border-radius: 16px;

                    svg path {
                        fill: #50B678;
                    }
                }
            }
        }
        & .filter {
            display: flex;
            justify-content: space-between;
            padding: 25px;

            .search {
                display: flex;
                align-items: center;
                padding: 10px 14px;
                width: 100%;
                max-width: 360px;
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                border-radius: 5px;
                height: 40px;

                .searchButton {
                    display: inherit;
                    margin-right: 8px;
                }
                input {
                    width: 100%;
                    border: 0;
                    color: #262626;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 19px;
                    outline: none;

                    &::placeholder {
                        color: #262626;
                        opacity: 0.4;
                    }
                }
                &:hover {
                    border: 1px solid #1280CE;
                }
                &:focus-within {
                    border: 2px solid #1280CE;
                    padding: 9px 13px;
                }
            }
        }
        & .paging {
            padding: 16px 25px;
        }
    }
}
@media (max-width: 768px) {
    .base {
        padding: 12px;

        .info {
            padding: 12px;
        }
        .activeLicenses {
            .activeLicensesTitle {
                flex-direction: column;

                h1 {
                    margin-bottom: 12px;
                    text-align: center;
                }
            }
        }
    }
}