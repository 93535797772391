@import "src/mixins/index.scss";

.commentContainer {
    .commentator {
        margin-bottom: 0.5rem;
    }

    .text {
        line-height: 21px;
        margin-bottom: 0.5rem;
        white-space: pre-line;
    }

    .updateTime {
        margin-bottom: 0.5rem;
        opacity: 0.5;
    }

    .replyInput {
        margin-bottom: 0.5rem;
    }

    .replyBlock {
        margin-bottom: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .editButtonsBlock {
            display: flex;
            width: 100%;
        }

        .reply {
            color: var(--color-blue);
            cursor: pointer;
            margin-right: 1rem;
            display: flex;
            align-items: center;

            .desktop {
                display: block;
            }
            .mobile {
                display: none;
            }
            @media (max-width: 767px) {
                margin-right: rem(4px);

                .desktop {
                    display: none;
                }
                .mobile {
                    display: block;
                }
                &.replyNext {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 4px;
                    min-width: 32px;
                    height: 32px;

                    i {
                        margin-right: 0;
                    }
                    span {
                        display: none;
                    }
                    &.like {
                        margin-left: 8px;
                    }
                }
            }
            i {
                margin-right: 4px;

                svg path {
                    fill: var(--color-blue);
                }
            }
            b {
                margin-left: 4px;
                min-width: 16px;
                font-style: normal;
            }
        }

        .removeButton {
            color: var(--color-red);

            i {
                svg path {
                    fill: var(--color-red);
                }
            }
        }

        .dislike {
            i {
                svg path {
                    fill: var(--color-red);
                }
            }
        }

        .like {
            i {
                svg path {
                    fill: var(--color-green);
                }
            }
        }

        .disabled {
            color: var(--color-gray-light);

            i svg path {
                fill: var(--color-gray-light);
            }
        }

        .noAction {
            cursor: default;
            color: var(--color-gray-light);
        }

        .likesBlock {
            display: flex;
            cursor: pointer;

            i {
                margin-right: 6px;

                path {
                    fill: var(--color-red);
                }
            }

            .likes {
                color: var(--color-gray-light);
            }

            div + div.likes {
                margin-left: 4px;
            }
        }

        .liking {
            cursor: progress;

            i {
                path {
                    fill: var(--color-gray-light);
                }
            }
        }
    }
}

.child {
    padding-left: 1.5rem;
    border-left: 1px solid var(--color-grayBlue);
}
