.trainingTestEnd {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: auto;

  .trainingTestEndContainer {
    width: 100%;
    max-width: 970px;

    h3 {
      margin-top: 72px;
      margin-bottom: 8px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
      text-align: center;
    }
    .trainingTestEndContainerInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      margin-bottom: 16px;

      li {
        padding: 0 16px;
        border-right: 1px solid #EAEDF3;

        &:last-child {
          border-right: none;
        }
        span {
          margin-right: 4px;
          color: #262626;
          font-size: 13px;
          line-height: 150%;
        }
        b {
          color: #262626;
          font-size: 13px;
          font-weight: bolder;
          line-height: 150%;
        }
      }
    }
    .trainingTestEndContainerDescription {
      margin-bottom: 24px;
      color: #262626;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    .trainingTestEndContainerDelimiter {
      margin-bottom: 24px;
      width: 100%;
      height: 1px;
      background: #EAEDF3;
    }
    .trainingTestEndContainerButtons {
      display: flex;
      justify-content: center;
      margin-bottom: 64px;

      & > div:first-child {
        margin-right: 16px;

        svg path {
          fill: #1280CE;
        }
      }
    }
    .trainingTestEndContainerMarks {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 56px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px 8px 4px;
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        color: #262626;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;

        &.trainingTestEndContainerMarksTrue {
          background: #F6FBF8;
          color: #42B26E;
        }
        &.trainingTestEndContainerMarksFalse {
          background: #FFF7F8;
          color: #FF6973;
        }
      }
    }
    .trainingTestEndContainerQuestions {
      display: flex;
      flex-direction: column;

      .trainingTestEndContainerQuestionsItem {
        margin-bottom: 16px;
        padding: 24px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        &:last-child {
          margin-bottom: 72px;
        }
        h4 {
          margin-bottom: 24px;
          color: #262626;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
        }
        .trainingTestEndContainerQuestionsItemDelimiter {
          margin-bottom: 24px;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        .trainingTestEndContainerQuestionsItemDescription {
          margin-bottom: 24px;
          color: rgba(38, 38, 38, 0.5);
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
        .trainingTestEndContainerQuestionsItemCorrect {
          color: #50B678;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
        .trainingTestEndContainerQuestionsItemIncorrect {
          color: #FF6973;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
        .trainingTestEndContainerQuestionsItemMany,
        .trainingTestEndContainerQuestionsItemOne {
          ul {
            display: flex;
            flex-direction: column;

            li {
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              label {
                margin-right: 8px;
                margin-bottom: 0;
                width: 24px;
                height: 24px;

                div {
                  width: 100%;
                  height: 100%;

                  &:after {
                    top: 3px;
                    left: 8px;
                    width: 6px;
                    height: 12px;
                  }
                }
              }
              span {
                color: #262626;
                font-size: 14px;
                line-height: 150%;
              }
            }
          }
        }
        .trainingTestEndContainerQuestionsItemOpen {
          .trainingTestEndContainerQuestionsItemOpenAnswer {
            color: #262626;
            font-size: 14px;
            line-height: 19px;
          }
        }
        .trainingTestEndContainerQuestionsItemMatch {
          ul {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            li {
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              b {
                margin-right: 8px;
                color: #262626;
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
              }
              span {
                color: #262626;
                font-size: 14px;
                line-height: 19px;
              }
            }
          }
        }
        .trainingTestEndContainerQuestionsItemNext {
          div {
            display: flex;
            align-items: center;

            b {
              margin-right: 8px;
              color: #262626;
              font-size: 14px;
              font-weight: 600;
              line-height: 19px;
            }
            span {
              color: #262626;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
        .trainingTestEndContainerQuestionsItemSort {
          ul {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            li {
              margin-bottom: 24px;
              color: #262626;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingTestEnd {
    .trainingTestEndContainer {
      .trainingTestEndContainerInfo {
        li {
          padding: 0 12px;
        }
      }
      .trainingTestEndContainerButtons {
        margin-bottom: 24px;
      }
      .trainingTestEndContainerMarks {
        margin-bottom: 24px;
      }
      .trainingTestEndContainerQuestions {
        .trainingTestEndContainerQuestionsItem {
          padding: 12px;
        }
      }
    }
  }
}