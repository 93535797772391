@import "src/mixins/index.scss";

.blockStatus {
    display: flex;
    flex-direction: column;

    p {
        width: 100%;
        max-width: 624px;
        color: rgba(38, 38, 38, 0.5);
        font-size: 13px;
        line-height: 150%;
    }
}

.toolDivider {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 0 !important;
}

.toolbar {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 1rem 0;

    :only-child {
        margin-right: rem(8px);
    }
    .desktop {
        display: block;
    }
    .noDesktop {
        display: none;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        .desktop {
            display: none;
        }
        .noDesktop {
            display: block;
        }
    }
    &.mobile {
        flex-direction: column-reverse;

        .desktop {
            display: none;
        }
        .noDesktop {
            display: block;
        }
        button {
            margin-right: 0;
        }
        & > :not(:first-child) {
            margin-bottom: rem(16px);
        }
    }
}
