.toggleTreeButton {
    position: fixed;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-top: 0;
    width: 39px;
    height: 39px;
    z-index: 999;

    &.low {
        z-index: 1;
    }
    i {
        transform: scale(-1, 1);
    }
}
.treeContainer {
    position: fixed;
    width: 100%;
    height: calc(100svh - 57px);
    background: rgba(38, 38, 38, 0.65);
    z-index: 1000;

    &.withNewsTicker {
        height: calc(100svh - 86px) !important;
    }
    &.treeContainerHidden {
        display: none;
    }
    .treeWrapper {
        width: 100%;
        height: 100%;
        max-width: 280px;
    }
}
.resizingOverlay {
    z-index: 10000;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.sidebarLayout {
    height: calc(100svh - 57px) !important;

    &.withNewsTicker {
        height: calc(100svh - 86px) !important;
    }
    & > div {
        height: 100%;
    }
}
