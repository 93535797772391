.trainingNavigation {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EAEDF3;

  li {
    a {
      position: relative;
      display: block;
      padding: 13px 24px;
      color: rgba(38, 38, 38, 0.5);
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;

      &[aria-current="page"], &.trainingNavigationActive {
        color: #1280CE;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #1280CE;
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
}