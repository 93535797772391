@import "src/mixins/index.scss";

.modalHeader {
  display: flex;
  justify-content: space-between;
  @include platform-spacing(padding);
  padding-bottom: 0;

  h3 {
    font-size: rem(22px);
    font-weight: 500;
  }
}

.modalBody {
  display: flex;
  flex-wrap: wrap;
  @include platform-spacing(padding);
  padding-top: rem(32px);

  .userLink {
    color: #1280CE;
    cursor: pointer;
  }

  .bottomDivider {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #EAEDF3;
  }

}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: rem(16px) rem(24px);
  border-top: 1px solid #EAEDF3;

  > *:not(:last-child) {
    margin-right: rem(16px);
  }

  &.mobileFooter {
    flex-direction: column-reverse;
    > * {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      &:last-child {
        margin-bottom: rem(16px);
      }
    }
  }
}