@import "src/mixins/index.scss";

.container {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;

    button {
        @include text-normal;
        width: 100%;
        height: rem(48px);
        padding-left: rem(24px);
        text-align: left;
        border: 0;
        background-color: var(--color-white);
        margin-left: 0;

        @media (min-width: 768px) and (max-width: 1024px) {
            padding-left: rem(16px);
        }
    }

    button:hover {
        background: rgba(39, 155, 217, 0.05);
    }

    .current {
        border-left: 2px solid var(--color-blue);
        padding-left: rem(22px);
        color: var(--color-blue);
        background: rgba(39, 155, 217, 0.05);

        @media (min-width: 768px) and (max-width: 1024px) {
            padding-left: rem(14px);
        }
    }
}

.submenuItem {
    width: 100%;
    height: 56px;
    padding: rem(16px);
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid var(--color-grayBlue);
    .backButton {
        width: 56px;
        height: 100%;
    }
    .menuTitle {
        height: 100%;
        flex-grow: 1;
    }
    &.active {
        background: rgba(18, 128, 206, 0.05);
        color: var(--color-blue);
    }
}
