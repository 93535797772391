@import "src/mixins/index.scss";

.container {
    display: block;
    position: relative;
    padding-left: rem(20px);
    margin-bottom: rem(12px);
    cursor: pointer;
    font-size: rem(14px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media not all and (hover: none) {
        &:hover {
            color: var(--color-blue);
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;

    &:disabled {
        cursor: not-allowed;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: rem(14px);
    width: rem(14px);
    background: transparent;
    border: rem(1px) solid var(--color-stroke);
    box-sizing: border-box;
    border-radius: rem(5px);

    &::after {
        position: absolute;
        display: none;
        box-sizing: unset;
        content: '';
    }
}

@media not all and (hover: none) {
    .container:hover input ~ .checkmark {
        border: rem(1px) solid var(--color-blue);
        color: var(--color-blue);
    }
    .container:hover input:disabled ~ .checkmark {
        border: rem(1px) solid #7B8F9C;
    }
}

.container input:disabled ~ .checkmark {
    border: rem(1px) solid #7B8F9C;
}

.container input:checked:disabled ~ .checkmark {
    background: #7B8F9C;

    @media not all and (hover: none) {
        &:hover {
            background: #7B8F9C;
        }
    }
}

.container input:checked ~ .checkmark {
    background: var(--color-blue);
    border: none !important;

    @media not all and (hover: none) {
        &:hover {
            background: var(--color-blueHover);
        }
    }
}

.container input:checked ~ .checkmark:after {
    content: '';
    display: block;
    top: 1px;
    left: 5px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.container.indeterminate input:checked ~ .checkmark:after {
    transform: rotate(90deg);
    border-bottom: 0;
}
