.emojiWrapper {
    padding: 4px;
}

.emojiPickerContainer {
    width: 264px;
    height: 216px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-gutter: stable both-edges;

    .categoryWrapper {
        position: relative;
    }

    .categoryName {
        position: sticky;
        top: 0;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(3px);
        font-weight: bold;
    }

    .emojiesList {
        display: flex;
        flex-wrap: wrap;
    }

    .emoji {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        font-size: 20px;
        cursor: pointer;
    }
    @media (max-width: 1024px) {
        width: 100%;
    }
}
