.trainingQuestion {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;

  .trainingQuestionCreate {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    height: max-content;

    h1 {
      margin-bottom: 24px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
    .trainingQuestionCreateContainer {
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      .trainingQuestionCreateTop {
        padding: 24px;

        .trainingQuestionCreateTopField {
          margin-bottom: 24px;
        }
        .trainingQuestionCreateTopDelimiter {
          margin: 8px 0;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        h3 {
          margin-bottom: 16px;
          color: #262626;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;

          &:last-child {
            margin-top: 24px;
          }
        }
      }
      .trainingQuestionCreateBottom {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #EAEDF3;

        button {
          margin-right: 16px;
        }
      }
    }
  }
  .trainingQuestionView {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    height: max-content;

    .trainingQuestionViewContainer {
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      .trainingQuestionViewTop {
        padding: 24px;
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: 24px;
          width: 360px;
          height: 200px;
          border-radius: 5px;
          object-fit: cover;
        }
        h3 {
          margin-bottom: 8px;
          color: #262626;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
        .trainingQuestionViewTopMark {
          margin-bottom: 24px;
          color: #262626;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          opacity: 0.5;
        }
        .trainingQuestionViewTopSeparator {
          margin-bottom: 24px;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        .trainingQuestionViewTopMany,
        .trainingQuestionViewTopOne,
        .trainingQuestionViewTopNext,
        .trainingQuestionViewTopMatch,
        .trainingQuestionViewTopSort {
          p {
            margin-bottom: 24px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            opacity: 0.5;
          }
          ul {
            display: flex;
            flex-direction: column;

            li {
              display: flex;
              flex-direction: column;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
              label {
                color: #262626;
                font-size: 14px;
                line-height: 21px;
              }
              span {
                margin-top: 4px;
                color: #262626;
                font-size: 13px;
                line-height: 19px;
                opacity: 0.5;
              }
            }
          }
        }
        .trainingQuestionViewTopOpen {
          display: flex;
          align-items: center;
          padding: 16px;
          width: 100%;
          max-width: 380px;
          background: #FBFBFD;
          border-radius: 5px;

          i {
            margin-right: 8px;
          }
          span {
            color: #262626;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .trainingQuestionViewBottom {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #EAEDF3;

        div:first-child svg path {
          fill: #FF6973;
        }
        button {
          margin-right: 16px;

          svg path {
            fill: #1280CE;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingQuestion {
    .trainingQuestionCreate {
      padding: 12px;

      .trainingQuestionCreateContainer {
        .trainingQuestionCreateTop {
          padding: 12px;
        }
      }
    }
    .trainingQuestionView {
      padding: 12px;

      .trainingQuestionViewContainer {
        .trainingQuestionViewTop {
          padding: 12px;
        }
        .trainingQuestionViewBottom {
          padding: 12px;
        }
      }
    }
  }
}