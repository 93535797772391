.iconGroup {
    display: flex;
    align-items: center;
    width: 100%;

    &.center {
        justify-content: center;
    }

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }

    & > * {
        &:not(:last-child) {
            margin-right: 16px;
        }

        cursor: pointer;
    }
}
