@import "src/mixins/index.scss";

.contentWrapper {
  padding: 24px;
}

.title {
  @include header-large;
  width: 100%;
  padding-bottom: rem(24px);
}

.table {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .filter {
    padding: rem(24px);
    border-bottom: 1px solid #EAEDF3;
  }


  .pagination {
    padding: 16px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-radius: 0 0 5px 5px;
  }
}

.employeeCell {
  display: flex;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    background-color: #eee;
    margin-right: 8px;
  }
}
