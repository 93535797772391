
.chatWindowFooter {
  position: relative;
  padding: 0 24px;
  background: #FFFFFF;
  border-top: 1px solid #EAEDF3;

  .chatWindowFooterMessage {
    display: flex;
    align-items: center;
    height: 56px;

    .chatWindowFooterMessageCross {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .chatWindowFooterMessageEdit {
      display: flex;
      flex-direction: column;
      padding: 8px 16px;
      width: 100%;
      max-width: 720px;
      background: #FBFBFD;
      border-radius: 5px;

      label {
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
      }
      p {
        color: #262626;
        font-size: 14px;
        line-height: 150%;
      }
    }
  }
  .chatWindowFooterSend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;

    & > div:first-child {
      margin-right: 8px;
      cursor: pointer;

      &:hover + .chatWindowFooterEmodji {
        display: block;
      }
    }
    .chatWindowFooterEmodji {
      display: none;
      position: absolute;
      bottom: 18px;
      left: 16px;
      padding-bottom: 54px;
      width: 100%;
      max-width: 276px;
      height: 250px;
      z-index: 10;

      &:hover {
        display: block;
      }
      .chatWindowFooterEmodjiItems {
        display: flex;
        flex-wrap: wrap;
        align-content: baseline;
        padding: 8px;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        overflow: auto;

        .chatWindowFooterEmodjiItem {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          font-size: 20px;
          cursor: pointer;
          transition: background 0.3s ease-in-out;

          &:hover {
            background: rgba(18, 128, 206, 0.05);
            border: 1px solid #EAEDF3;
          }
        }
      }
    }
    .chatWindowFooterInput input {
      border: none;
    }
    & > div:last-child {
      margin-left: 24px;
    }
  }
}
