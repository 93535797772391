@import "src/mixins/index.scss";

.wrapper {
  margin-bottom: rem(12px);

  &.checked {
    .checkmark {
      border-color: var(--color-blue);
    }
  }
}

.container {
  display: block;
  position: relative;
  padding-left: rem(20px);
  margin-bottom: rem(8px);
  cursor: pointer;
  font-size: rem(14px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 14px;

  &:hover {
    color: var(--color-blue);
  }
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3.5px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(14px);
  width: rem(14px);
  border: rem(1px) solid var(--color-stroke);
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  border: rem(1px) solid var(--color-blue);
  color: var(--color-blue);
}

.container input:checked ~ .checkmark {
  span {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: var(--color-blue);
  }
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .label {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.wrapper .desc {
  color: #262626;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  opacity: .5;
}