@import "src/mixins/index.scss";

.loader {
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
}
.userContainer {
    width: 100%;

    h2 {
        font-size: rem(22px);
        font-weight: 500;
        @include platform-spacing(margin-bottom);
        line-height: rem(30px);
    }
    .table {
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        position: relative;

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.6);
            z-index: 1;
        }
        .filter {
            padding: 24px;
            border-bottom: 1px solid #EAEDF3;
        }
        .pagination {
            padding: 16px 24px;
            background: #FFFFFF;
            border-top: 1px solid #EAEDF3;
            border-radius: 0 0 5px 5px;
        }
    }
}
.nameCell {
    width: 100%;
    display: flex;
    align-items: center;
    color: #262626 !important;

    img {
        min-width: rem(40px);
        min-height: rem(40px);
        max-width: 40px;
        border-radius: 50%;
        margin-right: 8px;
    }
    &:hover {
        p {
            color: #1280CE;
        }
    }
    &.mobileName {
        margin-bottom: 16px;
        p {
            margin-bottom: 0 !important;
        }
    }
}
.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}
.blockActionButton {
    svg path {
        fill: #E06169 !important;
    }
}
.cellMobile {
    width: 100%;

    .sections {
        gap: 16px;
        width: 100%;
        display: flex;

        .section{
            display: flex;
            flex-direction: column;
            gap: 16px;
            flex-grow: 1;
        }
    }
}
