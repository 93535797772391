@import "src/mixins/index.scss";

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);

  .integrationsTitle {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .integrationsTitleIcon {
      margin-right: 16px;
    }

    h1 {
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }

  .integrationsWrapper {
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .integrationsWrapperBlock {
      position: relative;
      margin-bottom: 8px;
      @include platform-spacing(padding);
      padding-top: rem(24px);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
        background: #EAEDF3;
      }

      &:nth-child(3):after {
        display: none;
      }

      h2 {
        margin-bottom: 32px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .integrationsWrapperBlockRow {
        display: flex;

        label {
          display: block;
          margin-bottom: 4px;
          color: #262626;
          font-size: 13px;
          line-height: 18px;
          opacity: 0.5;
        }

        .host {
          margin-bottom: 24px;
          margin-right: 24px;
          width: 100%;
          max-width: 280px;
        }

        .port {
          margin-bottom: 24px;
          width: 100%;
          max-width: 120px;
        }

        .login {
          margin-bottom: 8px;
          margin-right: 24px;
          width: 100%;
          max-width: 420px;
        }

        .password {
          margin-bottom: 8px;
          width: 100%;
          max-width: 420px;
        }
      }

      .integrationsLocation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 0 12px;
        width: 100%;
        max-width: 420px;
        height: 40px;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        cursor: pointer;
      }

      ul {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 24px;
          width: 100%;
          max-width: 270px;
          height: 170px;
          background: #FFFFFF;
          border: 1px solid #EAEDF3;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border: 2px solid #1280CE;

            svg path {
              fill: #1280CE;
            }
          }

          div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            i {
              margin-right: 16px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          span {
            padding: 0 24px;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            text-align: center;
          }
        }
      }
    }

    .integrationsWrapperButtons {
      display: flex;
      align-items: center;
      @include platform-spacing(padding);
      padding-top: rem(16px);
      padding-bottom: rem(16px);
      border-top: 1px solid #EAEDF3;

      button:first-child {
        margin-right: 16px;
      }
    }
  }

  &.mobile {
    .integrationsWrapperBlockRow {
      flex-wrap: wrap;
      > * {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: none !important;
      }
    }
    .integrationsComponents {
      flex-direction: column;
      > * {
        max-width: none !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        &:not(:last-child) {
          margin-bottom: rem(16px);
        }
      }
    }
    .integrationsWrapperButtons {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        &:last-child {
          margin-bottom: rem(16px);
        }
      }
    }
  }
}