
.chatWindowHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 16px;
  background: #FFFFFF;
  border-bottom: 1px solid #EAEDF3;

  .chatWindowHeaderBackBtn {
    margin-right: 16px;
  }
  .chatWindowHeaderTarget {
    display: flex;
    align-items: center;
    cursor: pointer;

    .chatWindowHeaderTargetAvatar {
      position: relative;
      margin-right: 16px;
      width: 32px;
      height: 32px;
      background: #EAEDF3;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      @media (min-width: 768px) {
        width: 48px;
        height: 48px;

        i {
            width: 32px !important;
            height: 32px !important;
        }
      }

      img {
        width: 100%;
        border-radius: 100px;
        object-fit: cover;
      }
      .chatWindowHeaderTargetStatusOnline {
        background: #50B678;
      }
      .chatWindowHeaderTargetStatusOffline {
        background: #FF6973;
      }
    }
    .chatWindowHeaderTargetContent {
      display: flex;
      flex-direction: column;

      p {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
      span {
        font-size: 13px;
        line-height: 19px;

        &.chatWindowHeaderTargetContentStatusOnline {
          color: #1280CE;
        }
        &.chatWindowHeaderTargetContentStatusOffline {
          color: #262626;
          opacity: 0.5;
        }
      }
    }
  }
}
