@import "src/mixins/index.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 200;

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 24px;
    height: calc(100vh - 72px);
    width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &.mobile {
      margin: rem(50px) rem(16px);
      height: calc(100% - 200px);

      .uploadSection {
        padding: rem(24px) rem(16px) rem(16px);
      }

      .modalBody {
        padding: rem(16px);
      }

      .modalFooter {
        flex-direction: column-reverse;
        & > :first-child {
          margin-top: rem(16px);
        }
      }
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: rem(24px);
  padding-left: rem(24px);
  padding-right: rem(24px);

  h3 {
    font-size: rem(22px);
    font-weight: 500;
  }
}

.uploadSection {
  padding: rem(32px) rem(32px) rem(16px);
  width: 100%;
  flex-shrink: 0;
  border-bottom: 1px solid #EAEDF3;

  .tabs {
    display: flex;
    width: 100%;
    background-color: #F3F6FA;
    border-radius: 5px;
    padding: rem(3px);

    .tab {
      width: 100%;
      max-width: 50%;
      height: 26px;
      line-height: rem(26px);
      text-align: center;
      border-radius: 5px;
      color: rgba(#262626, 0.5);
      cursor: pointer;

      &:first-of-type {
        padding-right: 3px;
      }

      &:last-of-type {
        padding-left: 3px;
      }

    }

    .tabSelected {
      background-color: #1280CE;
      color: #fff;
    }
  }

  .uploadLabel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top: rem(24px);
    padding-bottom: rem(24px);
    text-align: center;
    border: 1px solid rgba(39, 155, 217, 0.15);
    border-radius: 5px;
    color: rgba(#262626, 0.5);
    margin-bottom: rem(16px);

    & > div:first-of-type {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: rem(8px);
    }
  }


  svg path {
    fill: #1280CE;
  }

  .uploadDesc {
    max-width: rem(320px);
  }

  label.uploadLabel input[type="file"] {
    display: none;
  }
}

.modalBody {
  position: relative;
  padding: rem(16px) rem(32px);
  overflow: auto;
  height: rem(408px);
  flex-grow: 1;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
    padding: rem(32px);
  }

  .search {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    margin-bottom: rem(16px);

    .searchButton {
      display: inherit;
      margin-right: 10px;
    }

    input {
      width: 100%;
      border: 0;
      color: #262626;
      font-size: 14px;
      outline: none;

      &::placeholder {
        color: #262626;
        opacity: 0.4;
      }
    }
  }

  .fileWrapper {
    display: flex;

    & > div:first-of-type {
      @include platform-spacing(padding-right);
    }
  }

  .nameCell {
    width: 100%;
    padding-bottom: rem(20px);
  }

  .nameCell, .fileWrapper {
    display: flex;
    align-items: flex-start;

    .iconWrapper {
      padding-right: rem(12px);
    }

    .descWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    .fileName {
      padding-bottom: rem(8px);
    }

    .fileDescription {
      color: rgba(#262626, 0.5);

      .fileType {
        text-transform: uppercase;
      }
    }
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: rem(16px) rem(24px);
  border-top: 1px solid #EAEDF3;

  > *:not(:last-child) {
    margin-right: rem(16px);
  }
}

.empty {
  margin-top: 0;
}
