@import "src/mixins/index.scss";

$border: #EAEDF3;

.toggler {
  min-width: 16px;
  height: 14px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.opened {
  i path {
    fill: #1280CE;
  }
}

.child {
  border-left: 1px solid $border;
}