$transition-duration: 0.1s;

.chatMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  background: #FFFFFF;
  border-right: 1px solid #EAEDF3;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 260px;
  }

  .chatSearch {
    position: relative;
    padding: 16px;
    width: 100%;

    i {
      position: absolute;
      top: 30px;
      left: 30px;
    }

    .chatSearchInput {
      input {
        padding-left: 34px;
      }
    }
  }

  .chatTabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #EAEDF3;

    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0;
      width: 100%;
      cursor: pointer;

      &:hover, &.active {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #1280CE;
          border-radius: 5px 5px 0 0;
        }

        p {
          color: #1280CE;
          opacity: 1;
        }

        span {
          background: #FF6973;
          color: #FFFFFF;
        }
      }

      p {
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        opacity: 0.5;
      }

      span {
        margin-left: 4px;
        padding: 2px 4px;
        background: #EAEDF3;
        border-radius: 5px;
        color: #7B8F9C;
        font-weight: 600;
        font-size: 10px;
        line-height: 9px;
      }
    }
  }

  .chatMenuContent {
    height: 100%;
    overflow: auto;

    .chatMenuContentLoading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .chatMenuContentEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      i {
        margin-bottom: 24px;

        svg path {
          fill: #7B8F9C;
        }
      }

      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        opacity: 0.65;
      }
    }

    .chatMenuContentItem {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 16px;
      width: 100%;
      height: 80px;
      cursor: pointer;
      transition: all $transition-duration ease-in-out;

      &:hover, &.active {
        background: #1280CE;

        .chatMenuContentItemContainer {
          border-bottom: 1px solid #1280CE;

          .chatMenuContentItemTitle,
          .chatMenuContentItemAuthor,
          .chatMenuContentItemMessage {
            color: #FFFFFF;
          }
        }

        .chatMenuContentItemDate {
          i {
            background: #FFFFFF;
          }

          span {
            color: #FFFFFF;
          }
        }
      }

      .chatMenuContentItemImage {
        margin-right: 16px;
        width: 100%;
        max-width: 48px;
        height: 48px;
        background: #EAEDF3;
        background-size: cover;
        border-radius: 100px;
      }

      .chatMenuContentItemContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 16px 8px 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #EAEDF3;
        transition: all $transition-duration ease-in-out;

        .chatMenuContentItemTitle {
          margin-bottom: 2px;
          color: #262626;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          transition: all $transition-duration ease-in-out;
        }

        .chatMenuContentItemAuthor {
          margin-bottom: 2px;
          color: #262626;
          font-size: 13px;
          line-height: 19px;
          transition: all $transition-duration ease-in-out;
        }

        .chatMenuContentItemMessage {
          color: #262626;
          font-size: 13px;
          line-height: 19px;
          opacity: 0.5;
          transition: all $transition-duration ease-in-out;
          word-break: break-all;
        }
      }

      .chatMenuContentItemRead {
        position: absolute;
        bottom: 8px;
        right: 16px;
        padding: 4px 6px;
        background: #FF6973;
        border-radius: 100px;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
      }

      .chatMenuContentItemDate {
        position: absolute;
        top: 8px;
        right: 16px;
        display: flex;
        align-items: center;

        i {
          display: block;
          margin-right: 8px;
          width: 8px;
          height: 8px;
          background: #1280CE;
          border-radius: 8px;
          transition: all $transition-duration ease-in-out;
        }

        span {
          color: #262626;
          font-size: 13px;
          line-height: 19px;
          opacity: 0.5;
          transition: all $transition-duration ease-in-out;
        }
      }
    }
  }

  .chatButtons {
    display: flex;
    width: 100%;
    height: 91px;
    border-top: 1px solid #EAEDF3;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05);

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 0;
      width: 100%;
      background: #FFFFFF;
      border: none;
      transition: all $transition-duration ease-in-out;

      &:first-child {
        border-right: 1px solid #EAEDF3;
      }

      &:hover {
        background: #1280CE;

        i svg path {
          fill: #FFFFFF;
        }

        P {
          color: #FFFFFF;
          opacity: 1;
        }
      }

      i {
        margin-bottom: 8px;

        svg path {
          fill: #1280CE;
          transition: all $transition-duration ease-in-out;
        }
      }

      p {
        color: #262626;
        font-size: 11px;
        font-weight: 500;
        line-height: 11px;
        text-align: center;
        opacity: 0.5;
        transition: all $transition-duration ease-in-out;
      }
    }
  }

  &.mobile {
    max-width: 100%;
  }
}
