@import 'src/mixins/index.scss';
@import 'src/uikit/_styles/common-styles';

.base {
    position: relative;
    width: 100%;
    text-align: left;

    .inputWrapper {
        @include input;

        position: initial;
        padding: 2px 6px;

        display: flex;
        width: 100%;
        max-height: 100px;
        min-height: 40px;
        height: initial;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: auto;

        &.withDesc {
            padding-bottom: rem(4px);
        }

        &.disabled {
            background-color: #fbfbfd;

            &:hover {
                border: 1px solid var(--color-stroke);
            }

            .input {
                cursor: default;
            }
        }

        &.error {
            border: 1px solid var(--color-red);
        }

        .removeAll {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: auto;
            cursor: pointer;
        }
    }
}

.valuesWrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .valueWrapper {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 9999px;
        background-color: #f5f5f5;

        .imgWrapper {
            display: flex;
            overflow: hidden;
            width: 16px;
            height: 16px;
            background-color: #b19bdf;
            border-radius: 9999px;
            margin-right: 6px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        span {
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
        }

        .remove {
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 14px;
            height: 14px;
            border-radius: 9999px;
            background-color: #1280ce;
            cursor: pointer;
        }
    }
}

.label {
    @include inputLabel;

    &.required::after {
        content: '*';
        display: inline-block;
        color: var(--color-red);
    }
}

.icon {
    position: absolute;
    left: 14px;
    top: 12px;
}

.input {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    min-width: 5px;
    width: 5px;
    max-width: 100%;
    box-sizing: content-box;
    background: 0px center;
    border: 0px;
    opacity: 1;
    outline: 0px;
    padding: 0px;

    &.withIcon {
        padding-left: 38px;
    }
}

.clearable {
    padding-right: rem(40px);
}

.description {
    margin-top: 4px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px;
    line-height: 18px;
}

.input[type='date']::-webkit-inner-spin-button {
    display: none;
}

.error {
    .input {
        margin-bottom: rem(4px);
        border-color: var(--color-red);
    }

    .error-message {
        color: var(--color-red);
        font-size: rem(12px);
        line-height: rem(16px);
    }
}

.buffer {
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
    height: 0px;
    overflow: scroll;
    white-space: pre;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-transform: none;
}
