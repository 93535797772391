.lockTree {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 570px;
  height: calc(100vh - 162px);
  background: #FFFFFF;
  border-radius: 5px;
  z-index: 1;

  .header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EAEDF3;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h3 {
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 32px 16px 32px;

      .left {
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 8px;
          width: 24px;
          height: 24px;
          background: #EAEDF3;
          border-radius: 24px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 24px;
            object-fit: contain;
          }
        }

        p {
          color: #262626;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
        }
      }

      .right {
        span {
          color: #262626;
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }

  .body {
    padding: 16px 24px;
    height: calc(100vh - 352px);
    overflow: auto;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: 1px solid #EAEDF3;
  }
}
