.panel {
  position: relative;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h2 {
      margin: 0;
      color: #262626;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }

    a {
      color: #1280CE;
      font-size: 14px;
      font-weight: bolder;
      line-height: 150%;
    }
    .headContent {
      display: flex;
      align-items: center;

      .headReadied {
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
        }
        span {
          color: #262626;
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          line-height: 150%;
        }
        & > div {
          width: auto;

          & > div > div:first-child,
          & > div > div:nth-child(2) {
            background: transparent;
            border: none !important;

            & > div:first-child {
              padding: 0 0 0 8px;

              & > div:first-child {
                position: relative;
                top: 0;
                margin-right: 0;
                width: auto;
                transform: none;
                color: #1280CE;
                font-size: 13px;
                font-weight: 500;
                font-style: normal;
                line-height: 150%;
              }
            }
            & > div:last-child > div:last-child {
              margin: 0;
              padding: 0;

              svg path {
                fill: #1280CE;
              }
            }
          }
          & > div > div:nth-child(3) {
            left: -75px;
            width: 135px;
          }
        }
      }
      ul {
        display: flex;
        margin-left: 24px;
        padding: 4px;
        background: #F3F6FA;
        border-radius: 4px;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          cursor: pointer;

          &:hover {
            background: #1280CE;
            border-radius: 5px;

            svg path {
              fill: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .body {
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      z-index: 1;
    }

    .filter {
      margin-bottom: 24px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        margin-bottom: 24px;
        width: calc(50% - 15px);
        max-width: none;

        &:nth-child(even) {
          margin-left: 15px;
        }

        &:nth-child(odd) {
          margin-right: 15px;
        }
      }
    }

    .pagination {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .panel {
    .body {
      .items {
        .item {
          width: 100%;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}