@import "src/mixins/index.scss";

.table {
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        h2 {
            margin: 0;
            color: #262626;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
        }
        a {
            color: #1280CE;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
        }
        .headContent {
            display: flex;
            align-items: center;

            .headReadied {
                display: flex;
                align-items: center;

                i {
                    margin-right: 4px;
                }
                span {
                    color: #262626;
                    font-size: 13px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 150%;
                }
                & > div {
                    width: auto;

                    & > div > div:first-child,
                    & > div > div:nth-child(2) {
                        background: transparent;
                        border: none !important;

                        & > div:first-child {
                            padding: 0 0 0 8px;

                            & > div:first-child {
                                position: relative;
                                top: 0;
                                margin-right: 0;
                                max-width: none;
                                width: auto;
                                transform: none;
                                color: #1280CE;
                                font-size: 13px;
                                font-weight: 500;
                                font-style: normal;
                                line-height: 150%;
                            }
                        }
                        & > div:last-child > div:last-child {
                            margin: 0;
                            padding: 0;

                            svg path {
                                fill: #1280CE;
                            }
                        }
                    }
                    & > div > div:nth-child(3) {
                        left: -75px;
                        width: 135px;
                    }
                }
            }
            ul {
                display: flex;
                margin-left: 24px;
                padding: 4px;
                background: #F3F6FA;
                border-radius: 4px;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    &:hover {
                        background: #1280CE;
                        border-radius: 5px;

                        svg path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

    .body {
        position: relative;

        @media (min-width: 768px) {
            background: #FFFFFF;
            border: 1px solid #EAEDF3;
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        }
        //@media (min-width: 768px) and (max-width: 1024px) {
        //    margin-bottom: 64px;
        //}
        .loader {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.6);
            z-index: 1;
        }

        .filter {
            @include platform-spacing(padding);
            padding-top: rem(24px);
            padding-bottom: rem(24px);
            background: #FFFFFF;

            @media (max-width: 767px) {
                border: 1px solid #EAEDF3;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
            }
            @media (min-width: 768px) {
                border-bottom: 1px solid #EAEDF3;
            }
        }

        .pagination {
            padding: 16px 24px;
            background: #FFFFFF;
            border-top: 1px solid #EAEDF3;
            border-radius: 0 0 5px 5px;

            @media (min-width: 768px) and (max-width: 1024px) {
                padding: 16px;
            }
            @media (max-width: 767px) {
                background: none;
                border: none;
            }
        }
    }
}
.emptyCell {
    opacity: 0.5;
}
