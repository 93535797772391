@import "src/mixins/index.scss";

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .container {
    display: flex;
    align-items: center;

    .first, .last {
      color: #262626;
      font-size: 13px;
      font-weight: 500;
      line-height: 13px;
      background: transparent;
      border: none;

      &:disabled {
        opacity: 0.5;
      }
    }

    .first {
      margin-right: 24px;

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-right: 16px;
      }
    }

    .last {
      margin-left: 24px;

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 16px;
      }
    }

    .prev, .next {
      background: transparent;
      border: none;
    }

    .prev {
      margin-right: 24px;
      transform: rotate(-90deg);

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-right: 16px;
      }
    }

    .next {
      margin-left: 24px;
      transform: rotate(90deg);

      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 16px;
      }
    }

    .buttons {
      margin: 0 10px;

      button {
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;

        &:hover, &.active {
          background: #1280CE;
          border-radius: 5px;
          color: #FFFFFF;
        }
      }
    }
  }

  .counts {
    width: 75px;
  }

  &.mobile {
    justify-content: center;
    .container {
      > * {
        padding: 0;
        margin: 0;
      }
      .first {
        margin: 0;
      }

      .next, .prev {
        margin: 0 rem(20px);
      }
    }
  }
}
