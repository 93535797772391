.trainingQuestionAnswerSort {
  margin-top: 24px;

  .trainingQuestionAnswerSortDescription {
    margin-bottom: 24px;
    color: #262626;
    font-size: 14px;
    line-height: 21px;
    opacity: 0.65;
  }
  .trainingQuestionAnswerSortItem {
    display: flex;
    align-items: center;

    i:first-child {
      margin-right: 16px;
    }
    i:last-child {
      margin-left: 16px;
    }
  }
  .trainingQuestionAnswerSortAdd {
    display: flex;
    align-items: center;
    cursor: pointer;

    .trainingQuestionAnswerSortAddIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      background: #1280CE;
      border-radius: 100px;
    }
    span {
      color: #1280CE;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}