@import "src/mixins/index.scss";

$btn-color: #1280CE;

.locationChange {
  position: relative;
  .titleBlock {
    font-weight: 600;
    font-size: rem(14px);
    line-height: 150%;
    margin-bottom: 1rem;
  }

  .blockLocation {
    min-height: 40px;
    max-height: 100px;
    border: 1px solid var(--color-stroke);
    border-radius: 5px;
    padding: 3px 6px;
      overflow-y: auto;

    &:hover {
      border: 1px solid var(--color-input-border-hover);
      cursor: pointer;
    }

    .path {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    i + i {
      margin-left: .5rem;
    }

    .removeAll {
      position: absolute;
      right: 16px;
      top: 13px;
      margin-left: auto;
      cursor: pointer;
    }

    i:hover {
      cursor: pointer;
    }

    &.fieldError {
      border-color: var(--color-red);
    }
  }

  .fieldErrorText {
    margin-top: 4px;
    color: var(--color-red);
    font-size: 13px;
    line-height: 18px;
  }

  .projectWrapper {
    margin: 3px 6px;
    padding: 6px;
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border-radius: 9999px;

    .projectLogo {
      overflow: hidden;
      width: 16px;
      height: 16px;
      background-color: #B19BDF;
      border-radius: 9999px;
      margin-right: 6px;
    }

    .projectRemoveIcon {
      margin-left: 6px;
      display: flex;
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      background-color: #1280CE;
      border-radius: 9999px;
    }
  }
}


.tree {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-left: 32px;
  padding-right: 32px;

  .searchInput {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .toolbar {
    display: flex;
    height: rem(24px);
    margin: rem(8px);

    .btn {
      display: flex;
      font-size: 11px;
      color: $btn-color;
      justify-content: center;
      flex-basis: 50%;
      flex-shrink: 1;
      align-items: center;
      cursor: pointer;

      i {
        margin-right: 8px;
      }
    }

    .btn + .btn {
      border-left: #EAEDF3 1px solid;
    }
  }

  .allBtn {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 36px;
    height: 32px;
    width: 100%;
    background: transparent;
    border: none;
    color: #1280CE;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    &.allBtnActive, &:hover {
      background: rgba(39, 155, 217, 0.05);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 32px;
        background: #1280CE;
      }
    }

    i {
      margin-right: 10px;

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #1280CE;
        }
      }
    }
  }

  .sizerContainer {
    flex-grow: 1;
  }
}
