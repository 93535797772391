@import "src/mixins/index.scss";

.container {
    position: relative;

    > div > button {
        &:hover svg {
            circle {
                fill: #1280CE;
            }

            path {
                fill: white;
            }
        }
    }

    .button {
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;
        color: #1280CE;
        background: none;
        border: none;
    }

    .menu {
        display: flex;
        flex-direction: column;
        padding: 0;
        //width: 266px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        position: absolute;
        top: 25.5px;
        //left: -249px;
        z-index: 10000;
        //overflow: hidden;
        min-width: 200px;
        width: auto;

        &.left {
            left: -249px;
        }

        &.right {
            left: -10px;
            //width: 266px;
        }

        div {
            margin-right: 0;
        }
        .MenuContentClose {
            display: none;
        }
        @media (max-width: 1024px) {
            position: fixed !important;
            top: unset !important;
            bottom: 0;
            left: 0 !important;
            transform: unset !important;
            padding-bottom: 41px;
            width: 100%;
            max-height: 281px;
            border: 1px solid #E8E8E8;
            border-radius: 5px 5px 0 0;
            will-change: unset !important;

            .MenuContentClose {
                position: fixed;
                bottom: 0;
                left: 0;
                display: block;
                padding: 10px 12px;
                width: calc(100% + 10px);
                background: #FFFFFF;
                border-top: 1px solid #E8E8E8;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    &.show {
        > div > button svg {
            circle {
                fill: #1280CE;
            }

            path {
                fill: white;
            }
        }
    }
}
