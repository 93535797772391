@import "src/mixins/index.scss";

.content {
  .pageTitle {
    @include header-large;
    margin-top: 0;
    margin-bottom: rem(24px);
  }

  .field {
    margin-bottom: rem(8px);
    max-width: rem(420px);
  }

  .formGroup {
    margin-bottom: 10px;
  }

  .divider {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 0;
  }

  .tool {
    display: flex;
    padding-top: 1rem;

    button {
      margin-right: 1rem;
    }
  }

  &.mobile {
    .tool {
      flex-direction: column-reverse;
      & > div:first-child {
        margin-top: rem(16px);
      }
    }
  }
}