@import "src/mixins/index.scss";

.pageWrapper {
  display: flex;
  flex-wrap: wrap;

  &.withTicker {
    .mobileMenuWrapper {
      top: 28px;

      .mobileMenu {
        top: 84px;
        height: calc(100% - 85px);
      }
    }
    .menuWrapper {
      top: 28px;

      .menuItems {
        height: calc(100svh - 84px);
      }
    }
  }
}

.mobileMenuWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;

  .mobileMenu {
    visibility: hidden;
    display: flex;
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    flex-direction: row;
    height: calc(100% - 56px);
    z-index: 99;

    &.open {
      visibility: visible;
    }

    .menuBackground {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }

    .mobileMenuItems {
      display: flex;
      flex-direction: column;
      z-index: 3;
      background-color: var(--color-white);
      @include invisible-scroll();

      .shadowItem:last-child {
        border-bottom: none;
        flex-grow: 1;
        align-items: flex-start;
        border-right: 1px solid var(--color-grayBlue);
      }

      .itemContainer {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--color-grayBlue);
        border-right: 1px solid var(--color-grayBlue);
        flex-shrink: 0;

        &:last-of-type {
          border-bottom: none;
        }

        &.active {
          background-color: var(--color-blue);
          .mobileItem path {
            fill: var(--color-white);
          }
        }

        &.add {
          .mobileItem path {
            fill: #50B678;
          }
          &.active {
            background-color: #50B678;
            .mobileItem path {
              fill: var(--color-white);
            }
          }
        }
      }
    }

    .menuContent {
      width: 283px;
      height: 100%;
      z-index: 3;
      background-color: var(--color-white);
      overflow-y: auto;

      .mainMenu {
        .textItem {
          width: 100%;
          height: 56px;
          padding-left: rem(16px);
          font-weight: 500;
          font-size: rem(14px);
          line-height: rem(19px);
          display: flex;
          align-items: center;

          &:not(:last-child) {
            border-bottom: 1px solid var(--color-grayBlue);
          }

          &.active {
            background: rgba(18, 128, 206, 0.05);
            color: var(--color-blue);
          }
        }
      }

      .addMenu {
        .addMenuItem {
          width: 100%;
          height: 56px;
          padding: rem(16px);
          display: flex;
          align-items: center;
          flex-direction: row;
          border-bottom: 1px solid var(--color-grayBlue);
          .backButton {
            width: 56px;
            height: 100%;
          }
          .menuTitle {
            height: 100%;
            flex-grow: 1;
          }
        }
      }

      .tree {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    height: 100% !important;
    height: stretch;
    overflow: hidden;

    > div:last-child {
      height: 100%;
    }
  }

  .subMenuContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .subMenuTitle {
      display: flex;
      flex-direction: row;
      height: 56px;
      border-bottom: 1px solid var(--color-grayBlue);
      flex-shrink: 0;

      .backButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 100%;
        border-right: 1px solid var(--color-grayBlue);
        i {
          transform: rotate(-90deg);
        }
      }

      .menuTitle {
        display: flex;
        align-items: center;
        padding: rem(16px);
        flex-grow: 1;
        height: 100%;
      }
    }

    .subMenu {
      flex-grow: 1;
    }
  }
}

.menuWrapper {
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 2;

  .menu {
    display: flex;
    flex-direction: column;
    flex-basis: rem(64px);
    max-width: rem(64px);
    flex-shrink: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--color-grayBlue);

    .granLogo {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(18, 128, 206, 0.05);
      width: rem(63px);
      height: rem(57px);
      border-bottom: 1px solid var(--color-grayBlue);
      cursor: pointer;

      img {
        background-size: contain;
        border-radius: 50%;
        width: rem(40px);
        height: rem(40px);
      }
    }

    .menuItems {
      padding: rem(10px) 0;
      overflow-y: auto;
      height: calc(100svh - 57px);
      @include invisible-scroll();
      a {
        &:first-child .item .imgContainer {
          background: #50B678;

          img {
            width: rem(10px);
            height: rem(10px);
          }
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: rem(10px) 0;
          cursor: pointer;

          &:hover, &.itemActive {
            .imgContainer {
              background: #1280CE;

              img {
                filter: brightness(200%);
              }
            }

            p {
              color: #1280CE;
              opacity: 1;
            }
          }

          .imgContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            width: rem(32px);
            height: rem(32px);
            background-color: transparent;
            border-radius: 50%;

            img {
              width: rem(18px);
              height: rem(18px);
            }
          }

          p {
            color: #262626;
            font-size: rem(10px);
            font-weight: normal;
            line-height: rem(14px);
            text-align: center;
            opacity: 0.75;
          }
        }
      }
    }
  }

  .content {
    overflow: hidden;
    flex-grow: 1;
  }
}
