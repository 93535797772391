.chart {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100%;
  max-height: 385px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  border: 1px solid var(--color-grayBlue);
  border-radius: 5px;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }
}

.chartHeader {
  padding: 0 16px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartHeaderTitle {
  line-height: 150%;
  font-weight: 600;

}

.chartWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 24px 24px 0;
  height: calc(385px - 111px);
  z-index: 0;
}

.lineCounter {
  padding: 0 24px;
  line-height: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #1280CE;
}

.emptyChart {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.emptyChartDoughnut {
  position: absolute;
  width: 220px;
  height: 220px;
  background: linear-gradient(157.07deg, #EAEDF3 4.31%, rgba(234, 237, 243, 0) 92.43%);
  border-radius: 9999px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 9999px;
  }
}

.emptyChartBar {
  position: absolute;
  top: 30px;
  left: 24px;
  right: 24px;
  bottom: 30px;
  width: calc(100% - 48px);
  height: calc(100% - 60px);
  background: repeating-linear-gradient(to right,
          #EAEDF3,
          #EAEDF3 1px,
          transparent 1px,
          transparent 40px);
}

.emptyChartLine {
  position: absolute;
  top: 30px;
  left: 24px;
  right: 24px;
  bottom: 30px;
  width: calc(100% - 48px);
  height: calc(100% - 60px);
  background: repeating-linear-gradient(to bottom,
          #EAEDF3,
          #EAEDF3 1px,
          transparent 1px,
          transparent 40px);
}

.emptyChartText {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 24px;

  i {
    margin-bottom: 16px;
  }

  div {
    width: 100%;
    text-align: center;
  }
}

.loaderWrapper {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}