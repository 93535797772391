@import "src/mixins/index.scss";

.news {
    display: flex;
    width: 100%;

    .newsWrapper {
        display: flex;
        width: 100%;
    }
    .newsContainer {
        width: 100%;
        height: calc(100svh - 57px);
        overflow: auto;

        &.withNewsTicker {
            height: calc(100svh - 86px);
        }
    }
    .newsHistory {
        width: 100%;
        max-width: 260px;
        height: calc(100svh - 57px);
        background: #FFFFFF;
        box-shadow: -2px 1px 2px rgba(0, 0, 0, 0.05);
        z-index: 1;

        &.withNewsTicker {
            height: calc(100svh - 86px);
        }
        .newsHistoryHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 54px;
            border-bottom: 1px solid #EAEDF3;

            h4 {
                padding-left: 16px;
                color: #262626;
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
            }
            .newsHistoryHeaderClose {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 54px;
                height: 100%;
                border-left: 1px solid #EAEDF3;
                cursor: pointer;
            }
        }
        .newsHistoryBody {
            display: flex;
            flex-direction: column;
            height: calc(100% - 54px);
            overflow: auto;

            .newsHistoryBodyItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                border-bottom: 1px solid #EAEDF3;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover, &.newsHistoryBodyItemActive {
                    background: rgba(18, 128, 206, 0.05);
                }
                h5 {
                    margin-bottom: 8px;
                    color: #262626;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                }
                .newsHistoryBodyItemContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    img {
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                        border-radius: 16px;
                        object-fit: cover;
                        overflow: hidden;
                    }
                    span {
                        color: #262626;
                        font-size: 12px;
                        line-height: 150%;
                    }
                }
                p {
                    color: rgba(38, 38, 38, 0.75);
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }
    }
}

.header {
    border-bottom: 1px solid var(--color-grayBlue);
}

.scrollTopBtn {
    position: absolute;
    bottom: 24px;
    right: 24px;
    font-weight: 500;
    color: #1280ce;
    text-align: center;
    cursor: pointer;

    div:first-of-type {
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: #1280CE;
        border-radius: 9999px;

        & svg path {
            fill: #fff;
        }
    }
}

.readBtnMobile {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.restoreModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.65);
    z-index: 10;

    .restoreModal {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 570px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        .restoreModalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 24px 0 24px;

            h2 {
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .restoreModalBody {
            padding: 32px;

            p {
                margin-bottom: 32px;
                color: #000000;
                font-size: 14px;
                line-height: 21px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .restoreModalFooter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 16px 24px;
            border-top: 1px solid #EAEDF3;

            div {
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.favoriteActive {
    svg path {
        fill: #1280ce;
    }
}

.search {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--color-grayBlue);
}
.postingReadVersion {
    position: sticky;
    top: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    background: #FFFFFF;
    border: 2px solid #1280CE;
    border-radius: 5px;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);

    .postingReadVersionContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .postingReadVersionContainer {
            display: flex;
            align-items: center;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 24px;
                min-width: 62px;
                min-height: 62px;
                background: #1280CE;

                .desktop {
                    display: block !important;
                }
                .mobile {
                    display: none !important;
                }
                svg path {
                    fill: #FFFFFF;
                }
            }
            p {
                color: #262626;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
            }
        }
        .postingReadVersionShow {
            display: flex;
            align-items: center;
            margin-right: 24px;
            cursor: pointer;

            i {
                margin-right: 8px;

                svg path {
                    fill: #1280CE;
                }
            }
            span {
                color: #1280CE;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
            }
        }
    }
    .postingReadVersionClose {
        padding: 22px 24px;
        height: 100%;
        border-left: 1px solid #EAEDF3;

        i {
            cursor: pointer;
        }
    }
}
.postingSnapshot {
    padding: 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;

    p {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 180%;
    }
    ul {
        display: flex;
        list-style-type: none;

        li {
            display: flex;
            flex-direction: column;
            margin-right: 40px;

            &:last-child {
                margin-right: 0;

                a {
                    color: #1280CE;
                    cursor: pointer;
                }
            }
            label {
                margin-bottom: 4px;
                color: rgba(38, 38, 38, 0.5);
                font-size: 13px;
                line-height: 180%;
            }
            span, a {
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 150%;
            }
        }
    }
}

.newsPostingRestoreButton svg path {
    fill: #7B8F9C;
}

.postingContent {
    // overflow-y: auto;
    background-color: #fff;

    .cover {
        width: 100%;
        height: calc((100vw - 168px) / 4);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @media print {
            max-height: none !important;
            height: 100% !important;
        }
    }

    .desc {
        padding: rem(24px);
        border-bottom: 1px solid var(--color-grayBlue);
        font-size: rem(14px);
        line-height: rem(21px);
    }

    .content {
        @include content;
    }

    .fileBlock {
        @include platform-spacing(margin-left);
        @include platform-spacing(margin-right);

        padding-top: rem(24px);

        .blockText {
            font-size: rem(14px);
            font-weight: 600;
            color: var(--color-textBlack);
        }

        .blockFiles {
            margin: 1rem 0;
        }
    }

    .likesBlock {
        display: flex;
        height: 72px;
        padding: 0 rem(24px);
        align-items: center;

        .likeButton, .likeButtonActive {
            display: inline-flex;
            border: 1px solid #E8E8E8;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            background-color: #fff;
            padding-left: rem(18px);
            height: rem(40px);
            width: rem(167px);
            align-items: center;
            margin-right: rem(24px);
            cursor: pointer;

            i {
                margin-right: 0.5rem;
            }
        }

        .likeButton:hover {
            background-color: #F6FBF8;
            color: #42B26E;

            svg path {
                fill: #42B26E;
            }
        }

        .likeButton:active {
            background-color: #F1F9F4;
        }

        .likeButtonActive svg path {
            fill: #FF6973;
        }

        .likes {
            display: flex;
            margin-right: rem(24px);

            .likeEllipse {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 40px;
                height: 40px;
                margin-left: -10px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .likedOther {
            color: #000000;
            opacity: 0.5;
        }
    }

    &.mobileContent {
        .cover {
            height: auto;
        }
    }
}

// temp
.file {
    display: flex;
    flex-direction: column;
    padding: rem(8px);
    width: rem(175px);
    height: rem(56px);
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 16px rem(16px) 16px 0;

    .filename {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
            margin-right: 4px;
            margin-left: 4px;
        }
    }

    .size {
        padding-top: 4px;
        font-size: 13px;
        line-height: 18px;
        color: #262626;
        opacity: 0.5;

    }
}

.modal {
    position: relative;
    padding: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 568px;
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    overflow: hidden;

    .modalContent {
        display: flex;
        flex-direction: column;
        height: 100%;

        .modalHeader {
            display: flex;
            justify-content: space-between;
            padding-top: rem(24px);
            padding-left: rem(24px);
            padding-right: rem(24px);

            h3 {
                font-size: rem(22px);
                font-weight: 500;
            }
        }

        .modalBody {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: rem(32px);

            font-size: 13px;

            .modalSelectColor {
                margin-bottom: 200px;
            }
            .documentPublished {
                padding-bottom: 24px;

                div:first-of-type {
                    font-weight: 600;
                    padding-bottom: 8px;
                }
            }

            .deleteTickerText {
                font-size: rem(14px);
                line-height: rem(21px);
            }
        }

        .modalFooter {
            display: flex;
            justify-content: flex-end;
            padding: rem(16px) rem(24px);
            border-top: 1px solid #EAEDF3;
            margin-top: auto;

            > *:not(:last-child) {
                margin-right: rem(16px);
            }
        }
    }

}

.modalOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(38, 38, 38, 0.65);
    z-index: 10;

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
}

@media (max-width: 1024px) {
    .news {
        flex-direction: column;

        .mobileHeader {
            .versionButton {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
        .postingSnapshot {
            display: none;
        }
        .newsWrapper {
            .newsContainer {
                .postingReadVersion {
                    position: relative;
                    top: 0;
                    align-items: flex-start;
                    padding: 12px 12px 44px 12px;

                    .postingReadVersionContent {

                        .postingReadVersionContainer {
                            align-items: flex-start;

                            div {
                                margin-right: 8px;
                                padding: 0;
                                min-width: 26px;
                                min-height: 26px;
                                background: none;

                                .desktop {
                                    display: none !important;
                                }
                                .mobile {
                                    display: block !important;
                                }
                            }
                            p {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                        }
                        .postingReadVersionShow {
                            position: absolute;
                            bottom: 12px;
                            left: 12px;

                            i {
                                width: 16px !important;
                                height: 16px !important;
                            }
                            span {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                        }
                    }
                    .postingReadVersionClose {
                        padding: 0;
                        border: none;

                        i {
                            width: 24px !important;
                            height: 24px !important;

                            svg {
                                circle {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .cover {
                    margin-top: 0 !important;
                }
            }
        }
        .newsHistory {
            .newsHistoryHeader {
                h4 {
                    font-size: 13px;
                    font-weight: 500;
                }
                .newsHistoryHeaderClose {
                    width: 28px;
                    height: 28px;

                    i {
                        width: 7px !important;
                        height: 7px !important;
                    }
                }
            }
        }
    }
}
