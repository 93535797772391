@import "src/mixins/index.scss";

.container {
  position: relative;
  cursor: pointer;
  height: 150px;

  .inputWrapper {
    position: absolute;

    .inputFile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
}

.borderEmpty {
  border: 1px solid var(--color-grayBlue);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: var(--color-white);
  padding: rem(35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emptyTitle {
      margin-top: rem(12px);
      color: var(--color-textBlack);
      font-weight: 500;
      line-height: 150%;
    }

    .emptySubtitle {
      margin-top: rem(2px);
      font-size: 13px;
      line-height: 150%;
      color: #262626;
      opacity: 0.5;
    }
  }
}