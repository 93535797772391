.trainingTheme {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: auto;

  .trainingThemeCreate {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    height: max-content;

    h1 {
      margin-bottom: 24px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
    .trainingThemeForm {
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      .trainingThemeFormFields {
        padding: 24px;

        .trainingThemeFormField {
          margin-bottom: 24px;
        }
        .trainingThemeFormDelimiter {
          margin: 8px 0;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        h2 {
          margin-top: 24px;
          margin-bottom: 16px;
          color: #262626;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
        }
        .trainingThemeFormInfo {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          padding: 16px;
          background: #FBFBFD;
          border-radius: 5px;

          i {
            margin-right: 10px;

            svg path {
              fill: #1280CE;
            }
          }
          span {
            color: #000000;
            font-size: 14px;
            line-height: 19px;
            opacity: 0.5;
          }
        }
      }
      .trainingThemeFormButtons {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #EAEDF3;

        button {
          margin-right: 16px;
        }
      }
    }
  }
  .trainingThemeView {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;
    height: max-content;

    .trainingThemeViewHeader {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      background: #FFFFFF;
      border-bottom: 1px solid #EAEDF3;

      ul {
        position: absolute;
        display: flex;
        top: 16px;
        right: 24px;

        li {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;

            svg path {
              fill: #FF6973;
            }
          }
        }
      }
      h1 {
        margin-bottom: 4px;
        color: #262626;
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
      }
      p {
        color: #262626;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;
      }
    }
    .trainingThemeViewContent {
      margin-top: 8px;
      padding: 0 24px;
    }
  }
}
@media (max-width: 768px) {
  .trainingTheme {
    .trainingThemeCreate {
      padding: 12px;

      .trainingThemeForm {
        .trainingThemeFormFields {
          padding: 12px;
        }
      }
    }
  }
}