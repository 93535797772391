@import "src/mixins/index.scss";

.show {
  > button svg {
    circle {
      fill: #1280CE;
    }

    path {
      fill: white;
    }
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.65);
  display: block;
  overflow: auto;
}

.modal {
  position: absolute;
  bottom: 0;
  margin: rem(16px);
  width: calc(100% - 32px);
  background-color: var(--color-white);
  border: 1px solid transparent;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .modalMenu {
    .item {
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
      justify-content: space-between;
      margin-right: 0;

      svg path {
        fill: #1280CE;
      }

      .titleWrapper {
        display: flex;
        height: 100%;
        align-items: center;

        i {
          margin-top: 2px;
          margin-right: 6px;
        }

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    .cancelBtn {
      width: 100%;
      border-top: 1px solid var(--color-grayBlue);
      padding: rem(10px) rem(12px);
    }
  }
}