@import "src/mixins/index.scss";

.container {
  @include platform-spacing(padding);
  padding-top: rem(24px);
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  h1 {
    padding-left: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;


  img {
    width: rem(120px);
    height: rem(120px);
    border-radius: 50%;
  }

  .infoContainer {
    margin-left: rem(16px);
    position: relative;
    cursor: pointer;

    p {
      color: var(--color-textBlack);
      font-size: 13px;
      line-height: 150%;
      opacity: 0.5;
    }

    .btnContainer {
      display: flex;
      padding-top: 8px;

      button {
        display: inline-flex;
      }

      & > div {

        &:first-of-type {
          margin-right: 16px;

          svg path {
            fill: #1280CE;
          }
        }

        &:last-of-type {
          svg path {
            fill: #d10000;
          }
        }

        i {
          margin-right: 8px;
        }
      }
    }

  }
}

.formContainer {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .name {
    @include platform-spacing(padding);
    padding-bottom: rem(16px);
  }

  .desc {
    @include platform-spacing(padding);
    padding-top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }

  .footer {
    display: flex;
    border-top: 1px solid #EAEDF3;
    @include platform-spacing(padding);
    padding-top: rem(16px);
    padding-bottom: rem(16px);

    & > div:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.mobile {
  .avatarContainer {
    .infoContainer {
      .btnContainer {
        flex-direction: column;
        > * {
          width: 100%;
          &:first-child {
            margin-bottom: rem(8px);
          }
        }
      }
    }
  }

  .formContainer {
    .footer {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        &:first-child {
          margin-right: 0;
          margin-top: rem(16px);
        }
      }
    }
  }
}