@import "src/mixins/index.scss";

.headerWrapper {
  display: flex;
  align-items: center;
  background-color: white;
  padding: rem(16px) rem(24px);
  border-bottom: 1px solid var(--color-grayBlue);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: rem(16px);

    .logo {
      display: flex;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      background-color: #EAEDF4;
    }

    .userInfoBlock {
      .userInfo {
        @include header-large;
        word-break: break-all;
        word-wrap: break-word;
      }

      .userPosition {
        font-family: 'NotoSans', serif;
        font-size: rem(13px);
        font-weight: 500;
        line-height: rem(19.5px);
        color: #262626;
        opacity: 0.5;
        margin-top: rem(4px);
      }
    }

    .blockedUser {
      align-self: flex-start;
      width: fit-content;
      font-family: 'NotoSans', serif;
      font-weight: 700;
      font-size: rem(10px);
      line-height: rem(10px);
      color: #FFFFFF;
      background-color: #FF6973;
      padding: rem(4px);
      /*margin-top: rem(7.5px);*/
      border-radius: 5px;
      &.blockedUserCenter {
        align-self: center;
        margin-bottom: rem(4px);
      }
    }

  }

  .headerActions {
    align-self: flex-start;
  }
}
