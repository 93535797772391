@import "src/mixins/index.scss";

.comments {
  display: flex;
  flex-direction: column;
  @include platform-spacing(padding);

  h2 {
    margin-bottom: 24px;
    color: #262626;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }

  .container {
    @include platform-spacing(padding);
    padding-top: rem(24px);
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    h3 {
      margin-bottom: 16px;
      color: #262626;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      & + div {
        margin-bottom: 16px;
      }
    }

    .line {
      margin: 32px 0;
      height: 1px;
      background-color: var(--color-grayBlue);
    }

    .sortWrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .commentsCount {
        @include text-middle;
        line-height: 24px;
        font-weight: 600;
      }

      .sorting {
        display: flex;

        div:first-child {
          color: var(--color-textBlack);
        }

        div:last-child {
          color: var(--color-blue);
        }

        div + div {
          margin-left: 0.5rem;
        }
      }
    }
  }
}