@import "src/mixins/index.scss";

.tool {
  display: flex;
  padding: 1rem 0;

  button {
    margin-right: 1rem;
  }

  &.mobile {
    flex-direction: column-reverse;

    button {
      margin-right: 0;
    }
    & > :not(:first-child) {
      margin-bottom: rem(16px);
    }
  }
}
