@import "src/mixins/index.scss";

.tool {
    display: flex;
    padding: 1rem 0;

    button {
        margin-right: 1rem;
    }

    &.mobile {
        flex-direction: column-reverse;

        button {
            margin-right: 0;
        }
        & > :not(:first-child) {
            margin-bottom: rem(16px);
        }
    }
}
.toolDivider {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 0 !important;
}
