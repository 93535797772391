@import "src/mixins/index.scss";

.tabUsers {
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-x: hidden;
    position: relative;
    flex-grow: 1;
    @media (max-width: 768px) {
        padding-left: rem(16px) !important;
        padding-right: rem(16px) !important;
    }

    .loader {
        // position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
    .loaderContainer > * {
        background-color: transparent;
    }
    .users {
        margin-bottom: 24px;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            h2 {
                margin: 0;
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .table {
            background: #ffffff;
            border: 1px solid #eaedf3;
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            .filter {
                padding: 24px;
                border-bottom: 1px solid #eaedf3;
            }
            .pagination {
                padding: 16px 24px;
                background: #ffffff;
                border-top: 1px solid #eaedf3;
                border-radius: 0 0 5px 5px;
            }
        }
    }
    .title {
        opacity: 1 !important;
        width: 100%;

        &:hover {
            color: #1280ce !important;
        }
    }
    .subheader {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #262626;
        opacity: 0.5;
        margin-bottom: rem(6px);
    }
}
