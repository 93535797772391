.backButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;

  svg {
    overflow: visible;
    border-radius: 50%;
  }

  &:hover {
    svg {
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.05);

      path:nth-child(2) {
        fill: #1280CE;
      }
    }
  }

  &:active {
    svg {
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.05);

      path:nth-child(1) {
        fill: #F3F9FD;
      }

      path:nth-child(2) {
        fill: #1280CE;
      }
    }
  }
}