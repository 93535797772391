@import "src/mixins/index.scss";

.content {
  @include platform-spacing(padding);
  padding-top: rem(24px);

  .pageTitle {
    @include header-large;
    margin: 0 0 1rem 0;
  }
}