@import 'src/mixins/index.scss';

.modal {
    display: flex;
    flex-direction: column;
    width: 572px;

    .head {
        display: flex;
        justify-content: space-between;
        height: 56px;
        padding: 24px 24px 0 24px;
        align-items: center;

        .text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            align-self: flex-end;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 32px;

        .hint {
            margin-bottom: 16px;
        }

        .resize {
            display: flex;
            align-items: center;

            .resizeLabel {
                padding-right: 8px;
            }

            .resizeControls {
                display: flex;
                align-items: center;

                .resizeValue {
                  padding-left: rem(8px);
                  padding-right:rem(8px);
                }

                .resizeButton {
                    background-color: #fff;
                    border: 1px solid #262626;
                    border-radius: 9999px;
                    padding: 5px;

                    i {
                        margin-bottom: 0;
                    }

                    svg path {
                      fill: #262626;
                    }

                    &.disabled {
                        border: 1px solid #7b8f9c;
                        cursor: not-allowed;

                        svg path {
                          fill: #7b8f9c;
                        }
                    }
                }
            }
        }

        .dropZone {
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border: 1px dashed #eaedf3;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            cursor: pointer;
            padding: 1.5rem 1rem;

            i {
                margin-bottom: 8px;

                svg path {
                    fill: var(--color-blue);
                }
            }

            p {
                max-width: 320px;
                text-align: center;
                font-size: 13px;
                line-height: 19.5px;
                color: #262626;
                opacity: 0.55;
            }
        }

        .divider {
            background: #eaedf3;
            height: 1px;
            margin: 1rem -32px;
        }

        .text {
            font-size: 14px;
            line-height: 19.5px;
            color: #262626;
        }

        .text + .text {
            margin-top: 1rem;
        }
        .error {
            font-size: 14px;
            line-height: 19.5px;
            color: #ff6973;
        }
    }

    .footer {
        border-top: 1px solid #eaedf3;
        display: flex;
        flex-direction: row-reverse;
        padding: 16px 24px;

        div + div {
            margin-right: 16px;
        }
    }

    &.mobile {
        width: auto;
        overflow: hidden;

        .content {
            padding: rem(24px);
        }

        .footer {
            flex-direction: column;
        }
    }
}
