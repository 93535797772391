@import "src/mixins/index.scss";

.tabNews {
    display: flex;
    flex-direction: column;
    @include platform-spacing(padding);
    padding-top: 24px;
    overflow-x: hidden;

    .news {
        margin-bottom: 24px;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            h2 {
                margin: 0;
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }

            ul {
                display: flex;
                padding: 4px;
                background: #F3F6FA;
                border-radius: 4px;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    &.active, &:hover {
                        background: #1280CE;
                        border-radius: 5px;

                        svg path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

    .titleBlock {
        width: 100%;
        display: flex;
        color: #262626 !important;

        &.mobileTitleBlock {
            flex-direction: column;

            .titleBlockTags {
                margin-bottom: rem(8px);
            }
        }

        .title {
            margin-bottom: 8px;
        }
        &:hover {
            .title {
                color: #1280CE;
            }
        }

        .title, .projectTitle {
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .title {
            -webkit-line-clamp: 2;
        }

        .projectTitle {
            -webkit-line-clamp: 2;
        }

        .titleBlockLogo {
            margin-right: 12px;
            min-width: 88px;
            width: 88px;
            height: 56px;
            background: #EAEDF3;
            border-radius: 5px;

            img {
                height: 100%;
                width: 100%;
                border-radius: 5px;
                object-fit: cover;
            }

            &.logoMobile {
                margin-top: rem(8px);
                width: 100%;
                height: 77px;
                img {
                    width: 100%;
                }
            }
        }

        .titleBlockTags {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            span {
                margin-right: 6px !important;
                padding: 4px;
                border-radius: 5px;
                font-size: 10px !important;
                font-weight: bold;
                line-height: 10px !important;
                text-transform: uppercase;
                opacity: 1 !important;

                &:last-child {
                    margin-right: 0 !important;
                }

                &.yellow {
                    background: #FFE05A;
                    color: #262626 !important;
                }

                &.blue {
                    background: rgba(18, 128, 206, 0.15);
                    color: #1280CE !important;
                }
            }
        }

        .title {
            line-height: 14px !important;
        }

        .titleBlockProject {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .titleBlockProjectAvatar {
                display: flex;
                margin-right: 4px;
                min-width: 14px;
                width: 14px;
                height: 14px;
                background: #EAEDF3;
                border-radius: 14px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                    object-fit: cover;
                }
            }

            span {
                margin-top: -1px;
                color: #1280CE !important;
                font-size: 11px !important;
                font-weight: 500;
                line-height: 11px !important;
                opacity: 1 !important;
            }
        }
    }

    .deleteActionButton {
        svg path {
            fill: #E06169 !important;
        }
    }
}

.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}
