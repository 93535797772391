.buttonGroup {
    display: flex;
    align-items: center;
    width: 100%;

    &.center {
        justify-content: center;
    }
    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
    }
    &.lastToEnd {
        div:last-child {
            margin-left: auto;
        }
    }
    &.firstToStart {
        div:first-child {
            margin-right: auto;
        }
    }
    div {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}