@import "src/mixins/index";

.tagsPage {
    margin-top: 1rem;

    & .mainCard {
        margin: 0 1rem;
    }

    & .title {
        @include text-large;
        font-weight: 400;
        margin: 2rem 0;
        padding-left: 2rem;
    }

    & .addButton {
        text-align: right;
        margin-bottom: 2rem;
    }

    .categories {
        margin-top: 1rem;
    }
}

