@import "src/mixins/index.scss";

$btn-color: #1280CE;

.tree {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-left: 32px;
  padding-right: 32px;

  .searchInput {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .toolbar {
    display: flex;
    height: rem(24px);
    margin: rem(8px);

    .btn {
      display: flex;
      font-size: 11px;
      color: $btn-color;
      justify-content: center;
      flex-basis: 50%;
      flex-shrink: 1;
      align-items: center;
      cursor: pointer;

      i {
        margin-right: 8px;
      }
    }

    .btn + .btn {
      border-left: #EAEDF3 1px solid;
    }
  }

  .allBtn {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 36px;
    height: 32px;
    width: 100%;
    background: transparent;
    border: none;
    color: #1280CE;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;

    &.allBtnActive, &:hover {
      background: rgba(39, 155, 217, 0.05);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 32px;
        background: #1280CE;
      }
    }

    i {
      margin-right: 10px;

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #1280CE;
        }
      }
    }
  }

  .sizerContainer {
    flex-grow: 1;
  }
}