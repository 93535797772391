@import "src/mixins/index.scss";

.toolbar {
  display: flex;
  justify-content: center;

  div:first-child {
    margin-right: rem(16px);
  }
}

.content {
  padding: rem(16px);
}