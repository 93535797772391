.blockWrapper {
    width: 100%;
    background-color: #46535F;
    z-index: 1;
    position: absolute;
}

.newsTickerWrapper {
    display: flex;
    align-items: center;
    height: 28px;
    background-color: #46535F;
    color: #fff;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.newsTickerTitle {
    padding-right: 8px;
}

.newsTickerDesc {
    opacity: 0.6;
}

.newsGroup {
    height: 28px;
    display: flex;
}
