@import "src/mixins/index.scss";
@import "mixins";
@import 'keyframes';

$loading-color: var(--color-primary-blue);

.loading {
  @include animation__loading--spin;
  width: 10.5rem;
  height: 10.5rem;
}

.loading__svg {
  fill: transparent;
}

.loading__svg circle {
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 240;
}

.loading__stroke {
  stroke: $loading-color;
  stroke-dashoffset: 40;
}

.loadingSmall .loading__stroke {
  stroke-dashoffset: 99;
}

.loadingStop {
  @include animation__loading--stop;
}

.loadingSmall {
  width: 2rem;
  height: 2rem;

  circle {
    stroke-width: 12;
  }
}

.loadingSmall .loading__svg {
  stroke: $loading-color;
}

.loading__background {
  stroke: #e0e0e0;
  stroke-dashoffset: 0;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  //background-color: rgba(var(--color-white), 0.6);
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  z-index: 2000;
}

.loadingRelativeOverlay {
  position: absolute;
  //background: rgba(255, 255, 255, 0.6);
}

.loadingOverlayStop {
  display: none;
}
