@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.modal {
    position: absolute;
    outline: none;
    overflow: unset;
}
.modalContent {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 570px;
    height: 490px;
    position: relative;
    padding-bottom: 74px;

    .title {
        display: flex;
        justify-content: space-between;
        height: rem(30px);
        font-size: 22px;
        //padding-left: rem(32px);
        //padding-right: rem(32px);
    }

    .body {
        margin-top: 2rem;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;

        & div + div {
            margin-left: 1rem;
        }
    }
}

.label, .hint {
    @include inputLabel;
    margin-top: 1rem;
}

.reactTagsInput {
    margin-top: 0.5rem;
    overflow-y: auto !important;
    max-height: 215px;
    height: 215px;

    .reactTagsInputTag {
        display: inline-flex;
        align-items: center;
        margin: 0 6px 6px 0;
        padding: 7px 6px;
        background: #F5F5F5;
        border: none;
        border-radius: 3px;

        span {
            margin: 0;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 14px;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1px;
            margin-left: 6px;
            width: 14px;
            height: 14px;
            background: #1280CE;
            border-radius: 14px;
            cursor: pointer;

            svg path {
                fill: #FFFFFF;
            }
        }
    }
}
