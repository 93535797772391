.stepWrapper {
  padding: 24px 24px 32px 24px;
}

.step {
  border-bottom: 1px solid #eaedf3;
  padding-bottom: 24px;
  padding-top: 16px;

  &:first-child {
      padding-top: 0;
  }

  & > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  }

  .stepTitle {
      padding-bottom: 16px;
      white-space: break-spaces;
  }

  .stepRefresh {
      display: flex;
      color: #1280ce;
      cursor: pointer;

      i {
          margin-right: 8px;
      }
  }

  .stepButtons {
      display: flex;
      button {
          white-space: pre-wrap;
          text-align: left;
      }
  }

  .stepButtons > div:not(:last-child) {
      margin-right: 16px;
  }
}

.blockStepsComplete {
  padding-top: 16px;
}

.attachmentsWrapper {
  padding-bottom: 16px;
}

.articlesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-left: -8px;
}

.article {
  width: 100%;
  display: flex;
  border: 1px solid #eaedf3;
  border-radius: 5px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;

  @media (min-width: 768px) {
      width: calc(50% - 16px);
  }

  @media (min-width: 1440px) {
      width: calc(100% / 3 - 16px);
  }

  .titleBlock {
      padding: 16px;
      width: 100%;
      display: flex;
      color: #262626 !important;

      &:hover {
          .title {
              color: #1280ce;
          }
      }
  }

  .titleWrapper {
      min-width: 0;
      hyphens: auto;
  }

  .titleBlockInner {
      margin-right: 12px;
      width: 88px;
      height: 56px;
      background: #EAEDF3;
      border-radius: 5px;
      flex-shrink: 0;
  }

  .titleBlockCover {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
  }
}

.linkWrapper {
  display: flex;
  justify-content: space-between;

  & > a {
      display: flex;
      align-items: center;

      &:not(:last-child) {
          padding-bottom: 16px;
      }
  }

  .link {
      display: flex;
  }

  .linkIcon {
      margin-right: 8px;
  }

  .linkName {
      padding-bottom: 4px;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: var(--color-blue);
  }

  .linkUrl {
      font-size: 13px;
      line-height: 18px;
      color: rgba(#262626, 0.5);
  }
}

.filesWrapper {
  display: flex;
  flex-wrap: wrap;

  & > div:not(:last-child) {
      margin-right: 16px;
  }
}

.file {
  display: flex;
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #eaedf3;
  border-radius: 5px;
  cursor: pointer;

  .fileIconWrapper {
      padding-right: 12px;
  }

  .descWrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;
  }

  .fileName {
      font-size: 13px;
      line-height: 18px;
      padding-bottom: rem(8px);
  }

  .fileDescription {
      color: rgba(#262626, 0.5);

      .fileType {
          text-transform: uppercase;
      }
  }
}

.imageWrapper {
  width: 100%;
  max-width: 550px;
  margin-bottom: 16px;

  img {
      width: 100%;
      height: auto;
  }
}
