@import "src/mixins/index.scss";

.container {
  padding: rem(24px);
  display: flex;
  flex-direction: column;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: rem(24px);
    line-height: rem(33px);
    margin-bottom: rem(24px);
    color: #262626;
  }
}
