@import "src/mixins/index.scss";

$borderColor: #E8E8E8;
$selection: #1280CE;
$hovered: #188FE3;
$hoverBG: linear-gradient(0deg, rgba(18, 128, 206, 0.05), rgba(18, 128, 206, 0.05)), #FFFFFF;

.container {
    position: relative;

    .editBlock {
        position: absolute;
        display: none;

        @media (max-width: 1024px) {
            display: none !important;
        }
    }

    .unreadBlock {
        position: absolute;
        right: 0;
        top: 8px;
    }

    &:hover .editBlock {
        padding: 0 5px;

        display: flex;
        right: 0;
        top: 8px;
    }

    &:hover .unreadBlock {
        display: none;
    }

    .unreadLabel {
        top: 2px;
        right: 12px;
    }

    .labelGray {
        background-color: #7B8F9C;
    }

    &:hover {
        background: rgba(39, 155, 217, 0.05);
    }

    .node {
        padding-right: 15px;
    }

    &:hover.editableNode .node {
        padding-right: 65px;
    }

    .statistics {
        padding-right: 8px;
    }

    @media (max-width: 767px) {
        .node {
            padding-right: 0;
            padding-left: 0;

            & > div:first-child {
                display: none;
            }
        }
    }
}


.node {
    display: flex;
    height: rem(32px);
    padding-left: rem(8px);
    align-items: center;
    border-left: 2px solid transparent;

    .checkbox {
        @media (max-width: 1024px) {
            display: none;
        }
    }
    .addDraftContainer {
        height: 100%;
        display: flex;

        input {
            border: 2px solid var(--color-blue);
            border-radius: 5px;
            margin-right: 12px;
            padding-left: 8px;
            padding-right: 8px;
        }

        button {
            position: relative;
            border: 0;
            background: none;
            border-radius: 5px;
            width: 32px;
            height: 32px;
            padding: 0;
            display: flex;
            align-items: center;

            i {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .ok {
            background-color: var(--color-green);
            margin-right: 4px;
        }

        .cancel {
            border: 1px solid var(--color-input-border);
        }
    }

    .clickable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;
        cursor: pointer;

        & > div {
            display: inline-flex;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 1;

            .nodeName {
                font-weight: 500;
                opacity: 1;
            }
        }

        .nodeName {
            //color: #262626;
            font-size: rem(13px);
            line-height: rem(18px);
            opacity: 0.5;
        }

        span > img {
            vertical-align: baseline;
            //margin-right: rem(11.46px);
        }
    }
    .nodeRole {
        min-width: 100px;
        width: 100px;
    }
}

.selected {
    background: rgba(39, 155, 217, 0.05);
    border-left: 2px solid $selection;

    .clickable {
        .nodeName {
            color: #1280CE;
        }
    }
}
