@import "src/mixins/index.scss";

.like {
    display: flex;
    height: 72px;
    @include platform-spacing(padding-left);
    @include platform-spacing(padding-right);
    align-items: center;

    @media print {
        display: none;
    }
    @media (max-width: 500px) {
        flex-direction: column;
        height: auto;
        padding: 16px 30px;

        .button {
            justify-content: center;
            width: 100%;

            &:first-child {
                margin-bottom: 8px;
            }
        }
        .button + .button {
            margin-left: 0 !important;
        }
    }

    .button {
        display: flex;
        flex-direction: row;
        padding: 10px 16px;
        height: 40px;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;

        i {
            margin-right: 0.5rem;
        }

        &.progress > span {
            color: #000000;
            opacity: 0.5;
        }

        &.liked {
            background: #F1F9F4;
            color: #42B26E;

            .likes {
                opacity: 1;
            }
        }

        &.disliked {
            background: #FFF3F4;
            color: #FF6973;

            .likes {
                opacity: 1;
            }
        }
    }

    .red {
        i {
            svg path {
                fill: #FF6973;
            }
        }

        &:not(.progress) {
            &:hover, &:active {
                color: #FF6973;
            }

            &:hover {
                background-color: #FFF7F8;
            }

            &:active {
                background-color: #FFF3F4;
            }
        }
    }

    .green {
        i {
            svg path {
                fill: #42B26E;
            }
        }

        &:not(.progress) {
            &:hover, &:active {
                color: #42B26E;
            }

            &:hover {
                background-color: #F6FBF8;
            }

            &:active {
                background-color: #F1F9F4;
            }
        }
    }

    .button + .button {
        margin-left: 1rem;
    }

    .likesCount {
        display: flex;
        margin-left: 2rem;

        .likeEllipse {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 40px;
            height: 40px;
            margin-left: -10px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .likedOther {
        color: #000000;
        opacity: 0.5;
        margin-left: rem(24px);
    }

    .likes {
        opacity: 0.6;
        white-space: pre;
    }
}
