@import "src/mixins/index.scss";

.modal {
  display: flex;
  flex-direction: column;
  width: 572px;

  .head {
    display: flex;
    justify-content: space-between;
    height: 56px;
    padding: 24px 24px 0 24px;
    align-items: center;

    .text {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      align-self: flex-end;
    }
  }

  .subTitle {
    padding: 32px 32px 16px;
    border-bottom: 1px solid #EAEDF3;

    &__noContent {
      border: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    max-height: 435px;
    overflow: auto;

    .hint {
      line-height: 21px;
      white-space: pre-wrap;
    }
  }

  .footer {
    border-top: 1px solid #EAEDF3;
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    & > div:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  &.mobile {
    width: auto;
    .head {
      height: auto;
    }
    .content {
      padding: rem(24px);
    }
    .footer {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        &:first-child {
          margin-right: 0;
          margin-top: rem(16px);
        }
      }
    }
  }
}
