@import '../../../../mixins/index';

.toggleTreeButton {
    position: fixed;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-top: 0;
    width: 39px;
    height: 39px;
    z-index: 2;

    i {
        transform: scale(-1, 1);
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        display: flex;
    }
}
.treeContainer {
    &.treeContainerHidden {
        display: none;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(38, 38, 38, 0.65);
        z-index: 2;
    }
}
.container {
    position: relative;
    display: flex;
    height: calc(100svh - 57px);

    &.withNewsTicker {
        height: calc(100vh - 90px);
    }

    &.bgWhite {
        background-color: #fff;
    }

    .tree {
        position: fixed;
        width: 100%;
        max-width: rem(280px);
        height: calc(100vh - 58px);
        overflow: auto;
        background-color: #fff;

        &.withNewsTicker {
            height: calc(100vh - 90px);
        }
    }

    .content {
        width: 100%;
        margin-left: 280px;
        border-left: 1px solid #eaedf3;

        & > div {
            display: flex;
            min-height: 100%;
        }
        &.maxWidth {
            max-width: calc(100% - 280px);
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0;

            &.maxWidth {
                max-width: none;
            }
        }
        .header {
            width: 100%;

            h1 {
                display: flex;
                align-items: center;
                padding: 24px 12px 17px;
                font-size: 24px;
                font-weight: 600;
                line-height: 33px;

                .iconBack {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    border: 1px solid var(--color-grayBlue);
                    border-radius: 9999px;
                    margin-right: 16px;
                    cursor: pointer;
                }
            }

            .tabs {
                display: flex;
                background-color: #fff;
                border-bottom: 1px solid #eaedf3;

                .tab {
                    position: relative;
                    padding: 13px 24px;
                    color: #262626;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 12px;
                    opacity: 0.5;
                    cursor: pointer;

                    &:hover,
                    &.active {
                        color: #1280ce;
                        opacity: 1;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background: #1280ce;
                            border-radius: 5px 5px 0 0;
                        }
                    }
                }
            }
        }

        .filtersPanel {
            display: flex;
            padding: 24px 24px 24px;

            .filter {
                width: 160px;
                padding-left: 8px;
            }

            .dateRange {
                padding-left: 8px;
            }

            .dateFilter {
                width: initial;
            }

            .exportBtn {
                margin-left: auto;
            }
        }

        .countRow {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .whiteBlock {
                @media (min-width: 768px) {
                    padding: 24px;
                    width: calc(100% / 3 - 12px);
                }
            }
        }

        .whiteBlock {
            padding: 16px;
            margin-bottom: 24px;
            width: 100%;
            background-color: #fff;
            border: 1px solid var(--color-grayBlue);
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(#000, 0.05);

            & > div:first-of-type {
                font-weight: 600;
                padding-bottom: 16px;
            }

            .count {
                color: #1280ce;
                font-size: 24px;
                font-weight: 600;
            }

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & > div:first-of-type {
                    padding-bottom: 16px;
                    color: rgba(#262626, 0.5);
                }

                .nameCell {
                    img {
                        width: rem(24px);
                        height: rem(24px);
                    }

                    p {
                        color: #1280ce;
                    }
                }
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 12px 24px;

            .title {
                display: flex;
                align-items: center;
                padding: 14px 12px 17px;
                font-weight: 500;
                font-size: 22px;
            }

            .chartRow {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
                padding-bottom: 24px;
            }

            .chartWrapper {
                position: relative;
                width: 100%;
                padding: 12px;
                min-height: 385px;

                @media (min-width: 768px) {
                    max-width: calc(100% / 2);
                }

                @media (min-width: 1600px) {
                    max-width: calc(100% / 3);
                }

                h3 {
                    padding-bottom: 16px;
                    font-weight: 600;
                }

                & > div {
                    max-width: 100%;
                }
            }

            .listWrapper {
                position: relative;
                width: 100%;
                padding: 12px;

                @media (min-width: 768px) {
                    max-width: calc(100% / 2);
                }

                @media (min-width: 1600px) {
                    max-width: calc(100% / 3);
                }

                h3 {
                    padding-bottom: 16px;
                    font-weight: 600;
                }

                & > div {
                    max-width: 100%;
                    background-color: #fff;
                    border: 1px solid var(--color-grayBlue);
                    border-radius: 5px;
                    padding: 24px;
                }

                .list {
                    .listEmpty {
                        opacity: 0.5;
                    }
                }
            }

            .requestsRow,
            .listRow {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 16px;
            }

            .requestsRow {
                padding-top: 16px;
                margin-bottom: 8px;
                border-bottom: 1px solid var(--color-grayBlue);

                & > div:first-of-type {
                    display: flex;
                    align-items: center;
                    color: #1280ce;

                    i {
                        margin-right: 6px;

                        svg path {
                            fill: #1280ce;
                        }
                    }
                }

                & > div:last-of-type {
                    color: rgba(#262626, 0.5);
                }
            }

            .listRow {
                & > div:first-of-type {
                    display: flex;
                    align-items: center;
                    color: rgba(#262626, 0.5);
                    line-height: 24px;
                }
            }

            .sessionTimeWrapper {
                margin-bottom: 24px;
                padding: 24px;
                background-color: #fff;
                border: 1px solid var(--color-grayBlue);
                border-radius: 5px;

                .sessionTimeTitle {
                    font-size: 14px;
                    font-weight: 500;
                    padding-bottom: 16px;
                }

                .sessionTime {
                    font-size: 24px;
                    font-weight: 500;
                    color: #0f7ac7;
                }
            }

            .tableTitle {
                width: 100%;
                padding-bottom: 24px;
                font-size: 22px;
                font-weight: 500;
            }

            .tableWrapper {
                width: 100%;
                background: #ffffff;
                border: 1px solid #eaedf3;
                border-radius: 5px;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            }
        }
    }

    &.mobile {
        .content {
            margin-left: 0;
            max-width: none;

            .body {
                padding: 0 4px 24px;

                .chartWrapper {
                    min-height: auto;
                }
            }
        }
    }
}

.nameCell {
    display: flex;
    align-items: center;

    img {
        width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        margin-right: 8px;
    }
}

.readDurationCell {
    font-size: 13px;
    color: rgba(#262626, 0.5);
}

.iconSuccess {
    display: flex;
    align-items: center;

    i {
        margin-right: 8px;
    }

    svg path {
        fill: #50b678;
    }
}

.iconError {
    display: flex;
    align-items: center;

    i {
        margin-right: 8px;
    }

    svg path {
        fill: #ff6973;
    }
}
