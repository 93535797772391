@import "src/mixins/index.scss";

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    background-color: rgba(26, 26, 26, 0.65);
    overflow: auto;
    z-index: 10001;

    &.withNewsTicker {
        top: 28px;
    }
}

.modalHeaderTablet {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: none;
    flex-direction: column;
    padding: 12px 20px;
    background-color: rgba(26, 26, 26, 0.9);

    @media (min-width: 768px) and (max-width: 1199px) {
        display: flex;
    }
    .top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .title {
            display: flex;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;

            p {
                margin-left: 4px;
                color: rgba(#fff, 0.5);
                font-weight: 400;
                min-width: fit-content;

                span {
                    text-transform: lowercase;
                }
            }
        }
        .closeIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
            min-width: 32px;
            min-height: 32px;
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;

        button {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.modalHeader {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: none;
    min-height: rem(64px);
    padding-right: rem(24px);
    align-items: center;
    background-color: rgba(26, 26, 26, 0.9);

    @media print {
        display: none;
    }
    @media (max-width: 767px) {
        display: flex;
    }
    @media (min-width: 1200px) {
        display: flex;
    }
    .modalHeaderDesktop {
        display: flex;

        @media (max-width: 1199px) {
            display: none;
        }
    }
    .modalHeaderMobile {
        @media (min-width: 1200px) {
            display: none;
        }
    }
    .fileWrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .iconWrapper {
            padding-right: rem(16px);
        }

        .descWrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }

        .changeFile {
            display: flex;
            align-items: center;
            color: #1280CE;

            i {
                margin-right: 6px;

                svg {
                    fill: #1280CE;
                }
            }
        }

        .fileName {
            color: #fff;
            padding-bottom: rem(8px);
        }

        .fileDescription {
            color: rgba(#fff, 0.5);

            .fileType {
                text-transform: uppercase;
            }
        }
    }

    .closeIcon {
        width: 64px;
        height: 64px;
        flex-shrink: 0;

        button {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .iconButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: rem(10px) rem(16px);
        background: #FFFFFF;
        border: 0;
        border-radius: 5px;
        font-weight: 500;

        i {
            margin-right: rem(6px);
        }
        svg {
            * {
                fill: #1280CE;
            }
        }
    }

    .downloadButton {
        margin-left: 12px;
        background-color: #1280CE;
        color: #fff;

        svg * {
            fill: #fff;
        }

        &:hover {
            background: #188FE3;
            box-shadow: 0 2px 4px rgba(18, 128, 206, 0.35);
        }
        &:active {
            background: #0F7AC7;
        }
    }

    .mobileMenu {
        position: absolute;
        top: 64px;
        left: 0;

        .noStyleButton {
            height: 48px;
            width: 100vw;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: rgba(26, 26, 26, 0.9);

            i {
                margin-left: 16px;
                margin-right: 16px;
                margin-bottom: 0;
            }

            div {
                color: white;
                width: auto;
            }
        }
    }
}

.modalBody {
    position: relative;
    top: 64px;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 64px);

    &.table {
        top: 0;

        .objWrapper {
            padding-top: 0;
            padding-bottom: 3px;
        }
    }

    video {
        max-width: 100%;
        max-height: 100%;
    }

    .objWrapper {
        width: 100%;
        height: 100%;
        padding-top: rem(32px);
        padding-bottom: rem(32px);

        img {
            display: block;
            margin: 0 auto;
            background-color: #fff;
        }
    }

    .playerWrapper {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        height: 100%;
        width: 100%;
        max-width: rem(726px);
    }
    .videoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-top: rem(32px);
        padding-bottom: rem(32px);
    }

    .inputWrapper {
        width: 100%;
        padding-bottom: rem(24px);
    }

    .imgWrapper {
    }

    .fileWrapper {
        padding-bottom: rem(24px);
    }

    .loader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;

        .errorTitle {
            padding-top: 16px;
            padding-bottom: 4px;
            font-weight: 500;
            color: #fff;
        }

        .errorDesc {
            color: rgba(255, 255, 255, 0.65)
        }
    }

    &.show {
        width: calc(100% - 400px);
    }
}

.modalFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.4);
    z-index: 1;

    button {
        border: 1px solid #fff;
        border-radius: 9999px;
        padding: 5px;

        i {
            margin-bottom: 0;
        }

        &.disabled {
            border: 1px solid #7B8F9C;
            cursor: not-allowed;
        }
    }

    .sizeValue {
        color: #fff;
        padding-left: rem(16px);
        padding-right: rem(16px);
    }
}

.noStyleButton {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 12px;
    padding-right: 12px;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border: 0;
    white-space: nowrap;

    i {
        margin-bottom: 6px;
    }

    &.red {
        div, span {
            color: var(--color-alert)
        }
    }

    div {
        width: 100%;
    }
}

.sidebar {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    background-color: #fff;

    &.show {
        right: 0;
    }

    .sidebarHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 24px 32px;
        line-height: initial;

        i {
            cursor: pointer;
        }
    }

    .sidebarTitle {
        font-size: 22px;
        font-weight: 500;
    }

    .sidebarBody {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.user {
    display: flex;

    .userAvatar {
        width: 24px;
        height: 24px;
        border-radius: 9999px;
        overflow: hidden;
        background-color: #9F9F9F;
        margin-right: 8px;
    }

    .userLink {
        color: #1280CE;
        line-height: 150%;
    }
}

.usage {
    position: relative;
    padding: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 770px;
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.usageOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(#275677, 0.65);
    z-index: 1000;

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
}

.fileInfoRow {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    line-height: 24px;

    div:first-of-type {
        color: rgba(#262626, 0.5);
    }
}

.doc {
    width: 100%;
    height: calc(100vh - 310px);
}
