@import "src/mixins/index.scss";

.filters {
  position: sticky;
  left: 0;
  z-index: 1;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 24px;
  margin-right: -12px;
  margin-left: -12px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  border-bottom: 1px solid #EAEDF3;

  .filtersBtns {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    display: flex;

    & > * {
      margin-right: 16px;
    }
  }

  .row {
    padding-right: 12px;
    padding-left: 12px;

    h4 {
      padding-bottom: 4px;
      opacity: 0.75;
    }
  }

  .createDateInput {
    margin-right: 24px;
  }

  .w-25 {
    width: 25%;
  }

  .w-50 {
    width: 25%;
  }

  .w-100 {
    width: 25%;
  }

  .filterWrapper {
    padding-bottom: 16px;
  }

  .dateRange {
    display: flex;
    padding-bottom: 16px;

    & > div {
      &:nth-of-type(1) {
        width: calc(50%);
        padding-right: 12px;
      }

      &:nth-of-type(2) {
        width: 25%;
        padding-right: 12px;
        padding-left: 12px;
      }

      &:nth-of-type(3) {
        width: 25%;
        padding-left: 12px;
      }
    }
  }

  .rangeInputs {
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;

    .rangeSeparator {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 14px;
    }

    .rangeInputsTo {
      margin-right: 12px;
    }

    .rangeInputsTo + div > div {
      width: 70px;
      border: none !important;
      box-shadow: none;

      & > div:nth-child(2) > span {
        display: none;
      }
    }
  }

  &.mobileFilters {
    .filtersBtns {
      flex-direction: column-reverse;
      button {
        margin-right: 0;
      }
      & > :not(:first-child) {
        margin-bottom: rem(16px);
      }
    }
  }
}

.locationChange {
  .titleBlock {
    font-weight: 600;
    font-size: rem(14px);
    line-height: 150%;
    margin-bottom: 1rem;
  }

  .blockLocation {
    min-height: 40px;
    border: 1px solid var(--color-stroke);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 6px;

    .path {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    i + i {
      margin-left: .5rem;
    }

    i:hover {
      cursor: pointer;
    }

    i:hover > svg > path {
      fill: var(--color-whiteHover);
    }
  }

  .projectWrapper {
    margin: 3px 6px;
    padding: 6px;
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border-radius: 9999px;

    .projectLogo {
      overflow: hidden;
      width: 16px;
      height: 16px;
      background-color: #B19BDF;
      border-radius: 9999px;
      margin-right: 6px;
    }

    .projectRemoveIcon {
      margin-left: 6px;
      display: flex;
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      background-color: #1280CE;
      border-radius: 9999px;
    }
  }
}

.fieldError {
  border: 1px solid var(--color-red) !important;
}

.fieldErrorText {
  padding-top: 4px;
  color: var(--color-red);
}