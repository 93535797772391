@import "src/mixins/index.scss";

.icon {
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin-right: 8px;
  i path {
    fill: #262626;
    opacity: 0.75;
  }

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.icon.opened {
  i path {
    fill: #1280CE !important;
  }
}

.icon.article {
  i path {
    opacity: 1;
    fill: #7B8F9C;
  }
}