$headerHeight: 64px;
$footerHeight: 56px;

.chatWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

.header {
    min-height: $headerHeight;
}

.content {
    position: relative;
    width: 100%;
    height: calc(100% - $headerHeight - $footerHeight);
    overflow: auto;


    .pending {
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
        bottom: 12px;
        left: 0;
        right: 0;
    }
}

.footer {
    min-height: $footerHeight;
}

.emptyMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
        margin-bottom: 16px;
        width: 56px;
        height: 56px;
    }
    p {
        margin-bottom: 4px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
    }
    span {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
        opacity: 0.65;
    }
}

.projectListWrapper {
    max-width: 220px;
    width: 100%;

    @media (max-width: 768px) {
        max-width: 148px;
    }
}

.emptyMessage {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    .title {
        font-size: 20px;
        color: rgba(#143C54, 0.65)
    }
}
