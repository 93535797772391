.project {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  * {
    word-break: break-word;
  }

  .head {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-bottom: 1px solid #EAEDF3;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;
      height: 46px;
      word-break: break-all;

        .favorites {
            cursor: pointer;

            @media (max-width: 1024px) {
                display: none !important;
            }
        }

      & > a:first-of-type {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .image {
        flex-shrink: 0;
        margin-right: 8px;
        width: 36px;
        height: 36px;
        background-size: cover;
        border-radius: 36px;
          img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
          }
      }

      span {
        display: -webkit-box;
        max-height: 46px;
        color: #262626;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      .favorite {
        svg path {
          fill: #1280ce;
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;

      .manager {
        display: flex;
        flex-direction: column;

        span {
          color: #262626;
          font-size: 13px;
          font-weight: normal;
          line-height: 19px;
          opacity: 0.5;
        }

        a {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;
        }
      }

      .activity {
        display: flex;
        flex-direction: column;
        padding-left: 8px;

        span {
          color: #262626;
          font-size: 13px;
          font-weight: normal;
          line-height: 19px;
          opacity: 0.5;
        }

        a {
          display: flex;
          align-items: center;
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;

          .avatar {
            position: relative;
            margin-right: 6px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: #EAEDF3;
            background-size: cover;

            img {
              width: 100%;
              border-radius: 20px;
              object-fit: cover;
            }
          }

          &:hover .popup {
            display: block;
          }
        }
      }
    }
  }

  ul {
    padding: 0 28px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #EAEDF3;

      &:last-child {
        border-bottom: none;
      }

      p {
        display: flex;
        align-items: center;

        i {
          margin-right: 12px;

            svg path {
                fill: #7B8F9C;
            }
        }

        span {
          color: #262626;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
        }
      }

      a {
        display: flex;
        align-items: center;
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;

        i {
          margin-left: 12px;

          svg path {
            fill: #1280CE;
          }
        }
      }
    }
  }
}
