.tiptap .details {
    display: flex;
    margin-top: 10px;
    padding: 5px 7px;
    width: 100%;
    border: 1px solid rgba(51, 139, 194, 1);
    border-radius: 2px;
    overflow: auto;
}
.tiptap .details > button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2219%22%20viewBox%3D%220%200%2018%2019%22%20fill%3D%22none%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M13.5%207.25L9%2011.75L4.5%207.25%22%20stroke%3D%22%23338BC2%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    border: none;
    min-width: 18px;
    width: 18px;
    height: 19px;
    transform: rotate(-90deg);
}
.tiptap .details.is-open > button {
    transform: rotate(0deg);
}
.tiptap .details > div {
    padding-left: 3px;
    width: calc(100% - 18px);
}
.tiptap .details > div > summary {
    color: rgba(38, 38, 38, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}
.tiptap .details p {
    margin-top: 6px;
    margin-bottom: 0;
    padding: 0;
    color: rgba(38, 38, 38, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}
.tiptap .details > div > summary.is-empty::before {
    content: attr(data-placeholder);
    color: rgba(38, 38, 38, 0.5);
    float: left;
    height: 0;
    pointer-events: none;
}
.tiptap .details > div > div > p.is-empty:only-child::before {
    content: 'Текст';
    color: rgba(38, 38, 38, 0.5);
    float: left;
    height: 0;
    pointer-events: none;
}
