@import 'src/mixins/index.scss';

.tabScripts {
    display: flex;
    flex-direction: column;
    @include platform-spacing(padding);
    padding-top: rem(24px);
    overflow-x: hidden;
    position: relative;
    flex-grow: 1;

    @media (max-width: 768px) {
        padding-left: rem(16px) !important;
        padding-right: rem(16px) !important;
    }
    .loader {
        // position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
    .loaderContainer > * {
        background-color: transparent;
    }
    .scripts {
        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include platform-spacing(margin-bottom);

            h2 {
                margin: 0;
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
            ul {
                display: flex;
                padding: 4px;
                background: #f3f6fa;
                border-radius: 4px;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    &.active, &:hover {
                        background: #1280ce;
                        border-radius: 5px;

                        svg path {
                            fill: #ffffff;
                        }
                    }
                }
            }
        }
        [class*="unread"] {
            background-color: unset !important;
        }
    }
    .titleBlock {
        width: 100%;
        display: flex;

        &.mobileTitleBlock {
            flex-direction: column;

            .titleBlockTags {
                margin-bottom: rem(8px);
            }
        }
        &:hover {
            .title {
                color: #1280ce;
            }
        }
        .desc {
            font-size: 13px;
            line-height: 150%;
            color: rgba(#262626, 0.5);
        }
        .title, .projectTitle {
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        .title {
            -webkit-line-clamp: 2;
        }
        .projectTitle {
            -webkit-line-clamp: 2;
        }
        .titleBlockLogo {
            margin-right: 12px;
            min-width: 88px;
            width: 88px;
            height: 56px;
            background: #eaedf3;
            border-radius: 5px;

            img {
                height: 100%;
                width: 100%;
                border-radius: 5px;
                object-fit: cover;
            }
            &.logoMobile {
                margin-top: rem(8px);
                width: 100%;
                height: 77px;
                img {
                    width: 100%;
                }
            }
        }
        .titleBlockTags {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            span {
                margin-right: 6px !important;
                padding: 4px;
                border-radius: 5px;
                font-size: 10px !important;
                font-weight: bold;
                line-height: 10px !important;
                text-transform: uppercase;
                opacity: 1 !important;

                &:last-child {
                    margin-right: 0 !important;
                }
                &.yellow {
                    background: #ffe05a;
                    color: #262626 !important;
                }
                &.blue {
                    background: rgba(18, 128, 206, 0.15);
                    color: #1280ce !important;
                }
            }
        }
        .title {
            line-height: 14px !important;
        }
        .titleBlockProject {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .titleBlockProjectAvatar {
                display: flex;
                margin-right: 4px;
                width: 14px;
                height: 14px;
                background: #eaedf3;
                border-radius: 14px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                    object-fit: cover;
                }
            }
            span {
                margin-top: -1px;
                color: #1280ce !important;
                font-size: 11px !important;
                font-weight: 500;
                line-height: 11px !important;
                opacity: 1 !important;
            }
        }
    }
}
.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}
