@import "src/mixins/index.scss";

.attachments {
    .uploadLabel {
        display: flex;
        align-items: center;
        height: 56px;
        background: rgba(39, 155, 217, 0.1);
        border: 1px solid rgba(39, 155, 217, 0.15);
        border-radius: 5px;
        color: var(--color-blue);
        padding-left: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
        font-weight: 500;

        i {
            margin-right: 0.5rem;

            path {
                fill: #1280CE;
            }
        }
    }
    label.uploadLabel input[type="file"] {
        display: none;
    }
    .files {
        display: flex;
        flex-wrap: wrap;
        margin: -8px -12px;

        .file {
            display: flex;
            min-height: 78px;
            padding: 1rem;
            border: 1px solid var(--color-grayBlue);
            border-radius: 5px;
            max-width: 360px;
            width: 100%;
            margin: 8px;
            transition: border-color ease 0.1s;
            cursor: pointer;
            box-shadow: 0 1px 2px 0 #0000000D;

            @media (max-width: 767px) {
                max-width: none;
                width: 100%;
            }
            @media (min-width: 768px) and (max-width: 1025px) {
                max-width: none;
                width: calc(50% - 16px);
            }
            &:hover {
                border-color: var(--color-blueHover);
                transition: border-color ease 0.1s;
            }
            & > div {
                flex-grow: 1;
            }
            .fileIcon {
                margin-right: rem(16px);
                flex-shrink: 0;
            }
            .name {
                display: flex;
                flex-basis: 100%;
                justify-content: space-between;
                margin-bottom: rem(4px);

                .text {
                    color: #262626;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: normal;
                    word-break: break-word;
                }
                .buttons {
                    display: flex;
                    margin-left: auto;

                    img + img {
                        margin-left: 1rem;
                    }
                }
            }
            .size {
                color: #262626;
                font-size: 13px;
                font-weight: 400;
                line-height: normal;
                opacity: 0.5;
            }
            .icon {
                margin-left: rem(16px);
                cursor: pointer;
            }
        }
        .fileDisabled {
            pointer-events: none;
        }
    }
}
.modal {
    position: relative;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 568px;
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
.modalOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(#275677, 0.65);
    z-index: 10001;

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
}
