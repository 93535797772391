@import "src/mixins/index.scss";

.modal {
    display: flex;
    flex-direction: column;
    width: 572px;

    .head {
        display: flex;
        justify-content: space-between;
        height: 56px;
        padding: 24px 24px 0 24px;
        align-items: center;

        .text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            align-self: flex-end;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 32px;
        max-height: 600px;
        overflow: auto;

        .hint {
            margin-bottom: 16px;
        }
    }

    .items {
        display: flex;
        flex-direction: column;

        .item {
            &:last-child {
                margin-bottom: 0;
            }

            h3 {
                margin-left: 0;
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
                text-align: left;
            }

            .users {
                .user {
                    margin-bottom: 16px;
                    background: #FFFFFF;
                    //border-radius: 5px;

                    &:last-child {
                        margin-bottom: 24px;
                    }

                    .head {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 16px 0;
                        padding: 0;
                        height: auto;

                        .avatar {
                            margin-right: 8px;
                            width: 32px;
                            height: 32px;
                            background: #ABC9D7;
                            background-size: cover;
                            border-radius: 32px;
                        }

                        a {
                            color: #1280CE;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                        }
                    }

                    .records {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        padding-left: 40px;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 15.5px;
                            height: 100%;
                            width: 1px;
                            background: #EAEDF3;
                        }

                        .record {
                            display: flex;
                            align-items: center;
                            margin-bottom: 24px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            span {
                                margin-right: 16px;
                                color: #262626;
                                font-size: 14px;
                                line-height: 21px;
                                opacity: 0.5;
                            }

                            p {
                                color: #262626;
                                font-size: 14px;
                                line-height: 21px;
                                word-break: break-all;
                            }

                            a {
                                color: #1280CE;
                                font-size: 14px;
                                line-height: 21px;
                                word-break: break-all;
                            }
                        }
                    }

                    .body {
                        display: flex;
                        padding-top: 20px;

                        span {
                            @include text-small;
                            color: #9F9F9F;
                        }

                        .separator {
                            margin: -20px 12px 0 10px;
                            width: 2px;
                            height: 50px;
                            background: #E7E7E7;

                            &:before {
                                content: "";
                                display: block;
                                margin-top: 23px;
                                margin-left: -3px;
                                width: 8px;
                                height: 8px;
                                background: #EDF1F5;
                                border-radius: 8px;
                            }
                        }

                        p {
                            @include text-small;
                            color: #9F9F9F;

                            a {
                                color: #279BD9;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .loader {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .footer {
        border-top: 1px solid #EAEDF3;
        display: flex;
        flex-direction: row-reverse;
        padding: 16px 24px;

        div + div {
            margin-right: 16px;
        }
    }

    &.mobile {
        width: auto;

        .head {
            height: auto;
        }
        .content {
            padding: rem(24px);
        }
        .footer {
            flex-direction: column-reverse;
            > * {
                width: 100%;
                &:first-child {
                    margin-right: 0;
                    margin-top: rem(16px);
                }
            }
        }
    }
}
