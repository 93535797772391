@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.search {
    display: flex;
    height: 64px;
    background-color: #fff;

    .inputBlock {
        flex-basis: 42%;
        flex-shrink: 1;
        flex-grow: 1;
        align-self: center;
        margin-left: rem(24px);

        .input {
            @include input;

            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .searchInputContainer {
                display: flex;
                align-items: center;
                width: 100%;

                input {
                    border: none;
                    width: 100%;
                }
                i {
                    margin-right: 8px;
                }
            }
            .searchInputInfo {
                display: flex;
                align-items: center;
                border-left: 1px solid #E8E8E8;

                span {
                    white-space: nowrap;
                }
                .iconContainer {
                    display: flex;

                    i {
                        cursor: pointer;
                    }
                    i:first-child {
                        margin-right: 18px;
                    }
                }
                span {
                    color: #262626;
                    opacity: 0.65;
                    padding: 0 16px;
                }
                .cancelContainer {
                    display: flex;
                    border-left: 1px solid #E8E8E8;
                    padding: 0 16px;
                    margin-left: 16px;

                    i {
                        cursor: pointer;
                    }
                }
            }
            > img {
                margin-right: rem(8px);
            }
            > input {
                font-size: rem(14px);
                line-height: rem(19px);
                border: 0;
                flex-grow: 1;
            }
            &:focus-within {
                border: 2px solid var(--color-blue);
                padding: rem(9px) 0 rem(9px) rem(12px);
            }
        }
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-basis: 50%;
        flex-shrink: 2;
        flex-grow: 1;
        align-self: center;
        padding-right: rem(18px);

        .toolbarItem, .dropdown {
            margin-right: rem(18px);
        }
    }
    &.mobile {
        height: auto;
        padding: rem(16px);
        flex-direction: column-reverse;

        .buttons {
            width: 100%;
            justify-content: flex-start;
            padding-right: 0;

            .toolbarItem, .dropdown {
                margin-left: rem(9px);
                margin-right: rem(9px);
            }
        }
        .inputBlock {
            width: 100%;
            margin-left: 0;
            margin-top: rem(16px);
        }
    }
}
