@import "src/mixins/index.scss";

.card {
    flex-grow: 1;
    padding-bottom: 0;

    .blockText {
        font-weight: 600;
        font-size: rem(14px);
        line-height: 150%;
        color: #262626;
        margin-bottom: rem(16px);
    }

    .found {
        .foundName {
            font-size: 14px;
            line-height: 150%;
            opacity: 0.5;
            margin-bottom: rem(8px);
        }

        .foundValue {
            font-size: 14px;
            line-height: 150%;
        }
    }

    .user {
        .userTitle {
            display: flex;
            justify-content: space-between;
            h4 {
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #262626;
            }
            .deleteBtn {
                display: flex;
                align-items: center;
                color: #1280CE;
                cursor: pointer;
                font-weight: 500;
                font-size: rem(13px);
                i {
                    margin-right: 7.5px;
                    path {
                        fill: var(--color-blue);
                    }
                }
            }
        }

        .inputsContainer {
            margin-top: rem(24px);
            display: flex;
            flex-wrap: wrap;
            column-gap: rem(16px);
            row-gap: rem(24px);

            > * {
                flex: 1 1;
                flex-basis: 25%;
            }

            .testSelect {
                * {
                    color: black;
                }
            }
        }

        .error {
            margin-top: rem(16px);
            font-size: rem(13px);
            line-height: 150%;
            color: #FF6973;
            .existingUserLink {
                font-weight: 500;
                font-size: rem(13px);
                line-height: 150%;
                color: var(--color-blue);
            }
        }

        .warning {
            margin-top: rem(16px);
            font-size: rem(13px);
            line-height: 150%;
            color: #262626;
            opacity: 0.5;
        }
    }

    .controlsContainer {
        display: flex;
        margin: rem(16px) rem(-24px) 0 rem(-24px);
        gap: rem(16px);
        padding: rem(16px) rem(24px);
        border: 1px solid var(--color-grayBlue);
    }
}
