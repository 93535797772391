.text {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.textError textarea {
    border: 1px solid #FF6973;
  }
  label {
    margin-bottom: 4px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px;
    line-height: 18px;

    &.error {
      color: #FF6973;
    }
  }
  textarea {
    padding: 10px 12px;
    height: 120px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    color: #262626;
    font-size: 13px;
    line-height: 150%;
  }
  span {
    margin-top: 4px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px;
    line-height: 18px;

    &.error {
      color: #FF6973;
    }
  }
}