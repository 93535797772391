@import "src/mixins/index.scss";

.loginPage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;

  .loginPageForm {
    margin-top: 80px;
    padding: rem(40px) rem(32px);
    max-width: 500px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.35);
    z-index: 1;

    .loginPageFormHeader {
      display: flex;
      align-items: center;

      img {
        margin-right: rem(16px);
        width: 48px;
        height: 48px;
      }
      div {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: rem(4px);
          color: #262626;
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
        }
        p {
          color: #262626;
          font-size: 13px;
          font-weight: 400;
          line-height: 150%;
          opacity: 0.5;
        }
      }
    }
    .loginPageFormHeaderError {
      margin-top: rem(24px);
      padding: rem(16px);
      background: rgba(244, 86, 51, 0.9);
      border-radius: 5px;
      color: #FFFFFF;
      font-size: 13px;
      font-weight: 400;
      line-height: 150%;
    }
    .loginPageFormFields {
      margin-top: rem(24px);

      .loginPageFormFieldsInput {
        margin-bottom: rem(24px);

        &:nth-child(2) {
          margin-bottom: rem(40px);
        }
      }
      .loginPageFormFieldsButton {
        margin-top: 0 !important;

        button {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .loginPage {
    .loginPageForm {
      padding: rem(40px) rem(12px);

      .loginPageFormHeader {
        display: flex;
        flex-direction: column;

        img {
          margin-right: 0;
        }
        div {
          h1 {
            text-align: center;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}