@import "src/mixins/index.scss";

.label {
  color: var(--color-textBlack);
  opacity: 0.5;
  margin-bottom: rem(4px);
}

.error {
    border: 1px #FF6973FF solid;
    border-radius: 5px;
}

.errorText {
    color: var(--color-red);
    font-size: rem(12px);
    line-height: rem(16px);
    margin-top: 4px;
}
