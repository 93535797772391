.lockTree {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 1rem;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;

    input {
      width: 100%;
      border: 0;
      color: rgba(38, 38, 38, 0.5);
      font-size: 14px;
      line-height: 19px;
    }
  }

  .sizerContainer {
    flex-grow: 1;

    & > div > div > div > div > div > div {
      background: transparent !important;
      border-left: none !important;
    }
  }
}