@import "src/mixins/index.scss";

.node, .opened {
    font-weight: 500;
}

.node {
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;

    &.wordBreak {
        white-space: pre-wrap;
    }
}

.unread {
    width: 6px;
    height: 6px;
    background-color: #FF6973;
    margin-right: 0.5rem;
    border-radius: 100%;
}

.node.opened {
    color: #1280CE;
}
