@import 'src/mixins/index.scss';

.pageWrapper {
    display: flex;

    &.mobile {
        .contentWrapper {
            margin-left: 0;
        }
    }
}

.tree {
    position: fixed;
    width: 100%;
    max-width: rem(280px);
    height: calc(100vh - 58px);
    background-color: #fff;
    padding-right: 4px;

    &.withNewsTicker {
        height: calc(100vh - 85px);
    }
}

.contentWrapper {
    width: 100%;
    height: calc(100svh - 57px);
    margin-left: 280px;
    border-left: 1px solid #EAEDF3;
    overflow: hidden;

    &.withNewsTicker {
        height: calc(100vh - 85px);
    }

    & > div {
        height: 100%;
    }
}
