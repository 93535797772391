@import "src/mixins/index.scss";

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);

  h1 {
    margin-bottom: 24px;
    color: #262626;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }

  .integrationsList {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .integrationsListItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;

      &:first-child:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
        background: #EAEDF3;
      }

      .integrationsListItemContainer {
        display: flex;
        align-items: center;
        margin-right: 75px;
        width: 100%;

        img {
          margin-right: 24px;
          width: 100%;
          max-width: 160px;
        }

        .integrationsListItemContent {
          display: flex;
          flex-direction: column;
          width: 100%;

          span {
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
          }

          .integrationsListItemLoader {
            position: relative;
            margin-top: 16px;
            width: 100%;
            height: 24px;
            background: #EAEDF3;
            border-radius: 6px;

            span {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              background: repeating-linear-gradient(45deg, #298CD3, #298CD3 24px, #1280CE 0, #1280CE 40px);
              border-radius: 6px;
              transition: all 1s ease-in-out;
            }
          }

          p {
            margin-top: 8px;
            color: #000000;
            font-size: 14px;
            line-height: 19px;
            opacity: 0.5;
          }
        }
      }

      .integrationsListItemButtons {
        display: flex;
        align-items: center;

        button:last-child {
          margin-left: 8px;
        }

        button div {
          display: flex;
          align-items: center;

          i {
            margin-right: 8px;
            padding: 3px;
            background: #FFFFFF;
            border-radius: 100px;

            svg path {
              fill: #50b678;
            }
          }
        }
      }
    }
  }

  &.mobile {
    .integrationsListItem {
      flex-direction: column;
      .integrationsListItemContainer {
        margin-right: 0;
        margin-bottom: rem(16px);
        .integrationsListItemContent {
          align-items: flex-end;
        }
      }
      .integrationsListItemButtons {
        align-self: flex-end;
      }
    }
  }
}