@import "src/mixins/index.scss";

.content {
  .field {
    margin-bottom: rem(8px);
  }

  .avatarContainer {
    display: flex;
    margin-bottom: rem(24px);

    .avatarImage {
      width: rem(120px);
      height: rem(120px);
      background-color: #EAEDF3;
      border-radius: rem(60px);
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .avatarPanel {
      display: flex;
      align-items: center;
      padding: rem(12px) 0;

      img {
        width: rem(120px);
        height: rem(120px);
        border-radius: 50%;
      }

      .infoContainer {
        display: flex;
        margin-left: rem(16px);
        position: relative;
        cursor: pointer;

        input[type=file] {
          display: none;
        }

        & > div {
          &:first-child button {
            margin-right: rem(16px);
          }

          &:nth-child(2) button i svg path {
            fill: #FF6973;
          }
        }
      }
    }
  }

  .titleBlock {
    font-weight: 600;
    font-size: rem(14px);
    line-height: 150%;
    margin-bottom: 1rem;
  }

  .divider {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 0;
  }

  .tool {
    display: flex;
    padding-top: 1rem;

    button {
      margin-right: 1rem;
    }
  }

  &.mobile {
    .avatarPanel {
      align-items: flex-start;
      .infoContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: rem(32px);

        > *:not(input) {
          width: 166px;

          button {
            width: 100%;
          }
        }
      }
    }

    .tool {
      flex-direction: column-reverse;
      & > div:first-child {
        margin-top: rem(16px);
      }
    }
  }
}