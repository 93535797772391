@import "src/mixins/index.scss";

.header {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 17px;
  padding-left: 24px;
  border-bottom: 1px solid #EAEDF3;

  h1 {
    padding-left: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }

  .backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.contentWrapper {
  padding: 24px;
}


.avatarContainer {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  img {
    width: rem(120px);
    height: rem(120px);
    border-radius: 50%;
  }

  .infoContainer {
    margin-left: rem(16px);
    position: relative;
    cursor: pointer;

    input[type=file] {
      cursor: pointer;
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;

    }

    p {
      color: var(--color-textBlack);
      font-size: 13px;
      line-height: 150%;
      opacity: 0.5;
    }

    .btnContainer {
      display: flex;
      padding-top: 8px;

      button {
        display: inline-flex;
        align-items: flex-end;
      }

      & > div {

        &:first-of-type {
          margin-right: 16px;

          svg path {
            fill: #1280CE;
          }
        }

        &:last-of-type {
          svg path {
            fill: #d10000;
          }
        }

        i {
          margin-right: 8px;
        }
      }
    }

  }
}

.formContainer {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .formBody {
    padding: 24px;

    h2 {
      padding-bottom: 16px;
    }

    p {
      font-size: 13px;
      color: #9F9F9F;
    }

    .bottomDivider {
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid #EAEDF3;
    }

    .rulesTable {
      width: 556px;

      tr {
        height: 40px;
        line-height: 40px;

        &:nth-of-type(even) {
          background-color: #FAFAFA;
        }

        th, td {
          &:not(:first-of-type) {
            text-align: center;
          }
        }

        td label {
          display: initial;
        }
      }
    }

    .mapWrapper {
      width: 422px;
      height: 355px;
      background-color: #9F9F9F;
      border-radius: 5px;
    }
  }

  .formFooter {
    display: flex;
    border-top: 1px solid #EAEDF3;
    padding: 16px 24px;

    & > div:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}