.archiveGlobal {
    position: sticky;
    top: 0;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;
    z-index: 10;

    ul {
        display: flex;

        #scriptsTab {
            @media (max-width: 1024px) {
                display: none;
            }
        }

        li {
            position: relative;
            padding: 13px 24px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 12px;
            opacity: 0.5;
            cursor: pointer;

            &:hover, &.active {
                color: #1280CE;
                opacity: 1;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #1280CE;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }
}
