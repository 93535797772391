@import "src/mixins/index.scss";

.player {
  display: flex;
  width: 100%;
  padding: rem(16px);
  border: 1px solid #EAEDF3;
  background-color: #fff;
  align-self: center;
  border-radius: 5px;

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .controlsBtn {
      display: flex;
      align-items: center;
    }

    .playButton, .replayButton {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: rem(40px);
      height: rem(40px);
      padding: 0;
      border: none;
      margin: 0;
      cursor: pointer;
      text-align: center;
      line-height: 100%;
      font-family: 'NotoSans', sans-serif;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .playButton {
      background-color: #1280CE;
      border-radius: 9999px;

      svg {
        fill: #fff;
      }
    }

    .replayButton {
      background-color: transparent;

      svg {
        fill: #1280CE;
      }
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    max-width: 487px;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .volume {
    user-select: none;
    width: 100%;
    max-width: 82px;
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .barProgress {
    flex: 1;
    border-radius: rem(6px);
    height: rem(4px);
    display: flex;
    align-items: center;
    cursor: pointer;

    .barProgressKnob {
      position: relative;
      height: 12px;
      width: 12px;
      border: 1px solid #EAEDF3;
      border-radius: 9999px;
      background-color: white;
      box-shadow: 0 1px 2px rgba(#000, 0.05);
    }
  }

  .durationValue {
    margin-left: rem(8px);
    margin-right: rem(8px);
  }

  .volumeValue {
    margin-left: rem(8px);
  }

  .muteIcon {
    cursor: pointer;
  }
}