@import "src/mixins/index.scss";

.loader {
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
}
.title {
    @include header-large;
    width: 100%;
    padding-bottom: rem(24px);
}
.tableWrapper {
    padding: rem(24px);
    overflow-x: hidden;
    position: relative;
    flex-grow: 1;
    @media (max-width: 768px) {
        padding-left: rem(16px) !important;
        padding-right: rem(16px) !important;
    }
    .loaderContainer > * {
        background-color: transparent;
    }
}
.table {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .filter {
        padding: rem(24px);
        border-bottom: 1px solid #EAEDF3;
    }
    .pagination {
        padding: 16px 24px;
        background: #FFFFFF;
        border-top: 1px solid #EAEDF3;
        border-radius: 0 0 5px 5px;
    }
}
.nameCell {
    width: 100%;
}
.nameCell, .fileWrapper {
    display: flex;
    align-items: flex-start;

    .iconWrapper {
        padding-right: rem(12px);
    }
    .descWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }
    .changeFile {
        display: flex;
        align-items: center;
        color: #1280CE;

        i {
            margin-right: 6px;

            svg {
                fill: #1280CE;
            }
        }
    }
    .fileName {
        padding-bottom: rem(8px);
    }
    .fileDescription {
        color: rgba(#262626, 0.5);

        .fileType {
            text-transform: uppercase;
        }
    }
}
.fileWrapper {
    padding-bottom: rem(24px);
}
.emptyId {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.toolbar {
    display: flex;
    justify-content: space-between;
}
.modal {
    position: relative;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 568px;
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .modalHeader {
        display: flex;
        justify-content: space-between;
        padding-top: rem(24px);
        padding-left: rem(24px);
        padding-right: rem(24px);

        h3 {
            font-size: rem(22px);
            font-weight: 500;
        }
        .closeIcon {
            cursor: pointer;
        }
    }
    .modalBody {
        display: flex;
        flex-wrap: wrap;
        padding: rem(32px);

        img, video {
            width: 100%;
        }

    }
    .modalFooter {
        display: flex;
        justify-content: flex-end;
        padding: rem(16px) rem(24px);
        border-top: 1px solid #EAEDF3;

        > *:not(:last-child) {
            margin-right: rem(16px);
        }
    }
}
.modalOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(#275677, 0.65);
    z-index: 20;

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
}
.inputWrapper {
    width: 100%;
    padding-bottom: rem(24px);
}
.iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: rem(10px) rem(16px);
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-weight: 500;

    svg {
        margin-right: rem(6px);

        * {
            fill: #1280CE;
        }
    }
}
.downloadButton {
    background-color: #1280CE;
    color: #fff;

    svg * {
        fill: #fff;
    }
}
