@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.modal {
  display: flex;
  flex-direction: column;
  width: 572px;

  .head {
    display: flex;
    justify-content: space-between;
    height: 56px;
    padding: 24px 24px 0 24px;
    align-items: center;

    .text {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      align-self: flex-end;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .hint {
      margin-bottom: 16px;
    }
  }

  .footer {
    border-top: 1px solid #EAEDF3;
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 24px;

    div + div {
      margin-right: 16px;
    }
  }

  &.mobile {
    width: auto;
    .head {
      height: auto;
    }
    .content {
      padding: rem(24px);
    }
    .footer {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        &:first-child {
          margin-right: 0;
          margin-top: rem(16px);
        }
      }
    }
  }
}

.fieldRow {
  margin-bottom: 1rem;
}

.inputWrapper {
  position: relative;

  &.withDesc {
    padding-bottom: rem(4px);
  }
}

.description {
  color: rgba(#262626, 0.5);
}

.label {
  @include inputLabel;
}