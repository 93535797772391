.indicator {
    height: 28px;
    padding: 7px 6px;
    border-radius: 100px;
    background: #F5F5F5;;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .text {
        white-space: nowrap;
        display: inline;
        margin-right: 6px;
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        color: #262626;
    }
    .cross {
        display: inline;
    }
}
