.trainingAll {
  .trainingAllContainer {
    padding: 24px;

    h1 {
      margin-bottom: 24px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
    .trainingAllFilter {
      margin-bottom: 24px;
    }
    .trainingAllItems {
      display: flex;
      flex-direction: column;

      .trainingAllItem {
        margin-bottom: 24px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        .trainingAllItemTop {
          display: flex;
          align-items: flex-start;
          padding: 24px;

          img {
            display: block;
            margin-right: 16px;
            min-width: 240px;
            height: 130px;
            border-radius: 5px;
            object-fit: cover;
          }
          .trainingAllItemContent {
            display: flex;
            flex-direction: column;
            width: 100%;

            h3 {
              margin-bottom: 8px;
              color: #262626;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              cursor: pointer;
            }
            p {
              color: #000000;
              font-size: 14px;
              line-height: 21px;
              opacity: 0.8;
            }
          }
        }
        .trainingAllItemBottom {
          display: flex;
          margin-bottom: 24px;
          padding: 0 24px;

          li {
            display: flex;
            align-items: center;
            margin-right: 32px;

            img {
              margin-right: 8px;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              object-fit: cover;
            }
            div {
              display: flex;
              flex-direction: column;

              span {
                color: #262626;
                font-size: 13px;
                line-height: 19px;
                opacity: 0.5;
              }
              p {
                color: #262626;
                font-size: 13px;
                line-height: 19px;
              }
              a {
                color: #1280CE;
                font-size: 13px;
                font-weight: 500;
                line-height: 150%;
              }
            }
          }
        }
        .trainingAllItemMenu {
          display: flex;
          align-items: center;
          padding: 18px 24px;
          border-top: 1px solid #EAEDF3;

          & > div:last-child svg path {
            fill: #FF6973;
          }
          i {
            margin-right: 16px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingAll {
    .trainingAllContainer {
      padding: 12px;

      .trainingAllItems {
        .trainingAllItem {
          .trainingAllItemTop {
            padding: 12px;
          }
          .trainingAllItemBottom {
            flex-direction: column;
            padding: 0 12px;

            li {
              img {
                display: none;
              }
              div {
                flex-direction: row;

                span {
                  margin-right: 4px;
                }
              }
            }
          }
          .trainingAllItemMenu {
            padding: 12px;
          }
        }
      }
    }
  }
}