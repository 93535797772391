@import "src/mixins/index.scss";

.content {
  @include platform-spacing(padding);
  padding-top: rem(24px);
  height: 100%;

  .pageTitle {
    @include header-large;
    margin: 0 0 1rem 0;
  }

  .emptyCard {
    height: 100%;

    .empty {
      margin-top: 0;
    }
  }
}