.trainingQuestionPreviewModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(39, 86, 119, 0.65);
  z-index: 10;
}
.trainingQuestionPreviewModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 950px;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .trainingQuestionPreviewModalHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;

    .trainingQuestionPreviewModalHeaderButtons {
      display: flex;

      div {
        margin-right: 16px;

        &:first-child svg path {
          fill: #FF6973;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .trainingQuestionPreviewModalBody {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;

    img {
      margin-bottom: 24px;
      width: 360px;
      height: 200px;
      border-radius: 5px;
      object-fit: cover;
    }
    h3 {
      margin-bottom: 8px;
      color: #262626;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .trainingQuestionPreviewModalBodyMark {
      margin-bottom: 24px;
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      opacity: 0.5;
    }
    .trainingQuestionPreviewModalBodySeparator {
      margin-bottom: 24px;
      width: 100%;
      height: 1px;
      background: #EAEDF3;
    }
    .trainingQuestionPreviewModalBodyMany,
    .trainingQuestionPreviewModalBodyOne,
    .trainingQuestionPreviewModalBodyNext,
    .trainingQuestionPreviewModalBodyMatch,
    .trainingQuestionPreviewModalBodySort {
      p {
        margin-bottom: 24px;
        color: #262626;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        opacity: 0.5;
      }
      ul {
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
          label {
            color: #262626;
            font-size: 14px;
            line-height: 21px;
          }
          span {
            margin-top: 4px;
            color: #262626;
            font-size: 13px;
            line-height: 19px;
            opacity: 0.5;
          }
        }
      }
    }
    .trainingQuestionPreviewModalBodyOpen {
      display: flex;
      align-items: center;
      padding: 16px;
      width: 100%;
      max-width: 380px;
      background: #FBFBFD;
      border-radius: 5px;

      i {
        margin-right: 8px;
      }
      span {
        color: #262626;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingQuestionPreviewModal {
    .trainingQuestionPreviewModalHeader {
      flex-direction: column;
      align-items: center;
      padding: 12px;

      .trainingQuestionPreviewModalHeaderButtons {
        flex-direction: column;
        align-items: center;

        div {
          margin-top: 12px;
          margin-right: 0;
        }
      }
    }
    .trainingQuestionPreviewModalBody {
      padding: 12px;
    }
  }
}