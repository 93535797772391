
.modalFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.4);
    z-index: 1;

    button {
        border: 1px solid #fff;
        border-radius: 9999px;
        padding: 5px;

        i {
            margin-bottom: 0;
        }

        &.disabled {
            border: 1px solid #7B8F9C;
            cursor: not-allowed;
        }
    }

    .sizeValue {
        color: #fff;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.noStyleButton {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 12px;
    padding-right: 12px;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border: 0;
    white-space: nowrap;

    i {
        margin-bottom: 6px;
    }

    &.red {
        div {
            color: var(--color-alert)
        }
    }

    div {
        width: 100%;
    }
}
