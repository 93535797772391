@import "src/mixins/index.scss";

.news {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  padding: 10px;
  min-height: calc(100vh - 114px);
  border: 1px solid #EDF1F5;
  border-radius: 5px;

  .container {
    .status {
      margin-bottom: 20px;
      padding: 4px 10px;
      width: 100%;
      max-width: 140px;
      border-radius: 100px;
      color: #000000;
      font-size: 12px;
      line-height: 16px;
      text-align: center;

      &.gray {
        background: #D4D4D4;
      }

      &.yellow {
        background: #FFE05A;
      }

      &.red {
        background: #E06169;
      }
    }

    h1 {
      @include header-large;
      margin-bottom: 10px;
    }

    .creation {
      @include text-smallest;
      display: flex;
      margin-bottom: 10px;

      p {
        margin-right: 5px;
        color: #616060;
      }

      span {
        color: #9F9F9F;
      }
    }
  }

  .comment {
    display: flex;
    align-items: center;
    padding: 15px 12px;
    border: 1px solid #EDF1F5;
    border-radius: 5px;

    .avatar {
      margin-right: 5px;
      width: 38px;
      height: 38px;
      border-radius: 38px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .input {
      input {
        background: transparent !important;
        box-shadow: none !important;
        border: none;
      }
    }
  }
}