@import 'src/mixins/index.scss';
@import 'src/uikit/_styles/common-styles';

.base {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;

    &.error {
        .inputWrapper {
            .icon svg path {
                fill: #ff6973;
            }
            input,
            input:focus {
                border-color: #ff6973;
            }
        }
        .errorMessage {
            margin-top: 4px;
            color: #ff6973;
            font-size: 13px;
            line-height: 18px;
        }
    }
    .label {
        margin-bottom: 4px;
        color: rgba(38, 38, 38, 0.75);
        font-size: 13px;
        line-height: 18px;

        &.required::after {
            content: '*';
            display: inline-block;
            margin-left: 4px;
            color: #ff6973;
        }
    }
    .inputWrapper {
        position: relative;

        .icon {
            position: absolute;
            top: 13px;
            left: 12px;
        }
        input {
            padding: 0 12px;
            width: 100%;
            height: 40px;
            background: #ffffff;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            color: #262626;
            font-size: 14px;
            line-height: 19px;
            outline: none;

            &.withIcon {
                padding-left: 32px;
            }
            &::placeholder {
                color: #262626;
                opacity: 0.5;
            }
            &::-webkit-input-placeholder {
                color: #262626;
                opacity: 0.5;
            }
            &:-moz-placeholder {
                color: #262626;
                opacity: 0.5;
            }
            &:-ms-input-placeholder {
                color: #262626;
                opacity: 0.5;
            }
            &:hover {
                border: 1px solid #1280ce;
            }
            &:focus {
                border: 2px solid #1280ce;
            }
            &:disabled {
                background: #fbfbfd;
                border: 1px solid #e8e8e8;
            }
        }
        input[type='date']::-webkit-inner-spin-button {
            display: none;
        }
    }

    .maxLength {
        display: flex;
        justify-content: flex-end;
        color: rgba(38, 38, 38, 0.5);
        font-size: 13px;

        .lengthError {
            color: #ff6973;
        }
    }

    .inputInfo {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .left {
        padding-right: 8px;
      }
    }
    
    .description {
        margin-top: 4px;
        color: rgba(38, 38, 38, 0.5);
        font-size: 13px;
        line-height: 18px;
    }
}
