@import "src/mixins/index";

.tag {
    color: var(--color-primary-blue);
    padding: 0.25rem 1rem;
    border: 1px solid var(--color-primary-blue);
    border-radius: 0.5rem;
    display: inline-block;

    &:hover {
        color: var(--color-primary-outline);
        border-color: var(--color-primary-outline);
    }
}

.selected {
    color: var(--color-white);
    background-color: var(--color-primary-blue);

    &:hover {
        color: var(--color-white);
        background-color: var(--color-primary-outline);
    }
}

.selectable {
    &:hover {
        cursor: pointer;
    }
}
