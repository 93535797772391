@import "src/mixins/index.scss";

.content {
    padding: 0;
    width: 100%;

    & > div {
        & > span + div {
            border: 1px solid #1280CE !important;
        }

        & > div {
            height: auto;
            box-shadow: none !important;
        }
    }
}

.label {
    font-size: 13px;
    line-height: 18px;
    color: rgba(38, 38, 38, 0.75);
    padding-bottom: rem(4px);

    &.required::after {
        content: '\00a0*';
        display: inline-block;
        color: var(--color-red);
    }
}

.error-message {
    display: block;
    color: var(--color-alert);
    font-size: rem(12px);
    line-height: rem(16px);
    margin-top: rem(4px);
}
