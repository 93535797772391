@import "src/mixins/index.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 99999;

  .content {
    display: flex;
    flex-direction: column;
    padding-top: rem(26px);
    overflow: hidden;
    height: 650px;
    width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: rem(30px);
      font-size: 22px;
      padding-left: rem(32px);
      padding-right: rem(32px);
    }

    .stretched {
      flex-basis: 100%;
    }

    .buttons {
      display: flex;
      padding: rem(16px);
      align-self: flex-end;

      div + div {
        margin-left: rem(16px);
      }
    }

    &.mobile {
      width: 100%;
      height: calc(100% - 100px);
      margin: rem(50px) rem(16px);

      .title {
        padding-left: rem(24px);
        padding-right: rem(24px);
        div {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #262626;
        }
      }

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: rem(24px);

        > * {
          margin: 0;

          &:not(:last-child) {
            margin-top: rem(16px);
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }

  .line {
    background: #EAEDF3;
    height: 1px;
    margin-top: rem(16px);
  }
}
