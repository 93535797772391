@import "src/mixins/index.scss";

.container {
  position: relative;
  height: 20px;

  > div > button {
    &:hover svg {
      circle {
        fill: #1280CE;
      }

      path {
        fill: white;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    //width: 266px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: absolute;
    top: 2rem;
    //left: -249px;
    z-index: 101;
    //overflow: hidden;
    min-width: 200px;
    width: auto;

    &.left {
      left: -175px;
    }

    &.right {
      left: 0;
      //width: 266px;
    }

    div {
      margin-right: 0;
    }
  }

  &.show {
    > div > button svg {
      circle {
        fill: #1280CE;
      }

      path {
        fill: white;
      }
    }
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.65);
  display: block;
  overflow: auto;
}

.modal {
  position: absolute;
  bottom: 0;
  margin: rem(16px);
  width: calc(100% - 32px);
  background-color: var(--color-white);
  border: 1px solid transparent;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .cancelBtn {
    width: 100%;
    border-top: 1px solid var(--color-grayBlue);
    padding: rem(10px) rem(12px);
  }
}

