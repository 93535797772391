.chatInfoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;
}

.chatInfo {
  position: relative;
  width: 100%;
  max-width: 570px;
  height: calc(100vh - 160px);
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow: auto;

  .chatInfoHeader {
    display: flex;
    flex-direction: column;
    width: 100%;

    .chatInfoHeaderTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 24px 32px 24px;

      h3 {
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .chatInfoHeaderInfo {
      display: flex;
      align-items: center;
      padding: 0 32px 24px 32px;
      border-bottom: 1px solid #EAEDF3;

      .chatInfoHeaderInfoAvatar {
        margin-right: 24px;
        width: 96px;
        height: 96px;
        background-color: #EAEDF3;
        background-size: contain;
        border-radius: 100px;

        img {
          width: 96px;
          height: 96px;
          border-radius: 100px;
        }
      }

      .chatInfoHeaderInfoData {
        display: flex;
        flex-direction: column;

        h4 {
          margin-bottom: 4px;
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        p {
          color: #000000;
          font-size: 13px;
          line-height: 19px;
          opacity: 0.5;
        }
      }
    }

    .chatInfoHeaderMenu {
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      border-bottom: 1px solid #EAEDF3;

      li {
        display: flex;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #EAEDF3;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        i {
          margin-right: 8px;

          svg path {
            fill: #1280CE;
          }
        }

        &.chatInfoHeaderMenuRed {
          color: #FF6973;

          svg path {
            fill: #FF6973;
          }
        }

        color: #1280CE;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .chatInfoBody {
    h4 {
      margin: 16px 0;
      padding: 0 32px;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #262626;
    }

    .chatInfoBodyItems {
      display: flex;
      flex-direction: column;

      .chatInfoBodyGroup {
        .chatInfoBodyItem {
          &:hover {
            i svg path {
              fill: #7B8F9C;
            }
          }
        }
        .chatInfoBodyGroupItems {
          .chatInfoBodyItem {
            padding-left: 64px;
          }
        }
      }
      .chatInfoBodyItem {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 32px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background: rgba(18, 128, 206, 0.05);

          i svg path {
            fill: #1280CE;
          }
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 32px;
          width: calc(100% - 64px);
          height: 1px;
          background: #EAEDF3;
        }

        i svg path {
          transition: all 0.3s ease-in-out;
        }

        .chatInfoBodyItemContainer {
          display: flex;
          align-items: center;

          .chatInfoBodyItemContainerAvatar {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            width: 48px;
            height: 48px;
            background: #EAEDF3;
            border-radius: 48px;

            img {
              width: 100%;
              border-radius: 48px;
              object-fit: cover;
            }
            .crown {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }

          .chatInfoBodyItemContainerData {
            display: flex;
            flex-direction: column;

            p {
              color: #262626;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }

            span {
              color: #262626;
              font-size: 13px;
              line-height: 19px;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
