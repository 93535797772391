.trainingTest {
  .trainingTestCreate {
    padding: 24px;

    h1 {
      margin-bottom: 24px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
    input[type=file] {
      display: none;
    }
    .trainingTestAvatar {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      height: 150px;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      cursor: pointer;

      i {
        margin-bottom: 8px;
      }
      h3 {
        margin-bottom: 2px;
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
      }
      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;
      }
      img {
        display: block;
        width: 100%;
        height: 150px;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        object-fit: cover;
      }
      .trainingTestAvatarOverlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 16px;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.65) 0%, rgba(0, 0, 0, 0.65) 0.01%, rgba(0, 0, 0, 0) 100%);
        border-radius: 5px;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));

        button {
          margin-right: 16px;
          height: 32px;
          background: rgba(255, 255, 255, 0.2);
          border: none;
          border-radius: 5px;
          color: #FFFFFF;
          font-size: 13px;
          font-weight: 500;
          line-height: 19px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .trainingTestForm {
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      .trainingTestFormFields {
        padding: 24px;

        .trainingTestFormField {
          margin-bottom: 24px;
        }
        .trainingTestFormDelimiter {
          display: inline-block;
          margin: 8px 0;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        .trainingTestFormSettings {
          margin: 24px 0;

          .trainingTestFormSettingsLabel {
            display: flex;
            align-items: center;
            color: #262626;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            cursor: pointer;

            .trainingTestFormSettingsLabelActive {
              color: #1280CE;
            }
            span {
              margin-left: 16px;
            }
          }
          .trainingTestFormSettingsContent {
            margin-top: 16px;

            .trainingTestFormSettingsContentItem {
              display: flex;
              flex-direction: column;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
              label {
                margin-bottom: 0;
              }
              span {
                margin-top: 8px;
                color: #000000;
                font-size: 14px;
                line-height: 19px;
                opacity: 0.5;
              }
              .trainingTestFormSettingsContentItemInput {
                display: flex;
                align-items: center;
                margin-top: 16px;
                margin-left: 16px;

                div {
                  margin-right: 16px;
                  width: 100%;
                  max-width: 160px;
                }
                span {
                  margin-top: 0;
                  color: #000000;
                  font-size: 14px;
                  line-height: 19px;
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .trainingTestFormGrading {
          display: flex;
          flex-direction: column;
          margin: 24px 0;

          .trainingTestFormGradingLabel {
            display: flex;
            align-items: center;
            color: #262626;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            cursor: pointer;

            .trainingTestFormGradingLabelActive {
              color: #1280CE;
            }
            span {
              margin-left: 16px;
            }
          }
          .trainingTestFormGradingContent {
            margin-top: 16px;

            .trainingTestFormGradingContentItem {
              display: flex;
              flex-direction: column;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
              label {
                margin-bottom: 0;
              }
            }
            .trainingTestFormGradingContentRange {
              display: flex;
              flex-direction: column;

              .trainingTestFormGradingContentRangeItem {
                display: flex;
                align-items: center;
                margin-bottom: 24px;

                & > div:nth-child(1) {
                  margin-top: 16px;
                  margin-right: 16px;
                }
                & > div:nth-child(2) {
                  margin-right: 24px;
                  width: 100%;
                  max-width: 240px;
                }
                & > div:nth-child(3) {
                  margin-right: 8px;
                  width: 100%;
                  max-width: 64px;
                }
                & > div:nth-child(4) {
                  margin-right: 24px;
                  width: 100%;
                  max-width: 64px;
                }
                & > label {
                  margin-top: 32px;
                }
              }
            }
            .trainingTestFormGradingContentRangeAdd {
              display: flex;
              align-items: center;
              margin-bottom: 24px;
              cursor: pointer;

              .trainingTestFormGradingContentRangeAddIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                width: 18px;
                height: 18px;
                background: #1280CE;
                border-radius: 100px;
              }
              span {
                color: #1280CE;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
              }
            }
            .trainingTestFormDelimiter {
              margin-bottom: 32px;
            }
          }
        }
        .trainingTestFormContent {
          h2 {
            margin-top: 24px;
            margin-bottom: 16px;
            color: #262626;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
          }
          .trainingTestFormContentInfo {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            padding: 16px;
            background: #FBFBFD;
            border-radius: 5px;

            i {
              margin-right: 10px;

              svg path {
                fill: #1280CE;
              }
            }
            span {
              color: #000000;
              font-size: 14px;
              line-height: 19px;
              opacity: 0.5;
            }
          }
          .trainingTestFormContentItem {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            span {
              color: #000000;
              font-size: 14px;
              line-height: 21px;
              opacity: 0.5;
            }
          }
        }
      }
      .trainingTestFormButtons {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        border-top: 1px solid #EAEDF3;

        button {
          margin-right: 16px;
        }
      }
    }
  }
  .trainingTestView {
    .trainingTestViewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background: #FFFFFF;

      .trainingTestViewHeaderLeft {
        display: flex;
        align-items: center;

        i {
          margin-right: 16px;
        }
        h1 {
          color: #262626;
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
        }
      }
      .trainingTestViewHeaderRight {
        display: flex;
        align-items: center;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          width: 20px;
          height: 20px;

          &:nth-child(2) svg path {
            fill: #FF6973;
          }
          &:last-child {
            margin-right: 0;
            width: auto;
            height: 40px;
          }
        }
      }
    }
    .trainingTestViewTabs {
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-bottom: 1px solid #EAEDF3;

      li {
        position: relative;
        padding: 16px 24px;
        color: rgba(38, 38, 38, 0.5);
        font-size: 13px;
        font-weight: 500;
        line-height: 12px;
        cursor: pointer;

        &.trainingTestViewTabsActive, &:hover {
          color: #1280CE;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 2px;
            width: 100%;
            background: #1280CE;
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingTest {
    .trainingTestCreate {
      padding: 12px;

      .trainingTestAvatar {
        padding: 0 12px;

        p {
          text-align: center;
        }
      }
      .trainingTestForm {
        .trainingTestFormFields {
          padding: 12px;

          .trainingTestFormField {
            margin-bottom: 12px;
          }
          .trainingTestFormSettings {
            margin: 12px 0;
          }
          .trainingTestFormGrading {
            margin: 12px 0;
          }
        }
        .trainingTestFormButtons {
          padding: 12px;
        }
      }
    }
    .trainingTestView {
      .trainingTestViewHeader {
        padding: 12px;
      }
    }
  }
}