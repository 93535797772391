@import "src/mixins/index.scss";

.top {
    position: absolute;
    top: 0;
    border-left: 1px solid #EAEDF3;
}
.filtersOverlay {
    background: transparent;
}
.search {
    padding: rem(24px);

    em {
        background: #FFE05A;
        color: #262626;
        font-weight: 600;
    }
    .heading {
        display: flex;
        flex-direction: column;

        .searchTitle {
            font-size: rem(22px);
            font-weight: 500;
            line-height: rem(30px);

            span {
                color: #1280CE;
            }
        }
        .searchInstead {
            span {
                color: #1280CE;
                cursor: pointer;
            }
        }
        .searchCount {
            margin-top: rem(8px);
            font-style: normal;
            font-weight: normal;
            font-size: rem(13px);
            line-height: 150%;
        }
    }
    .filters {
        margin-bottom: 24px;

        span {
            display: inline-block;
            margin-bottom: 4px;
            margin-right: 6px;
        }
    }
    .searchResultContainer {
        margin-top: rem(24px);

        .searchResultContent {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            .searchResultContentHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                h4 {
                    color: #262626;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                }
                .searchResultContentHeaderSorting {
                    display: flex;
                    align-items: center;
                    color: #262626;
                    font-size: 13px;
                    line-height: 150%;
                    z-index: 20;

                    .navigationMenu {
                        .navigationMenuTitle {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 100%;
                            color: #1280CE;
                            background: none;
                            border: none;
                        }
                    }
                    .order {
                        padding-left: 12px;
                        border-left: 1px solid #EAEDF3;
                        color: #1280CE;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 100%;
                        cursor: pointer;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        span {
                            display: flex;
                            width: max-content;
                        }

                        .union {
                            display: inline-block;
                            margin-left: 4px;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            .searchResultContentBody {
                display: flex;
                flex-wrap: wrap;

                .searchResultContentBodyEmpty {
                    display: flex;
                    align-items: center;

                    div {
                        display: flex;
                        flex-direction: column;
                        margin-left: 16px;

                        p {
                            margin-bottom: 4px;
                            color: #262626;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 150%;
                        }
                        span {
                            color: #262626;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 150%;
                            opacity: 0.65;
                        }
                    }
                }
                .searchResultContentBodyMore {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    i {
                        margin-right: 4px;
                        transform: rotate(90deg);

                        svg path {
                            fill: #1280CE;
                        }
                    }
                    span {
                        color: #1280CE;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 100%;
                    }
                }
                .fileItem {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: rem(16px);
                    padding: rem(16px);
                    width: calc(50% - 8px);
                    height: 72px;
                    background: #FFFFFF;
                    border: rem(1px) solid var(--color-grayBlue);
                    border-radius: rem(5px);
                    box-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.05);

                    &:nth-child(even) {
                        margin-left: 8px;
                    }
                    &:nth-child(odd) {
                        margin-right: 8px;
                    }
                    .generalInfoContainer {
                        display: flex;

                        .logoContainer {
                            margin-right: rem(16px);
                            cursor: pointer;
                        }
                        .descriptionContainer {
                            // cursor: pointer;

                            .title {
                                height: 18px;
                                color: #000000;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 18px;
                                overflow: hidden;
                            }
                            .coincidences {
                                margin-top: 4px;
                                color: #262626;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 18px;
                                opacity: 0.5;
                            }
                        }
                    }
                    .additionalInfoContainer {
                        display: flex;

                        i {
                            margin-left: 16px;
                            cursor: pointer;
                        }
                    }
                }
                .resultItemWrap {
                    width: 100%;
                    background: #FFFFFF;
                    border: rem(1px) solid var(--color-grayBlue);
                    border-radius: rem(5px);
                    box-shadow: rem(0px) rem(1px) rem(2px) rgba(0, 0, 0, 0.05);
                    margin-bottom: rem(16px);
                    padding: rem(24px);
                    &.resultItemWrapRecommended {
                        border: rem(2px) solid var(--color-primary-green);
                    }
                }

                .resultItem {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    min-height: rem(89px);

                    .generalInfoContainer {
                        display: flex;

                        .logoContainer {
                            margin-right: rem(12px);
                            cursor: pointer;

                            div {
                                display: block;
                                width: rem(88px);
                                height: rem(56px);
                                border-radius: rem(5px);
                            }
                            span {
                                display: block;
                                width: rem(88px);
                                height: rem(56px);
                                border-radius: rem(5px);
                            }
                            .void {
                                background: #f1eff0;
                            }
                            img {
                                width: rem(88px);
                                height: rem(56px);
                                border-radius: rem(5px);
                            }
                        }
                        .descriptionContainer {
                            // cursor: pointer;

                            .titleContainer {
                                display: flex;
                                align-items: center;

                                .titleArchived {
                                    margin-right: 10px;
                                    padding: 4px;
                                    background: #EAEDF3;
                                    border-radius: 5px;
                                    color: #262626;
                                    font-size: 10px;
                                    font-weight: bold;
                                    line-height: 10px;
                                    text-transform: uppercase;
                                }
                                .title {
                                    //display: flex;
                                    align-items: center;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 150%;
                                    color: #262626;
                                }
                            }
                            .coincidences {
                                margin-top: 8px;
                                font-size: 13px;
                                line-height: 150%;
                                color: #262626;
                                display: flex;
                                align-items: center;

                                .fileType {
                                    font-size: 13px;
                                    line-height: 18px;
                                    color: #262626;
                                    opacity: 0.5;
                                }
                                .type {
                                    display: flex;

                                    .img {
                                        width: 20px;
                                        height: 20px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                    .titleType {
                                        margin: 0 16px 0 4px;
                                    }
                                }
                            }
                            .desc {
                                cursor: pointer;
                                margin-top: rem(8px);
                                font-size: rem(13px);
                                line-height: 150%;
                                color: rgba(38, 38, 38, 0.5);
                            }
                        }
                    }
                    .additionalInfoContainer {
                        display: flex;

                        .menuContainer {
                            margin-left: 24px;
                            margin-top: 10px;

                            @media (max-width: 1024px) {
                                display: none;
                            }
                        }
                        .infoContainer {
                            margin-left: rem(24px);

                            .infoTitle {
                                font-size: rem(13px);
                                line-height: 150%;
                                opacity: 0.5;
                            }
                            .infoValue {
                                font-size: rem(13px);
                                line-height: 150%;

                                .author {
                                    padding: 0;
                                    background: transparent;
                                    border: none;
                                    color: #1280CE;
                                    font-weight: 500;
                                    line-height: 100%;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
        .errorSearch {
            margin-top: 88px;

            .errorImg {
                display: flex;
                justify-content: center;
            }
            .errorText {
                display: flex;
                justify-content: center;

                span {
                    width: 459px;
                    font-size: 14px;
                    line-height: 150%;
                    text-align: center;
                    color: #262626;
                    margin: 25px 0 16px 0;
                }
            }
            .homeButton {
                display: flex;
                justify-content: center;

                .button {
                    width: 202px;
                    display: flex;
                    align-items: center;

                    .textButton {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    &.mobile {
        .heading {
            flex-direction: column;

            .rightContainer .order {
                padding-left: 6px;
            }
        }
    }
}
@media (max-width: 768px) {
    .search {
        padding: rem(12px);

        .searchResultContainer {
            .searchResultContent {
                .searchResultContentHeader {
                    flex-direction: column;
                    align-items: flex-start;

                    .searchResultContentHeaderSorting {
                        justify-content: space-between;
                        margin-top: rem(12px);
                        width: 100%;

                        .order {
                            margin-bottom: 0;
                            padding: 0;
                            border-left: none;
                        }
                    }
                }
                .searchResultContentBody {
                    .searchResultContentBodyEmpty {
                        flex-direction: column;
                        width: 100%;

                        div {
                            margin-left: 0;

                            p, span {
                                text-align: center;
                            }
                        }
                    }
                    .resultItem {
                        flex-direction: column;
                        padding: rem(12px);

                        .generalInfoContainer {
                            flex-direction: column;
                            margin-bottom: rem(12px);

                            .logoContainer {
                                margin-right: 0;
                                margin-bottom: rem(12px);
                            }
                        }
                        .additionalInfoContainer {
                            .menuContainer {
                                margin-left: 0;
                            }
                            .infoContainer {
                                margin-left: 0;
                                margin-right: rem(12px);
                            }
                        }
                    }
                    .fileItem {
                        margin-bottom: rem(12px);
                        padding: rem(12px);
                        width: 100%;

                        &:nth-child(even) {
                            margin-left: 0;
                        }
                        &:nth-child(odd) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.resultItemRecommendedTag {
    display: inline-block;
    margin-bottom: 8px;
    padding: 0.1rem 0.25rem;
    font-size: 0.55rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 6px;
    color: var(--color-white);
    background-color: var(--color-primary-green);
}
