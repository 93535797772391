

.nodeContainerOverlay {
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  bottom: -3px;
  /* width: 100%; */
  /* height: 100%; */
  z-index: 1;
  background-color: white;
  outline: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.nodeContainer {
  position: relative;
  padding: 16px;

  &.nodeContainerActive .nodeContainerOverlay {
      outline: 2px solid var(--color-blue);
  }
  
  &.nodeContainerLinked .nodeContainerOverlay {
      outline: 2px dashed var(--color-blue);
  }

  // &:hover {
  &:hover .nodeContainerOverlay {
      background-color: var(--color-grey);
  }
}

.nodeText {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.nodeTextSpan {
  white-space: pre-wrap;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.nodeTargetStep,
.addButtonContainer,
.linkButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nodeTargetStep {
    text-align: center;
    width: 18px;
    height: 18px;
    background-color: #50b678;
    border-radius: 9999px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkButtonContainer,
.addButtonContainer {
    position: absolute;
    z-index: 2;
}

.addButtonContainer {
    width: 17px;
    height: 17px;
    top: unset;
    bottom: -11px;
    left: 50%;
    transform: translateX(-8px);
    // right: -9px;
    background-color: var(--color-blue);
    border-radius: 50%;
    color: white;
    cursor: pointer;
}

.linkButtonContainer {
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--color-gray-light);
    cursor: pointer;
}

.nodeTextSpan {
    white-space: pre-wrap
}

.nodeContainer,
.nodePositiveContainer,
.nodeNegativeContainer,
.nodeNeutralContainer{
    .nodeHandle,
    .nodeHandleLinkTo,
    .nodeHandleLinkFrom {
        visibility: hidden !important;
    }
}
.nodeHandleLinkTo,
.nodeHandleLinkFrom {
    position: absolute;
    z-index: 3;
    transform: translateY(-50%) !important;
}
.nodeHandleLinkTo{
    top: 50% !important;
    right: unset !important;
    left: -3px !important;
}
.nodeHandleLinkFrom {
    left: unset !important;
    top: 50% !important;
    right: -3px !important;
    // bottom: -9px;
    bottom: 50% !important;
    // transform: translateY(50%) !important;
}

.nodePositiveContainer {
    position: relative;
    padding: 7px 8px;
    
    .nodeContainerOverlay {
        background-color: #ebfbee;
        outline: 1px solid #8cb785;
    }
}
.nodePositiveContainerActive .nodeContainerOverlay {
    outline: 2px solid #8cb785;
}

.nodeNegativeContainer {
    position: relative;
    padding: 7px 8px;
  
    .nodeContainerOverlay {
        background-color: #fff5f5;
        outline: 1px solid #df9f9f;
    }
}
.nodeNegativeContainerActive .nodeContainerOverlay {
    outline: 2px solid #df9f9f;
}

.nodeNeutralContainer {
    position: relative;
    padding: 7px 8px;
  
    .nodeContainerOverlay {
        outline: 1px solid var(--color-blue);
    }
}

.nodeNeutralContainerActive .nodeContainerOverlay {
    outline: 2px solid var(--color-blue);
}

.iconAlert {
    margin-top: auto;
    flex-shrink: 0;
    z-index: 5;
}
