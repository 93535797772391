.section {
    position: relative;
    background: #FFFFFF;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        h1 {
            margin: 0 0 8px 0;
            padding: 25px;
            color: #262626;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
        }
        .navigation {
            display: flex;
            align-items: center;
            margin-top: 24px;
            margin-right: 24px;

            @media (min-width: 768px) and (max-width: 1024px) {
                margin-right: 48px;
            }
            div {
                margin-right: 20px;

                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    background: #1280CE;
                    border: 1px solid #1280CE;
                    border-radius: 20px;

                    &:hover {
                        svg path {
                            fill: #FFFFFF;
                        }
                        &:hover {
                            background: #188FE3;
                            box-shadow: 0 2px 4px rgba(18, 128, 206, 0.35);
                        }
                        &:active {
                            background: #0F7AC7;
                            box-shadow: none;
                        }
                    }
                }

                &:not(:first-child):last-child {
                    margin-right: 0;

                    svg path {
                        fill: #FF6973;
                    }
                }
            }
        }
    }
}

ul.sectionMenu {
    position: sticky;
    top: 0;
    display: flex;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;
    z-index: 10;

    #scriptsTab {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    li {
        position: relative;
        padding: 13px 24px;
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 12px;
        opacity: 0.5;
        cursor: pointer;

        &:hover, &.active {
            color: #1280CE;
            opacity: 1;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #1280CE;
                border-radius: 5px 5px 0 0;
            }
        }
    }
}
