@import "src/mixins/index.scss";

.content {
    width: 100%;

    .title {
        padding: 14px 24px 17px;
        font-size: 24px;
        line-height: 33px;
        font-weight: 600;
        background-color: #fff;
    }

    #scriptsTab {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    ul {
        display: flex;
        background-color: #fff;
        border-bottom: 1px solid #EAEDF3;

        @media (max-width: 767px) {
            padding: 0 16px;
        }
        li {
            display: flex;
            position: relative;
            padding: 12px 24px;
            color: rgba(38, 38, 38, 0.5);
            font-size: 13px;
            font-weight: 500;
            line-height: 12px;
            cursor: pointer;

            &:hover, &.active {
                color: #1280CE;

                .count {
                    background: #1280CE;
                    color: #FFFFFF;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #1280CE;
                    border-radius: 5px 5px 0 0;
                }
            }

            .count {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;
                padding-left: 4px;
                padding-right: 4px;
                min-width: 14px;
                min-height: 14px;
                background: #EAEDF3;
                border-radius: 5px;
                color: #7B8F9C;
                font-size: 10px;
                font-weight: 600;
                line-height: 9px;
            }
        }
    }

    .router {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        height: 100%;
    }
}

.tableWrapper {
    height: calc(100% - 103px);
    @include platform-spacing(padding);
}
