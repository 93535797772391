@import "src/mixins/index.scss";

.comment {
  width: 100%;
  padding-bottom: 32px;

  .commentUser {
    display: flex;
    padding-bottom: 8px;
  }

  .commentUserAvatar {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    overflow: hidden;
    background-color: #9F9F9F;
    margin-right: 8px;
  }

  .commentUserName {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    color: #1280CE;
    cursor: pointer;
  }

  .commentContent {
    width: 100%;
    padding-bottom: 9.5px;
    line-height: 150%;
  }

  .commentIcon {
    cursor: pointer;
  }

  .commentDate {
    color: rgba(#262626, 0.5);
    font-size: 13px;
    line-height: 150%;
  }

  .commentInput {
    width: 100%;
  }

  .buttonBlock {
    padding-top: 6px;
    display: flex;
    align-items: center;
  }

  .commentButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      margin-right: 4px;
    }

    &:first-of-type {
      margin-right: 8px;
    }
  }

  .commentCounters {
    display: flex;

    div {
      display: flex;
      align-items: center;
      padding-right: 16px;

      .likeCount {
        margin-left: rem(6px);
        font-size: rem(13px);
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }
}