@import "src/mixins/index.scss";

.modal {
  margin-top: 0 !important;
  // max-height: 100vh;
  max-height: 652px;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: rem(26px);

  .title {
    display: flex;
    justify-content: space-between;
    height: rem(30px);
    font-size: 22px;
    padding-left: rem(32px);
    padding-right: rem(32px);
  }

  .container {
    margin: 0 auto;
    padding: 20px 0;
    max-width: 640px;
    // height: 480px;
    // height: 100%;
    max-height: 520px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    img {
      max-width: 100%;
    }
  }

  .mid {
    flex-basis: 100%;
    width: 200px;
  }

  .midContent {
    height: 300px;
    background-color: burlywood;
    width: 200px;
  }

  .edge {
    height: 40px;
    background-color: aquamarine;
    width: 200px;
  }

  .stretched {
    flex-basis: 100%;
  }

  .buttons {
    display: flex;
    padding: rem(16px);
    align-self: flex-end;
    justify-content: center;
    width: 100%;

    div + div {
      margin-left: rem(16px);
    }
  }

  &.mobile {
    .container {
      width: 100%;
      flex-grow: 1;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .buttons {
      width: 100%;
      flex-direction: column-reverse;
      > * {
        margin: 0;

        &:not(:first-child) {
          margin-bottom: rem(16px);
        }
      }
    }
  }
}

.line {
  background: #EAEDF3;
  height: 1px;
  margin-top: rem(16px);
}