@import "src/mixins/index.scss";

.contentWrapper {
  padding: 24px;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 24px 16px;
  }
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  h1 {
    padding-left: 16px;
    font-weight: 500;
    font-size: 24px;
  }
}

.avatarContainer {
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  img {
    width: rem(120px);
    height: rem(120px);
    border-radius: 50%;
  }

  .infoContainer {
    margin-left: rem(16px);
    position: relative;
    cursor: pointer;

    input[type=file] {
      cursor: pointer;
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;

    }

    p {
      color: var(--color-textBlack);
      font-size: 13px;
      line-height: 150%;
      opacity: 0.5;
    }

    .btnContainer {
      display: flex;
      padding-top: 8px;

      button {
        display: inline-flex;
        align-items: flex-end;
      }

      & > div {

        &:first-of-type {
          margin-right: 16px;

          svg path {
            fill: #1280CE;
          }
        }

        &:last-of-type {
          svg path {
            fill: #d10000;
          }
        }

        i {
          margin-right: 8px;
        }
      }
    }

  }
}

.formContainer {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  @include platform-spacing(padding);

  .formBody {
    margin-bottom: 24px;
    h2 {
      font-weight: 600;
      font-size: rem(14px);
      line-height: 150%;
      margin-bottom: 1rem;
    }

    .formContent {
      display: flex;

      & > div:first-of-type {
        padding-right: 8px;
      }

      & > div:last-of-type {
        padding-left: 8px;
      }

      .address {
        padding-bottom: 16px;
      }

      .additionalInfo {
        display: flex;
        padding-bottom: 16px;

        & > div:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .mapWrapper {
      position: relative;
      width: 422px;
      height: 355px;
      background-color: #9F9F9F;
      border-radius: 5px;
    }
  }

  .formFooter {
    display: flex;
    border-top: 1px solid #EAEDF3;
    padding-top: 16px;

    & > div:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.mobile {
  .formBody {
    .formContent {
      flex-direction: column-reverse;
      > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      > *:first-child {
        margin-top: 8px;
      }
      .mapWrapper {
        width: 100%;
      }
    }
  }
  .formFooter {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    > * {
      width: 100%;
      &:first-child {
        margin-top: 16px;
      }
    }
  }
}
