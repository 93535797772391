.notificationWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.notification {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 12px;
    background-color: rgba(39, 155, 217, 0.1);
    border-radius: 5px;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
}

.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity 200ms;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: opacity 200ms;
}
