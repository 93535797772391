@import "src/mixins/index.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .search {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: rem(16px);
    border-bottom: 1px solid #EAEDF3;
    border-left: 2px solid transparent;

    .searchIcon {
      margin-right: rem(8px);
    }

    input {
      flex-grow: 1;
      border: 0;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
    }
  }

  .allButton {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: rem(16px) rem(16px) rem(16px) rem(40px);
    border-left: 2px solid transparent;
    border-bottom: 1px solid #EAEDF3;

    .allButtonIcon {
      margin-left: rem(3.5px);
      margin-right: rem(11.5px);
    }
    &.allButtonActive {
      background: rgba(39, 155, 217, 0.05);
      color: #1280CE;
    }
  }

  .treeContainer {
    flex-grow: 1;
    & > div:first-child {
      width: 100% !important;

      & > div > div {
        width: 100% !important;
      }
    }
  }
}
