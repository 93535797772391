@import "src/mixins/index.scss";

.switch {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .input {
    position: absolute;
    width: 0;
    height: 0;
    display: none;
  }
  .label {
    display: block;
    width: 48px;
    height: 24px;
    background: #E8E8E8;
    border-radius: 100px;
    padding: rem(4px);
    cursor: pointer;
    transition: background-color ease-in-out 0.2s, transform ease-in-out 0.2s;

    .toggler {
      display: block;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
  .description {
    margin-left: 16px;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
  .input:checked + .label .toggler {
    transform: translateX(calc(100% + 6px));
  }
  .input:checked + .label {
    background: #1280CE;
  }
}