.modalContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    //padding: 26px 32px 0 24px;
    width: 750px;
    height: 780px;

    .divider {
        margin-left: 16px -34px 16px -24px;
    }


    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        margin-top: 24px;
        padding-left: 24px;
        padding-right: 32px;
   }

   .container {
        width: 100%;
        flex-direction: column;
        padding: 0 32px;

       .filters {
           display: flex;
           margin-bottom: 18px;
           width: 100%;

           .select {
               margin-right: 8px;
               .trainingAccessModalBodyFiltersLabel {
                   color: #262626;
                   font-size: 14px;
                   font-weight: 500;
                   line-height: 20px;
               }
               .trainingAccessModalBodyFiltersSpan {
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   margin-left: 6px;
                   width: 18px;
                   height: 18px;
                   background: #1280CE;
                   border-radius: 18px;
                   color: #FFFFFF;
                   font-size: 13px;
                   font-weight: 500;
                   line-height: 14px;
               }
           }
       }

       .trainingAccessModalBody {
           .trainingAccessModalBodyFilters {
               display: flex;
               align-items: center;
               margin-bottom: 16px;

               & > div {
                   margin-right: 8px;

                   & > div {
                       background: #FFFFFF;
                       border: 1px solid #E8E8E8;
                       border-radius: 5px;
                       box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

                       & > div:nth-child(2) {
                           & > div {
                               padding: 0;
                           }
                       }
                   }
                   .trainingAccessModalBodyFiltersLabel {
                       color: #262626;
                       font-size: 14px;
                       font-weight: 500;
                       line-height: 20px;
                   }
                   .trainingAccessModalBodyFiltersSpan {
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       margin-left: 6px;
                       width: 18px;
                       height: 18px;
                       background: #1280CE;
                       border-radius: 18px;
                       color: #FFFFFF;
                       font-size: 13px;
                       font-weight: 500;
                       line-height: 14px;
                   }
               }
           }
           .trainingAccessModalBodySelect {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin-bottom: 16px;

               span {
                   color: #262626;
                   font-size: 13px;
                   font-weight: 500;
                   line-height: 13px;
               }
               p {
                   color: #1280CE;
                   font-size: 13px;
                   font-weight: 500;
                   line-height: 13px;
                   cursor: pointer;
               }
           }
           .trainingAccessModalBodyUsers {
               height: 450px;
               overflow: auto;

               .trainingAccessModalBodyUser {
                   display: flex;
                   align-items: center;
                   padding: 16px 0;

                   img {
                       margin-left: 8px;
                       width: 24px;
                       height: 24px;
                       border-radius: 24px;
                       object-fit: cover;
                   }
                   span {
                       margin-left: 8px;
                       color: #262626;
                       font-size: 13px;
                       font-weight: 500;
                       line-height: 13px;
                   }
               }
           }
           .trainingAccessModalBodyDates {
               display: flex;
               align-items: center;
               margin-bottom: 24px;

               & > div:first-child {
                   margin-right: 16px;
               }
           }
           .trainingAccessModalBodyDelimiter {
               margin-bottom: 24px;
               width: 100%;
               height: 1px;
               background: #EAEDF3;
           }
           .trainingAccessModalBodyCheck {
               display: flex;
               flex-direction: column;
               margin-bottom: 24px;

               label {
                   margin-bottom: 0;
               }
               p {
                   margin-top: 8px;
                   color: #000000;
                   font-size: 14px;
                   line-height: 21px;
                   opacity: 0.5;
               }
               .trainingAccessModalBodyCheckContainer {
                   display: flex;
                   align-items: center;
                   margin-top: 16px;
                   margin-left: 24px;

                   div {
                       width: 160px;
                   }
                   span {
                       margin-left: 16px;
                       color: #000000;
                       font-size: 14px;
                       line-height: 19px;
                       opacity: 0.5;
                   }
               }
           }
       }

       .selection {
           height: 13px;
           width: 100%;
           display: flex;
           justify-content: space-between;
           align-items: center;
           margin-bottom: 17px;
           .left {
               font-weight: 500;
               font-size: 13px;
               line-height: 100%;
           }
           .right {
               font-weight: 500;
               font-size: 13px;
               line-height: 100%;
               text-align: right;
               color: #1280CE;
               cursor: pointer;
           }
       }

       .content {
           width: 100%;
           height: 432px;
           overflow: auto;

           .userContainer {
               display: flex;
               align-items: center;
               height: 48px;

               .userInfo {
                   display: flex;
                   align-items: center;
                   margin-left: 8px;
                   cursor: pointer;

                   img {
                       width: 24px;
                       height: 24px;
                       border-radius: 50%;
                       margin-right: 8px;
                   }
               }

           }
       }

    }

    .stretched {
        flex-basis: 100%;
    }

    .buttons {
        display: flex;
        align-self: flex-end;
        height: 73px;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        background: white;
        border-top: #eaedf3 1px solid;
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 24px;

        div + div {
            margin-left: 16px;
        }
    }
}

.searchContainer {
    margin-top: 32px;
    padding: 0 32px;

    .search {
        display: flex;
        align-items: center;
        padding: 10px 14px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;

        .searchButton {
            display: inherit;
            margin-right: 10px;
        }

        input {
            width: 100%;
            border: 0;
            color: #262626;
            font-size: 14px;
            outline: none;

            &::placeholder {
                color: #262626;
                opacity: 0.4;
            }
        }
    }
}

.customMenuListItem {
    color: inherit;
    cursor: default;
    display: flex;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
    align-items: center;
}

.checkbox {
    margin: 0;
    pointer-events: none;
}
