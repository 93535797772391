@import "src/mixins/index.scss";

.pageWrapper {
  overflow: hidden;
  
  &.withNewsTicker {
    height: calc(100% - 90px);
  }
}

.routerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  background-color: #fff;
  height: calc(100% - 62px);

}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;

  h1 {
    display: flex;
    align-items: center;
    padding: 16px 24px 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;

  }
}

.link {
  color: #1280CE;
  font-weight: 500;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 572px;

  .head {
    display: flex;
    justify-content: space-between;
    height: 56px;
    padding: 24px 24px 0 24px;
    align-items: center;

    .text {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      align-self: flex-end;
    }
  }

  .subTitle {
    padding: 32px 32px 16px;
    border-bottom: 1px solid #EAEDF3;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 32px;
    max-height: 435px;
    overflow: auto;

    .hint {
      line-height: 21px;
    }
  }

  .footer {
    border-top: 1px solid #EAEDF3;
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    & > div:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  &.mobile {
    width: auto;
    .head {
      height: auto;
    }
    .content {
      padding: rem(24px);
    }
    .footer {
      flex-direction: column-reverse;
      > * {
        width: 100%;
        &:first-child {
          margin-right: 0;
          margin-top: rem(16px);
        }
      }
    }
  }
}

.modalOverlay {
  position: fixed;
  padding: rem(32px);
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background: rgba(#275677, 0.65);
  z-index: 10;

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
}
