.tableWrapper {
  position: relative;
  width: 100%;
}

.chart {
  padding: 24px;
  background-color: #FBFBFD;
}

.table {
  position: relative;
  height: calc(100% - 74px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .body {
    position: relative;
    display: flex;
    align-content: flex-start;
    flex-grow: 1;
    width: 100%;
    min-height: 150px;
    background: #FFFFFF;

    & > div {
      width: 100%;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }

  .empty {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex-grow: 1;

    i {
      padding-bottom: 16px;
    }

    div:first-of-type {
      padding-bottom: 4px;
      font-weight: 500;
    }

    div:last-of-type {
      color: rgba(#262626, 0.65);
    }

    & > * {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }

  .pagination {
    padding: 16px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-radius: 0 0 5px 5px;
  }
}