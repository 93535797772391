@import "src/mixins/rem";
@import "src/mixins/mixins";

.category {
    display: flex;
    padding: 0;

    & .name {
        @include text-middle;
        width: 288px;
    }

    & .tags {
        flex-grow: 1;

        span + span {
            margin-left: 0.5rem;
        }
    }

    & .buttons {
        div + div {
            margin-left: 1rem;
        }
    }
}
