@import "src/mixins/index.scss";

.stretched {
  flex-basis: 100%;
  margin-top: rem(16px);
  padding-left: rem(32px);
  padding-right: rem(32px);
}

.selectors {
  padding-top: rem(32px);
  padding-left: rem(32px);
  padding-right: rem(32px);

  & > div:last-child {
    margin-top: rem(16px);
  }

  &.mobile {
    padding-top: rem(24px);
    padding-left: rem(24px);
    padding-right: rem(24px);
  }
}

.line {
  background: #EAEDF3;
  height: 1px;
  margin-top: rem(16px);
}