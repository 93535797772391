@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.label {
  @include inputLabel;
}

.container {
  position: relative;
}

.input {
  @include input;
}

.input.inputOpen {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  border: 2px solid var(--color-blue);
  border-top: 0;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionHighlighted {
  background-color: #ddd;
}