.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.videoWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .video {
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 9999px;
    background-color: #0084e2;
    cursor: pointer;
  }
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(38, 38, 38, 0.7);

  .buttonWrapper {
    display: flex;
    align-items: center;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    text-align: center;
    background-color: rgba(38, 38, 38, 0.7);
  }

  .maximize {
    padding-right: 12px;
    cursor: pointer;
  }
}

.volumeContainer {
  user-select: none;
  display: flex;
  align-items: center;
}

.volumeWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  width: 18px;
  max-width: 80px;
  padding-right: 6px;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    width: 80px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}

.volumeMute {
  flex-shrink: 0;
}

.volumeBar {
  margin-left: 8px;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  border-radius: 6px;
  height: 4px;
  cursor: pointer;

  .volume {
    position: relative;
    height: 12px;
    height: 12px;
    border: 1px solid #4f5960;
    border-radius: 9999px;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}

.barContainer {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 12px;
  padding-right: 12px;

  .timeStart {
    color: #fff;
    line-height: 8px;
    padding-right: 6px;
  }
  .timeEnd {
    color: #fff;
    line-height: 8px;
    padding-left: 6px;
  }

  .progress {
    position: relative;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    height: 4px;
    cursor: pointer;
    background-color: #899196;
  }

  .progressPosition {
    position: absolute;
    z-index: 20;
    height: 100%;
    background-color: #1280CE;
  }
}
