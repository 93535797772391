.trainingThemeFormQuestionAvatar {
  margin-bottom: 24px;

  input[type=file] {
    display: none;
  }
  .trainingThemeFormQuestionAvatarEmpty {
    display: flex;
    align-items: center;

    .trainingThemeFormQuestionAvatarDescription {
      display: flex;
      flex-direction: column;
      margin-left: 24px;

      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.65;
      }
    }
  }
  .trainingThemeFormQuestionAvatarFull {
    display: flex;
    align-items: flex-end;

    img {
      margin-right: 24px;
      width: 160px;
      height: 90px;
      border-radius: 5px;
      object-fit: cover;
    }
    .trainingThemeFormQuestionAvatarReadyContainer {
      display: flex;
      flex-direction: column;

      h4 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
      .trainingThemeFormQuestionAvatarReadyContainerButtons {
        display: flex;
        align-items: center;

        div:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingThemeFormQuestionAvatar {
    .trainingThemeFormQuestionAvatarEmpty {
      flex-direction: column;

      .trainingThemeFormQuestionAvatarDescription {
        margin-top: 12px;
        margin-left: 0;

        p {
          text-align: center;
        }
      }
    }
    .trainingThemeFormQuestionAvatarFull {
      flex-direction: column;
      align-items: center;

      .trainingThemeFormQuestionAvatarReadyContainer {
        margin-top: 12px;

        h4 {
          text-align: center;
        }
      }
    }
  }
}