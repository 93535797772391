.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    margin-bottom: 24px;

    h1 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
    }
  }

  .notification, .notificationUnread {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;

    &:last-child {
      border-bottom: none;
    }
    &.notificationRead {
      background: #FFFFFF;
    }
    .notificationContent {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      width: 100%;
      max-width: 650px;

      img {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 32px;
      }
      p {
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;

        a {
          display: inline;
          color: #1280CE;
        }
        span {
          display: block;
        }
      }
    }
    .notificationComment {
      margin-bottom: 8px;
      padding: 12px;
      width: 100%;
      max-width: 650px;
      background: #FBFBFD;
      border-radius: 5px;
      color: #262626;
      font-size: 14px;
      line-height: 150%;
    }
    button {
      margin-bottom: 8px;
    }
    .notificationTime {
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      line-height: 150%;
    }
  }
  .notificationUnread {
    background: rgba(18, 128, 206, 0.03);
  }
}
