@import "src/mixins/index.scss";

.documentLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.defaultSize {
    border-radius: 100px;
    padding: rem(6px);
    .text {
      font-weight: 500;
      font-size: rem(13px);
      line-height: rem(14px);
    }
  }
  &.small {
    border-radius: 5px;
    padding: rem(4px);
    .text {
      font-weight: bold;
      font-size: rem(10px);
      line-height: rem(10px);
      text-transform: uppercase;
    }
  }

  &.yellow {
    background: #FFE05A;
  }
  &.default {
    background: #EAEDF3;
  }
  &.blue {
    background: #1280CE;
    color: white;
  }
  &.transparent {
    background: rgba(18, 128, 206, 0.15);
    color: #1280CE;
  }
}