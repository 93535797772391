@import "src/mixins/index.scss";

.user {
  display: flex;

  .image {
    width: 40px;
    height: 40px;
    background-color: #EAEDF3;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 40px;
    flex-shrink: 0;
    margin-right: rem(8px);
  }

  .desc {
    display: flex;
    flex-direction: column;

    .link {
      color: var(--color-blue);
      font-size: rem(13px);
      font-weight: 500;
      cursor: pointer;
      line-height: rem(20px);
    }

    .text {
      color: var(--color-textBlack);
      font-size: rem(13px);
      line-height: rem(20px);
      opacity: 0.5;
    }
  }

  .desc.descReverse {
    flex-direction: column-reverse;
  }
}