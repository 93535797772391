@import "src/mixins/index.scss";

.avatarPanel {
  display: flex;
  align-items: center;
  padding: rem(12px) 0;

  img {
    width: rem(120px);
    height: rem(120px);
    border-radius: 50%;
  }

  .infoContainer {
    display: flex;
    margin-left: rem(16px);
    position: relative;
    cursor: pointer;

    input[type=file] {
      display: none;
    }

    & > div {
      &:first-child button {
        margin-right: rem(16px);
      }

      &:nth-child(2) button i svg path {
        fill: #FF6973;
      }
    }
  }

  &.mobile {
    align-items: flex-start;
    .infoContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: rem(32px);

      > *:not(input) {
        width: 166px;

        button {
          width: 100%;
        }
      }
    }
  }
}