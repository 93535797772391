@import "src/mixins/index.scss";

.modalOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(#275677, 0.65);
    z-index: 19;

    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        background: rgba(#275677, 0.65);
    }
}

.modal {
    position: relative;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 568px;
    background-color: #fff;
    border: 1px solid #EAEDF3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 999;

    .iconButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: rem(10px) rem(16px);
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        img {
            filter: invert(45%) sepia(61%) saturate(6191%) hue-rotate(186deg) brightness(94%) contrast(86%);
        }

        span {
            margin-left: 8px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            font-family: 'NotoSans', sans-serif;
        }
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    padding-top: rem(24px);
    padding-left: rem(24px);
    padding-right: rem(24px);
    padding-bottom: rem(32px);

    h3 {
        font-size: rem(16px);
        font-weight: 500;
    }
}

.modalBody {
    display: flex;
    flex-wrap: wrap;
    padding: rem(32px);
    padding-top: 0;
    max-height: rem(300px);
    overflow: auto;

    @media (max-width: 768px) {
        padding-bottom: rem(16px);
    }
}

.uploadFiles {
    position: relative;
    display: flex;
    width: 100%;

    &:not(:last-of-type) {
        padding-bottom: rem(24px);
        margin-bottom: rem(24px);
        border-bottom: 1px solid #EAEDF3;

    }

    .iconWrapper {
        padding-right: rem(12px);
    }

    .progressWrapper {
        width: 100%;
        padding-bottom: rem(8px);

        @media (min-width: 769px) {
            padding-right: rem(30px);
        }
    }

    .uploadInfo {
        width: 100%;
    }

    .fileName {
        display: flex;
        align-items: baseline;
        padding-bottom: rem(8px);
        word-break: break-word;

        span {
            padding-right: rem(8px);
        }

        img {
            cursor: pointer;
        }
    }

    .loadDescription {
        color: rgba(#262626, 0.5);
    }

    .cancelIcon {
        position: absolute;
        top: 0;
        right: 0;
        width: rem(14px);
        height: rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1280CE;
        border-radius: 9999px;

        svg path {
            fill: #FFFFFF;
        }
    }

    .uploadedIconWrapper {
        padding-bottom: rem(8px);
        display: flex;
        justify-content: flex-end;
    }

    .uploadedIcon {
        width: rem(14px);
        height: rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #50B678;
        border-radius: 9999px;

        img {
            filter: brightness(200%);
        }
    }

    .openFileButton {
        color: #1280CE;
        cursor: pointer;
    }
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: rem(16px) rem(24px);
    border-top: 1px solid #EAEDF3;

    > *:not(:last-child) {
        padding-right: rem(16px);
    }
    @media (max-width: 768px) {
        flex-direction: column-reverse;

        > *:not(:last-child) {
            margin-top: rem(8px);
            padding-right: 0;
        }
        button {
            width: 100%;
        }
    }
}

.backUploadModal {
    position: fixed;
    bottom: 0;
    right: rem(8px);
    max-width: 416px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 50px rgba(#000, 0.03);
    border: 1px solid #EAEDF3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 999;


    .backUploadHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(16px);
        border-bottom: 1px solid #EAEDF3;


        .backUploadIcons {
            display: flex;
            align-items: center;

            i {
                cursor: pointer;
            }

            i:not(:last-of-type) {
                margin-right: rem(26px);
            }
        }
    }

    .backUploadBody {
        height: rem(350px);
        padding-left: rem(16px);
        padding-right: rem(16px);
        overflow: auto;
        transition: height ease-out 0.2s;

        & > div:last-of-type {
            padding-bottom: rem(16px);
        }
    }

    .hidden {
        height: 0;
        transition: height ease-out 0.2s;
    }

    .backUploadCancelAllBtn {
        padding-top: rem(16px);
        padding-bottom: rem(24px);
        font-size: 13px;
        font-weight: 500;
        color: #1280CE;
        cursor: pointer;
    }
}
