.RootMenu {
    border: none;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
}

.Menu {
    background: rgba(255, 255, 255, 1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 8px 20px 0 rgba(0, 0, 0, 0.12);
    outline: 0;
    overflow: hidden;
    z-index: 999;

    @media (max-width: 1024px) {
        position: fixed !important;
        top: unset !important;
        bottom: 0;
        transform: unset !important;
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        border: 1px solid #E8E8E8;
        border-radius: 5px 5px 0 0;
        will-change: unset !important;
        z-index: 99999;

        .MenuContent {
            position: relative;
            padding-top: 0;
            padding-bottom: 41px;
            max-height: 281px;
            overflow: auto !important;

            &.MenuContentOne {
                overflow: hidden !important;

                .MenuItem {
                    height: 281px;
                    width: 100%;

                    & > div {
                        width: 100%;
                    }
                }
            }
            .MenuItem {
                padding-left: 0;
                height: 40px;
                color: #262626;
                font-size: 13px;
            }
            .MenuContentClose {
                position: fixed;
                bottom: 0;
                left: 0;
                display: block;
                padding: 10px 12px;
                width: calc(100% + 10px);
                background: #FFFFFF;
                border-top: 1px solid #E8E8E8;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}

.MenuContent {
    padding: 7px;
    overflow-y: auto;
    max-height: 354px;

    .MenuContentClose {
        display: none;
    }
}

.MenuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    line-height: 21px;
    margin: 0;
    outline: 0;
    padding: 6px;

    &:not(.disableHover):hover {
        color: rgba(51, 139, 194, 1);
        background-color: rgba(39, 155, 217, 0.1);
    }

    &.selected {
        color: rgba(51, 139, 194, 1);
        background-color: rgba(39, 155, 217, 0.1);
    }
}

.MenuItem:disabled {
    color: rgba(113, 112, 112, 1);
    pointer-events: none;
    cursor: not-allowed;
}

.MenuItem[data-nested][data-open]:not([data-focus-inside]) {
    color: rgba(51, 139, 194, 1);
    background-color: rgba(39, 155, 217, 0.1);
}

.MenuItem[data-focus-inside][data-open] {
    background: (39, 155, 217, 0.1);
}
