@import "src/mixins/index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //@include platform-spacing(padding);

  .news, .projects {
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //@include platform-spacing(margin-bottom);
      @include platform-spacing(margin);

      h2 {
        margin: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }

      a {
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
      }
    }

    .newsCarousel {
      overflow: hidden;
      max-height: 342.5px;
    }

    .projectsCarousel {
      max-height: 377px;
    }
  }

  .news, .projects {
    margin-bottom: 24px;
    overflow: hidden;
  }

  .titleWrapper {
    .desc {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      margin-top: 8px;
      font-weight: 400;
      -webkit-line-clamp: 3;
    }

    .titleBlock {
      width: 100%;
      color: #262626 !important;

      &.titleMobile p {
        margin-bottom: 8px;
      }

      &:hover {
        p {
          color: #1280CE;
        }
      }
    }
  }

  .subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
  }

  .articleTableContainer {
    @include platform-spacing(margin);
    margin-top: 0;
  }
}
