.trainingAccessModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(39, 86, 119, 0.65);
  z-index: 10;

  .trainingAccessModal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .trainingAccessModalHeader {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 32px 32px 0 32px;

      &.trainingAccessModalHeaderDelimiter {
        border-bottom: 1px solid #EAEDF3;

        .trainingAccessModalHeaderTop {
          margin-bottom: 32px;
        }
      }
      .trainingAccessModalHeaderTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h2 {
          color: #262626;
          font-size: 22px;
          font-weight: 500;
          line-height: 30px;
        }
      }
      .trainingAccessModalHeaderBottom {
        position: relative;
        margin-bottom: 16px;
        width: 100%;

        i {
          position: absolute;
          top: 14px;
          left: 14px;
        }
        input {
          padding-left: 34px;
          height: 40px;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E8E8E8;
          border-radius: 5px;
        }
      }
    }
    .trainingAccessModalBody {
      padding: 0 32px;

      .trainingAccessModalBodyFilters {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        & > div {
          margin-right: 8px;

          & > div {
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            & > div:nth-child(2) {
              & > div {
                padding: 0;
              }
            }
          }
          .trainingAccessModalBodyFiltersLabel {
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          .trainingAccessModalBodyFiltersSpan {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 6px;
            width: 18px;
            height: 18px;
            background: #1280CE;
            border-radius: 18px;
            color: #FFFFFF;
            font-size: 13px;
            font-weight: 500;
            line-height: 14px;
          }
        }
      }
      .trainingAccessModalBodySelect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        span {
          color: #262626;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
        }
        p {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
          cursor: pointer;
        }
      }
      .trainingAccessModalBodyUsers {
        height: 450px;
        overflow: auto;

        .trainingAccessModalBodyUser {
          display: flex;
          align-items: center;
          padding: 16px 0;

          img {
            margin-left: 8px;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            object-fit: cover;
          }
          span {
            margin-left: 8px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
          }
        }
      }
      .trainingAccessModalBodyDates {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        & > div:first-child {
          margin-right: 16px;
        }
      }
      .trainingAccessModalBodyDelimiter {
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        background: #EAEDF3;
      }
      .trainingAccessModalBodyCheck {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        label {
          margin-bottom: 0;
        }
        p {
          margin-top: 8px;
          color: #000000;
          font-size: 14px;
          line-height: 21px;
          opacity: 0.5;
        }
        .trainingAccessModalBodyCheckContainer {
          display: flex;
          align-items: center;
          margin-top: 16px;
          margin-left: 24px;

          div {
            width: 160px;
          }
          span {
            margin-left: 16px;
            color: #000000;
            font-size: 14px;
            line-height: 19px;
            opacity: 0.5;
          }
        }
      }
    }
    .trainingAccessModalFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;

      div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingAccessModalOverlay {
    .trainingAccessModal {
      .trainingAccessModalHeader {
        padding: 12px;
      }
      .trainingAccessModalBody {
        padding: 12px;

        .trainingAccessModalBodyFilters {
          flex-direction: column;

          & > div {
            margin-bottom: 12px;
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }
  }
}