@import 'src/mixins/index.scss';

.content {
    @include platform-spacing(padding);

    .pageTitle {
        @include header-large;
        margin: 0 0 1rem 0;
    }

    .emptyCard {
        height: 100%;

        .empty {
            margin-top: 0;
        }
    }
}
