.chatGroup {
  position: relative;
  padding: 24px 24px 97px 24px;
  height: 100%;
  background: #FFFFFF;
  overflow: auto;

  h2 {
    margin-bottom: 24px;
    color: #262626;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }
  .chatGroupAvatar {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .chatGroupAvatarPreview {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 120px;
      height: 120px;
      background: #EAEDF3;
      border-radius: 120px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 120px;
        object-fit: cover;
      }
    }

    .chatGroupAvatarLoad {
      display: flex;

      div:nth-child(2) {
        margin-right: 1rem;
      }

      div:nth-child(3) svg path {
        fill: #FF6973;
      }

      input[type=file] {
        display: none;
      }
    }
  }
  .chatGroupInput {
    margin-bottom: 16px;

    &:nth-child(4) {
      margin-bottom: 32px;
    }
  }
  .separator {
    margin-bottom: 32px;
    width: 100%;
    height: 1px;
    background: #EAEDF3;
  }
  .chatGroupUsers {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 16px;
      color: #262626;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }

    button {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 16px;
      background: rgba(39, 155, 217, 0.1);
      border: 1px solid rgba(39, 155, 217, 0.15);
      border-radius: 5px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(39, 155, 217, 0.05);
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        width: 18px;
        height: 18px;
        background: #1280CE;
        border-radius: 18px;
      }

      span {
        color: #1280CE;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
      }
    }

    .chatGroupGroupsItems {
      margin-bottom: 16px;

      .group {
        &:hover, &.groupOpen {
          .row {
            background: rgba(18, 128, 206, 0.05);
            border-radius: 5px;

            .left {
              .arrow {
                transform: rotate(180deg);
              }
              p {
                color: #1280CE;
              }
            }
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 8px;
          cursor: pointer;

          .left {
            display: flex;
            align-items: center;

            .arrow {
              transform: rotate(90deg);
            }
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 8px 0 20px;
              width: 24px;
              height: 24px;
              background: #EAEDF3;
              border-radius: 24px;
            }
            p {
              margin: 0;
              color: #262626;
              font-size: 13px;
              font-weight: 500;
              line-height: 13px;
            }
          }
          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 50%;

            .trashIcon:hover {
              svg path {
                fill: #1280CE;
              }
            }
          }
        }
        .items {
          display: flex;
          flex-direction: column;
          padding: 0 38px;

          .item {
            display: flex;
            align-items: center;
            padding: 12px 0;

            .avatar {
              margin-right: 8px;
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background: #EAEDF3;

              img {
                width: 100%;
                border-radius: 24px;
                object-fit: cover;
              }
            }
            p {
              margin: 0;
              color: #262626;
              font-size: 13px;
              font-weight: 500;
              line-height: 13px;
            }
          }
        }
      }
    }
    .chatGroupUsersItems {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
      .chatGroupUsersItemsItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        .chatGroupUsersItemsItemContainer {
          display: flex;
          align-items: center;

          .chatGroupUsersItemsItemContainerAvatar {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: #EAEDF3;
            border-radius: 24px;

            img {
              width: 100%;
              border-radius: 24px;
              object-fit: cover;
            }
          }
          p {
            margin-bottom: 0;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
          }
        }

        i {
          cursor: pointer;

          &:hover svg path {
            fill: #E06169;
          }
        }
      }
    }
  }
  .chatGroupButtons {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: -24px;
    padding: 16px 24px;
    width: 100%;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;

    button:first-child {
      margin-right: 16px;
    }
  }
}
@media (max-width: 768px) {
  .chatGroup {
    .chatGroupAvatar {
      flex-direction: column;

      .chatGroupAvatarPreview {
        margin-bottom: 12px;
        margin-right: 0;
      }
    }
  }
}