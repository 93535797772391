.trainingOpen {
  .trainingOpenContainer {
    padding: 24px;

    h1 {
      margin-bottom: 24px;
      color: #262626;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
    .trainingOpenItems {
      display: flex;
      flex-direction: column;

      .trainingOpenItem {
        margin-bottom: 24px;
        padding: 24px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        .trainingOpenItemTop {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;

          img {
            display: block;
            margin-right: 16px;
            min-width: 240px;
            height: 130px;
            border-radius: 5px;
            object-fit: cover;
          }
          .trainingOpenItemContent {
            display: flex;
            flex-direction: column;
            width: 100%;

            h3 {
              margin-bottom: 8px;
              color: #262626;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              cursor: pointer;
            }
            p {
              color: #000000;
              font-size: 14px;
              line-height: 21px;
              opacity: 0.8;
            }
          }
        }
        .trainingOpenItemBottom {
          display: flex;

          li {
            display: flex;
            flex-direction: column;
            margin-right: 32px;

            span {
              color: #262626;
              font-size: 13px;
              line-height: 19px;
              opacity: 0.5;
            }
            p {
              color: #262626;
              font-size: 13px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingOpen {
    .trainingOpenContainer {
      padding: 12px;

      .trainingOpenItems {
        .trainingOpenItem {
          padding: 12px;

          .trainingOpenItemTop {
            flex-direction: column;
            margin-bottom: 12px;

            img {
              margin-bottom: 12px;
              margin-right: 0;
            }
            .trainingOpenItemContent {
              margin-bottom: 12px;
            }
          }
          .trainingOpenItemBottom {
            flex-direction: column;

            li {
              flex-direction: row;

              span {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}