.ck-editor__editable_inline {
    height: var(--ckh, 200px);
    max-height: var(--ckh, 200px);;
}
.cke_maximized {
    height: 100vh !important;
}
.cke_maximized .cke_contents {
    height: 100% !important;
}

