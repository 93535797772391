@import 'src/mixins/index.scss';

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerWrapper {
    padding: 24px;
    border-bottom: 1px solid #eaedf3;
    background-color: #fff;

    .companyDescWrapper {
        display: flex;
        justify-content: space-between;
        
        i {
          cursor: pointer;
        }

        .companyDesc {
            display: flex;
            align-items: flex-start;

            .logo {
                display: flex;
                flex-shrink: 0;
                width: 80px;
                height: 80px;
                border-radius: 9999px;
                background-color: dodgerblue;
                margin-right: 16px;
            }

            .title {
                padding-bottom: 8px;
                font-size: 24px;
                font-weight: 600;
                line-height: 33px;
            }

            .desc {
                line-height: 150%;
            }

      .addCompany {
        display: flex;
        align-self: center;
        font-weight: 500;
        font-size: 24px;
        color: rgba(#262626, 0.5);
        
        & > div:first-of-type {
          cursor: default;
        }

        & > div {
          padding-right: rem(8px);
        }
      }
    }
  }
}

.contentWrapper {
    @include platform-spacing(padding);

    .header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;

        .title {
            font-size: 22px;
            font-weight: 500;
            line-height: 33px;
        }

    }

    .city {
        font-weight: 500;
        padding-bottom: 8px;
    }

    .address {
        font-size: 13px;
        padding-bottom: 8px;
    }

    .desc {
        color: rgba(#262626, 0.5);
    }
}
