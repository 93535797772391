.tabAll {
  display: flex;
  flex-direction: column;
  padding: 24px;

  .news, .articles {
    margin-bottom: 24px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      h2 {
        margin: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }

      a {
        color: #1280CE;
        font-size: 14px;
        font-weight: bolder;
        line-height: 150%;
      }
    }

    .table {
      position: relative;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      .loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
      }

      .filter {
        padding: 24px;
        border-bottom: 1px solid #EAEDF3;
      }

      .pagination {
        padding: 16px 24px;
        background: #FFFFFF;
        border-top: 1px solid #EAEDF3;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}