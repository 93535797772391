
.chatWindowBody {
  padding: 24px 16px;
  width: 100%;


    .chatWindowBodyDate {
      margin-bottom: 16px;
      width: 100%;
      color: #7B8F9C;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      transition: all 0.3s ease-in-out;
    }
}
