@import "src/mixins/index.scss";

.card {
    flex-grow: 1;
    padding-bottom: 0;
    .blockText {
        font-style: normal;
        font-weight: normal;
        font-size: rem(14px);
        line-height: 150%;
    }

    .templateFileBlock {
        margin-top: rem(24px);
        padding: rem(24px);
        background: #FBFBFD;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;

        .templateFileInfo {
            display: flex;
            align-items: center;
            .templateFileNameWrapper {
                margin-left: rem(12px);
                .templateFileName {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #262626;
                }
                .templateFileType {
                    margin-top: rem(8px);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 100%;
                    color: #262626;
                    opacity: 0.5;
                }
            }
        }
    }

    .uploadFileTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #262626;
        margin-bottom: rem(24px);
    }

    .uploadFileLabel {
        background: rgba(39, 155, 217, 0.1);
        border: 1px solid rgba(39, 155, 217, 0.15);
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 18px 16px;
        cursor: pointer;

        input[type='file'] {
            display: none;
        }

        .uploadFileText {
            color: #1280CE;
            margin-left: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .fileContainer {
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        box-sizing: border-box;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        padding: 16px;
        width: 420px;
        .fileLeft {
            display: flex;
            align-items: center;

            .fileNameWrapper {
                margin-left: 24px;

                .fileName {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    margin-bottom: 8px;
                }

                .fileType {
                    font-size: 14px;
                    line-height: 19px;
                    opacity: 0.5;
                }
            }
        }
    }

    .error {
        color: #FF6973;
    }

    .columnsInfoTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
    }

    .columnsInfo {
        margin-top: rem(24px);
        display: flex;
        column-gap: 24px;
        .column {
            width: 341px;
            display: flex;
            flex-direction: column;

            .infoBlock {
                flex-basis: 24%;
                .rowName {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                }
                .rowText {
                    margin: rem(4px) 0 rem(24px) 0;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 150%;
                    opacity: 0.5;
                }
            }
        }
    }

    .controlsContainer {
        display: flex;
        margin: rem(16px) rem(-24px) 0 rem(-24px);
        gap: rem(16px);
        padding: rem(16px) rem(24px);
        border: 1px solid var(--color-grayBlue);
    }
}
