.base {
    color: #fff;
    font-size: 10px;
    line-height: 10px;
    background-color: #FF6973;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 2px 4px;
    border-radius: 100px;
}
