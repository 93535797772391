@import "src/mixins/index.scss";

.section {
    position: relative;
    background: #FFFFFF;

    .navigationMenu {
        position: absolute;
        top: 24px;
        right: 16px;
    }

    .title {
        display: flex;
        justify-content: space-between;
        width: 100%;

        h1 {
            margin: 0 0 8px 0;
            padding: 25px;
            color: #262626;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
        }
        .navigation {
            display: flex;
            margin-top: 24px;
            margin-right: 24px;

            @media (min-width: 768px) and (max-width: 1024px) {
                margin-right: 48px;
            }

            a {
                margin-right: 20px;
            }

            div {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.sectionMenuWrapper {
    position: relative;
    height: 38px;
    //@include invisible-scroll-wrapper();

    .sectionMenuFogLeft, .sectionMenuFogRight {
        position: absolute;
        top: 0;
        height: 100%;
        width: 20%;
        z-index: 11;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
    }
    .sectionMenuFogLeft {
        left: 0;
        background: linear-gradient(.75turn, transparent, 10%, white);
    }
    .sectionMenuFogRight {
        right: 0;
        background: linear-gradient(.25turn, transparent, 10%, white);
    }
    ul.sectionMenu {
        position: sticky;
        top: 0;
        display: flex;
        background: #FFFFFF;
        border-bottom: 1px solid #EAEDF3;
        z-index: 10;
        @include invisible-scroll();

        #scriptsTab {
            @media (max-width: 1024px) {
                display: none;
            }
        }

        li {
            position: relative;
            padding: 13px 24px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 12px;
            opacity: 0.5;
            cursor: pointer;
            flex-shrink: 0;

            &:hover, &.active {
                color: #1280CE;
                opacity: 1;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #1280CE;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }
}
