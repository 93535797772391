.gray {
  svg path {
    fill: #7B8F9C;
  }
}

.red {
  svg path {
    fill: #FF6973;
  }
}

.blue {
  svg path {
    fill: #1280CE;
  }
}

.green {
  svg path {
    fill: #50B678;
  }
}

.white {
  svg path {
    fill: #fff;
  }
}