@import "src/mixins/index.scss";

$block-location-height: 40px;

.locationChange {
    position: relative;

    .titleBlock {
        font-weight: 600;
        font-size: rem(14px);
        line-height: 150%;
        margin-bottom: 1rem;

        &.required::after {
            content: '*';
            display: inline-block;
            font-weight: 400;
            color: var(--color-red);
        }
    }

    .disabledCheckbox {
        margin-bottom: rem(16px);
    }

    .disabledOverflow {
        background: transparent;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: $block-location-height;
        z-index: 10;
    }

    .blockLocation {
        position: relative;
        min-height: $block-location-height;
        border: 1px solid var(--color-stroke);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        padding: 10px 12px 10px 12px;
        cursor: pointer;

        .path {
            flex-grow: 1;
            padding-right: 32px;
            max-height: 42px;
            overflow: auto;

            .placeholder {
                color: #262626;
                opacity: 0.5;
                user-select: none;
            }
        }
        .iconCross {
            position: absolute;
            top: 14px;
            right: 20px;
        }

        i + i {
            margin-left: .5rem;
        }

        i:hover {
            cursor: pointer;
        }

        i:hover > svg > path {
            //fill: var(--color-whiteHover);
        }

        &.disabled {
            background: #FBFBFD;
            cursor: unset;
        }
    }
}

.locationChange.error {
    .blockLocation {
        border-color: var(--color-red);
    }

    .errorBlock {
        margin-top: rem(4px);
        color: var(--color-red);
        font-size: rem(12px);
        line-height: rem(16px);
    }
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: rem(32px) rem(24px) 0;
    overflow-y: hidden;

    .path {
        margin-bottom: rem(16px);
    }
}

.loading {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    background-color: rgba(255,255,255,0.85);
    user-select: none;
    pointer-events: none;
}
