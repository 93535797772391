@import "src/mixins/index.scss";

.content {
  @include platform-spacing(padding);

  height: calc(100svh - 57px);
  overflow: auto;

  .pageTitle {
    @include header-large;
    margin: 0 0 1rem 0;
  }
}
