@import "src/mixins/index.scss";

.tableWrapper {
    width: 100%;
    padding: 24px 16px;
}

.table {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .activeIcon {
        svg path {
            fill: #1280CE;
        }
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }

    .filter {
        padding: 24px;
        border-bottom: 1px solid #EAEDF3;
    }

    .pagination {
        padding: 16px 24px;
        background: #FFFFFF;
        border-top: 1px solid #EAEDF3;
        border-radius: 0 0 5px 5px;
    }
}

.newsDescWrapper {
    display: flex;

    .newsImage {
        margin-right: 12px;
        width: 100%;
        max-width: 88px;
        height: 56px;
        border-radius: 5px;
    }
}

.titleBlock {
    width: 100%;
    display: flex;
    color: #262626 !important;

    &.mobileTitleBlock {
        flex-direction: column;

        .titleBlockTags {
            margin-bottom: rem(8px);
        }
    }

    .title {
        margin-bottom: 8px;
    }
    &:hover {
        .title {
            color: #1280CE;
        }
    }

    .titleBlockLogo {
        margin-right: 12px;
        min-width: 88px;
        width: 88px;
        height: 56px;
        background: #EAEDF3;
        border-radius: 5px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 5px;
            object-fit: cover;
        }

        &.logoMobile {
            margin-top: rem(8px);
            width: 100%;
            height: 77px;
            img {
                width: 100%;
            }
        }
    }

    .titleBlockTags {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        span {
            margin-right: 6px !important;
            padding: 4px;
            border-radius: 5px;
            font-size: 10px !important;
            font-weight: bold;
            line-height: 10px !important;
            text-transform: uppercase;
            opacity: 1 !important;

            &:last-child {
                margin-right: 0 !important;
            }

            &.yellow {
                background: #FFE05A;
                color: #262626 !important;
            }

            &.blue {
                background: rgba(18, 128, 206, 0.15);
                color: #1280CE !important;
            }
        }
    }

    .title {
        line-height: 14px !important;
    }

    .titleBlockProject {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .titleBlockProjectAvatar {
            display: flex;
            margin-right: 4px;
            width: 14px;
            height: 14px;
            background: #EAEDF3;
            border-radius: 14px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 14px;
                object-fit: cover;
            }
        }

        span {
            margin-top: -1px;
            color: #1280CE !important;
            font-size: 11px !important;
            font-weight: 500;
            line-height: 11px !important;
            opacity: 1 !important;
        }
    }
}

.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}

.mobileDelete {
    position: absolute;
    top: 16px;
    right: 24px;

    @media (min-width: 768px) {
        display: none !important;
    }
}
