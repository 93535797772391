.trainingQuestionAnswerOpen {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 16px;
  width: 100%;
  max-width: 380px;
  background: #FBFBFD;
  border-radius: 5px;

  i {
    margin-right: 8px;
  }
  span {
    color: #262626;
    font-size: 14px;
    line-height: 21px;
  }
}