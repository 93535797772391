@import "src/mixins/index.scss";

.usageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 24px 16px;

  .usageTitle {
    font-size: 22px;
    font-weight: 500;
  }
}

.usageEmpty {
  padding: 16px 24px 24px;
}