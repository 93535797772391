.checkboxWrapper {
  display: flex;
  align-items: center;
  word-break: break-word;

  &:not(:last-of-type) {
    padding-bottom: 20px;
  }

  label {
    margin-right: 8px;
  }
}