.wrapper {
    display: flex;
    width: 100%;

    img {
        margin-right: 12px;
        width: 88px;
        height: 56px;
        border-radius: 5px;
        object-fit: cover;
    }
    .title {
        color: #262626 !important;
        font-size: 14px !important;
        font-weight: 500 !important;

        &:hover {
            color: #1280CE !important;
        }
    }
    .crumbs {
        margin-top: 8px;
    }
    .description {
        margin-top: 4px;
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 16.5px;
        opacity: 0.5;
    }
    @media (max-width: 767px) {
        flex-direction: column-reverse;

        img {
            margin-top: 8px;
            margin-right: 0;
            width: 100%;
            height: 77px;
        }
    }
}
