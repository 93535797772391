@import "src/mixins/index.scss";

.imgModal {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    transform: none !important;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        padding: 24px;
        align-items: center;
        background-color: rgba(26, 26, 26, 0.9);

        .text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            align-self: flex-end;
        }
    }

    .content {
        display: flex;
        padding: 24px 0 0;
        width: 100%;
        height: 100%;
        overflow: auto;

        img {
            display: block;
            margin: auto;
        }
    }

    .modalFooter {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#000, 0.4);
        z-index: 1;

        button {
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 9999px;
            padding: 5px;

            i {
                margin-bottom: 0;
            }

            &.disabled {
                border: 1px solid #7b8f9c;
                cursor: not-allowed;
            }
        }

        .sizeValue {
            color: #fff;
            padding-left: rem(16px);
            padding-right: rem(16px);
        }
    }
}

.termModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.65);
    z-index: 10;

    .termModal {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        width: 100%;
        max-width: 570px;
        background: #ffffff;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        transform: none !important;

        .termModalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 24px 0 24px;

            h2 {
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .termModalBody {
            padding: 24px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
        }
    }
}
