.trainingQuestionAnswerMany {
  margin-top: 24px;
  margin-bottom: 8px;

  .trainingQuestionAnswerManyItem {
    display: flex;
    flex-direction: column;

    .trainingQuestionAnswerManyItemContainer {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      i:first-child {
        margin-right: 16px;
      }
      i:last-child {
        margin-left: 16px;
      }
    }
    label {
      margin-left: 48px;
    }
  }
  .trainingQuestionAnswerManyAdd {
    display: flex;
    align-items: center;
    cursor: pointer;

    .trainingQuestionAnswerManyAddIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      background: #1280CE;
      border-radius: 100px;
    }
    span {
      color: #1280CE;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}