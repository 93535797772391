.trainingTestViewUsers {
  padding: 24px 24px 0 24px;

  .subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
  }
  .trainingTestViewUsersUser {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    span {
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      opacity: 1;
    }
  }
}
@media (max-width: 768px) {
  .trainingTestViewUsers {
    padding: 12px;
  }
}