@import "src/mixins/index.scss";

.container {
  position: relative;
  width: 100%;
  min-height: 200px;

  .filter {
    position: absolute;
    top: -5px;
    right: 0;
  }
  .items {
    display: flex;
    flex-direction: column;

    .item {
      &:last-child {
        margin-bottom: 0;
      }
      h3 {
        margin-bottom: 24px;
        margin-left: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
      }
      .users {
        .user {
          margin-bottom: 16px;
          @include platform-spacing(padding);
          background: #FFFFFF;
          border: 1px solid #EAEDF3;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

          &:last-child {
            margin-bottom: 24px;
          }
          .head {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .avatar {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              background: #ABC9D7;
              background-size: cover;
              border-radius: 32px;
              overflow: hidden;

              img {
                width: 100%;
                height: auto;
              }
            }
            a {
              color: #1280CE;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }
          }
          .records {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-left: 40px;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 15.5px;
              height: 100%;
              width: 1px;
              background: #EAEDF3;
            }
            .record {
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
              .recordTime {
                margin-right: 16px;
                min-width: 36px;
                color: #262626;
                font-size: 14px;
                line-height: 21px;
                opacity: 0.5;
              }
              p {
                color: #262626;
                font-size: 14px;
                line-height: 21px;
                word-break: break-word;
              }
              span {
                color: #262626;
                font-size: 14px;
                line-height: 21px;
                word-break: break-word;
              }
              a {
                color: #1280CE;
                font-size: 14px;
                line-height: 21px;
                word-break: break-word;
                font-weight: 500;
              }
            }
          }
          .body {
            display: flex;
            padding-top: 20px;

            span {
              @include text-small;
              color: #9F9F9F;
            }
            .separator {
              margin: -20px 12px 0 10px;
              width: 2px;
              height: 50px;
              background: #E7E7E7;

              &:before {
                content: "";
                display: block;
                margin-top: 23px;
                margin-left: -3px;
                width: 8px;
                height: 8px;
                background: #EDF1F5;
                border-radius: 8px;
              }
            }
            p {
              @include text-small;
              color: #9F9F9F;

              a {
                color: #279BD9;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
