@import "src/mixins/index.scss";

.node {
  width: 100%;
  padding: rem(14px) rem(16px);
  height: rem(48px);
  align-items: center;
  border-left: 2px solid transparent;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #EAEDF3;

  &.selected {
    background: rgba(18, 128, 206, 0.05);
    .name {
      color: var(--color-blue);
    }
  }

  .checkbox {
    margin-right: rem(12px);
  }

  .img {
    border-radius: 50%;
  }

  .name {
    font-size: 14px;
    line-height: 19px;
    color: #262626;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}