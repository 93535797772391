.trainingQuestionAnswerNext {
  margin-top: 24px;
  margin-bottom: 8px;

  .trainingQuestionAnswerNextItem {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    i {
      margin-top: 16px;
      margin-left: 16px;
    }
  }
  .trainingQuestionAnswerNextAdd {
    display: flex;
    align-items: center;
    cursor: pointer;

    .trainingQuestionAnswerNextAddIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      background: #1280CE;
      border-radius: 100px;
    }
    span {
      color: #1280CE;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}