@import 'src/mixins/index.scss';

.preview {
    max-height: calc(100vh - 2rem);
    overflow: hidden;
    min-width: 70vw;
    width: 70vw;
    max-width: 70vw;
    transition: min-width ease-out 0.1s;

    &.expand {
        min-width: 99vw;
        transition: min-width ease-out 0.1s;
    }

    .menu {
        display: flex;
        width: 100%;
        padding: 1rem 1.5rem;
        height: 73px;
        border-bottom: 1px solid #eaedf3;
        background-color: #fff;

        .buttonIcon {
            button {
                display: flex;
                padding-top: 0;
                padding-bottom: 0;
                align-items: center;
                height: 40px;

                i {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .header {
        display: flex;
        width: 100%;
        height: 89px;
        padding: 0 1.5rem;
        align-items: center;

        .title {
            @include header-large;
            font-weight: 600;
            flex-grow: 1;
        }
    }

    .content {
        height: calc(100vh - 194px);
        overflow: auto;

        .desc {
            padding: 1.5rem;
            border-bottom: 1px solid #eaedf3;
        }

        .attachments {
            margin: 0 1.5rem;

            div:first-child {
                height: 1px;
                background-color: var(--color-grayBlue);
                margin: 2rem 0;
            }

            div:nth-child(2) {
                @include text-normal;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 1rem;
            }
        }

        .attachmentsBlock {
            margin: 0 1.5rem;
        }

        .cover {
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .contentHtml {
            @include content;
        }
    }
}
