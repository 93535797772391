.tiptap {
    font-size: 16px;
}

.tiptap * {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-decoration-skip-ink: none;
}

.tiptap {
    padding: 10px 30px;
    cursor: text;
    outline: none;
}

.tiptap > *:first-child {
    margin-top: 0;
}

.tiptap > *:last-child {
    margin-bottom: 0;
}

.tiptap * {
    font-synthesis: weight style;
}

.tiptap .tableContainer {
    position: relative;
    max-width: 100%;
    width: 100%;
}

.tiptap .tableOuterWrapper {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.tiptap .tableWrapper {
    width: 100%;
    overflow: auto;
}

.tiptap table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    overflow: hidden;
    min-width: 137px;
    cursor: text;
}

.tiptap table[data-align='right'] {
    margin-left: auto;
}

.tiptap table[data-align='center'] {
    margin-left: auto;
    margin-right: auto;
}

.tiptap table[data-border='0'] {
    td,
    th {
        border-style: dotted;
        border-color: rgba(0, 0, 0, 0.1) !important;
    }
}

.tiptap hr {
    display: block;
    height: 5px;
    border: 0;
    border-top: 1.5px solid rgba(39, 155, 217, 0.35);
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tiptap table tbody tr:first-of-type {
    position: sticky !important;
    top: 0;
}

.tiptap table td,
.tiptap table th {
    box-sizing: border-box;
    min-width: 45px;
    height: 40px;
    position: relative;
}

.tiptap table * {
    word-break: normal;
}

.tiptap table tr:first-of-type td,
.tiptap table tr:first-of-type th {
    border-top-width: 1px;
}
.tiptap table tr td:first-of-type,
.tiptap table tr th:first-of-type {
    border-left-width: 1px;
}
.tiptap table tr td,
.tiptap table tr th {
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    padding: 4px 12px 4px 8px;
}

/* .tiptap table tr td .details,
.tiptap table tr th .details {
    margin-top: 0;
} */

.tiptap table td > *:not(ul):not(ol),
.tiptap table th > *:not(ul):not(ol) {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}
.tiptap ul,
.tiptap ol {
    padding-left: 40px !important;
}

.tiptap table th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
}

.tiptap table img {
    max-width: 100% !important;
}

.tiptap strong {
    font-weight: bold;
}

.tiptap em {
    font-style: italic;
}

.tiptap sub {
    vertical-align: sub;
    font-size: smaller;
}

.tiptap sup {
    vertical-align: super;
    font-size: smaller;
}

.tiptap p {
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.5;
}

.tiptap h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
}
.tiptap h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
}
.tiptap h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
}

.tiptap table .selectedCell:after {
    background: rgba(39, 155, 217, 0.4);
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}

.tiptap table .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    top: 0;
    width: 4px;
    cursor: col-resize;
    z-index: 10;
}

.tiptap table p {
    margin: 0;
    padding: 0 12px 0 8px;
}

.tiptap ol,
.tiptap ul {
    padding-left: 18px;
}

.tiptap ol {
    list-style-type: decimal;
}

.tiptap ul {
    list-style-type: disc;
}

.tiptap ul ul {
    list-style-type: circle;
}

.tiptap ul ul ul {
    list-style-type: square;
}

.tiptap li {
    margin-top: 1em;
    margin-bottom: 1em;
}

.tiptap li.list-right {
    text-align: right;
}
.tiptap li.list-left {
    text-align-last: left;
}
.tiptap li.list-center {
    text-align: center;
}
.tiptap li.list-justify {
    text-align: justify;
}

.tiptap li > p {
    margin: 0;
}
.tiptap .tableActionsWrapper {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: grab;
}

.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}

.chrome-picker {
    box-shadow: none !important;
}

.chrome-picker > div:first-of-type {
    width: 160px;
    height: 160px;
}

@media (max-width: 1024px) {
    .chrome-picker > div:first-of-type {
        padding: 0 !important;
        height: 100px !important;
    }
    .chrome-picker > div:last-of-type > div:last-of-type > div:first-of-type div div {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        label {
            margin-top: 0 !important;
            margin-right: 12px !important;
        }
    }
    .chrome-picker > div:last-of-type > div:last-of-type > div:last-of-type > div {
        margin-top: 0 !important;
    }
}

.tiptap div[data-node-type='remote_diagram'][data-layout='left'] {
    justify-content: flex-start;
}
.tiptap div[data-node-type='remote_diagram'][data-layout='center'] {
    justify-content: center;
}
.tiptap div[data-node-type='remote_diagram'][data-layout='right'] {
    justify-content: flex-end;
}

.tiptap .callout {
    padding: 12px 6px;
    display: flex;
    align-items: flex-start;
    gap: 9px;
    line-height: 21px;
    margin: 18px 0;
    border-radius: 2px;
    width: 100%;
}
.tiptap .callout > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 20px;
}

.tiptap .callout > div {
    width: 100%;
    overflow: hidden;
}
.tiptap .callout > div > p {
    padding-left: 1px;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-word;
    word-wrap: break-word;
    white-space: break-spaces;
    line-height: 150%;
}
.tiptap td .callout > div > p {
    word-break: inherit;
}

.tiptap .aquablock {
    background-color: #e8f7fb;
}
.tiptap .yellowblock {
    background-color: #FFF8D7;
}
.tiptap .redblock {
    background-color: #FDEBE9;
}
.tiptap .blueblock {
    background-color: #EBF5FF;
}
.tiptap .pirpleblock {
    background-color: #F4EEFF;
}
.tiptap .greenblock {
    background-color: #F2FFEE;
}

.tiptap blockquote {
    padding-top: 2px;
    padding-bottom: 9px;
    padding-left: 12px;
    border-left: 3px solid rgba(39, 155, 217, 0.2);
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: normal;
    font-style: italic;
}
.tiptap blockquote p {
    padding: 0;
    margin: 0;
    line-height: 21px;
}

.tiptap .columnBlock {
    margin-top: 10px;
    margin-bottom: 10px;
}

.tiptap .columnBlock .column {
    padding: 6px;
    min-height: 32px;
    border: 1px solid rgba(51, 139, 194, 0.5);
    border-radius: 5px;
    overflow: auto;
}

.tiptap .columnBlock .column p:first-child {
    margin-top: 0;
}
.tiptap .columnBlock .column p:last-child {
    margin-bottom: 0;
}
/* Extension Resizable Media Start */

.media-node-view {
    margin-top: 18px;
    margin-bottom: 18px;
    position: relative;
    display: flex;
}
.media-node-view img {
    cursor: pointer;
    flex-shrink: 0;
}
.media-node-view.f-left {
    float: left;
}

.media-node-view.f-right {
    float: right;
}

.media-node-view a {
    display: flex;
}

.tiptap .align-left {
    justify-content: flex-start;
}

.tiptap .align-center {
    justify-content: center;
}

.tiptap .align-right {
    justify-content: flex-end;
}

.media-node-view .horizontal-resize-handle,
.media-node-view .vertical-resize-handle {
    position: absolute;
    z-index: 50;
    opacity: 0.5;
}

.media-node-view .horizontal-resize-handle:hover,
.media-node-view .vertical-resize-handle:hover {
    background: red;
}

.media-node-view .horizontal-resize-handle {
    width: 100%;
    width: 4px;
    top: 0;
    right: 0;
    cursor: col-resize;
}

.media-node-view .vertical-resize-handle {
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    cursor: row-resize;
}

.image-actions-container {
    display: flex;
    gap: 2px;
    padding: 4px;
    border-radius: 3px;
    border: 1px solid #eaedf3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.media-node-view .media-container {
    display: flex;
    width: fit-content;
    position: relative;
}

.image-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 2px;
    border: 1px solid #eaedf3;
    border-radius: 3px;
}

.media-actions-container {
    padding: 4px !important;
    width: fit-content !important;
}
.media-actions-container .ep-button + .ep-button {
    margin-left: 0px;
}

/* Extension Resizable Media End */

.search-result {
    background-color: yellow;
}

.table-of-contents {
    border-radius: 5px;
    padding: 17px 12px;
    background-color: rgba(39, 155, 217, 0.1);
    margin-bottom: 1rem;
    margin-top: 1rem;
    cursor: default;
}

.table-of-contents > ul {
    padding-left: 0;
    list-style-position: outside;
    pointer-events: none;
    cursor: default;
}

.table-of-contents li.h2 {
    margin-left: 24px;
}
.table-of-contents li.h3 {
    margin-left: 48px;
}
.table-of-contents li.h4 {
    margin-left: 72px;
}
.table-of-contents li.h5 {
    margin-left: 96px;
}
.table-of-contents li.h6 {
    margin-left: 120px;
}

.table-of-contents li p {
    margin-top: 6px;
    margin-bottom: 6px;
}
.table-of-contents li {
    list-style-type: disc;
    pointer-events: all;
    cursor: pointer;
}
.table-of-contents li:not(.h1) p {
    position: relative;
    left: -10px;
}

.table-of-contents li.h1 {
    list-style-type: none;
}

.table-of-contents li {
    cursor: pointer;
}

.tiptap {
    line-height: 1;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 14px;
    margin-bottom: 14px;
}

.h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
}

.h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

.h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
}

.h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.h5 {
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    text-decoration: underline;
}

.h6 {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    color: rgba(113, 112, 112, 1);
}

.tiptap b {
    font-weight: 600;
}

.tiptap div[data-youtube-video] {
    display: flex;
    overflow: scroll;
    background-color: rgba(39, 155, 217, 0.1);
    padding: 20px;
    border-radius: 5px;
    margin-top: 21px;
}

.tiptap div[data-align='left'],
.tiptap div[data-align='center'],
.tiptap div[data-align='right'] {
    display: flex;
}

.tiptap div[data-align='left'] {
    justify-content: flex-start;
}

.tiptap div[data-align='center'] {
    justify-content: center;
}

.tiptap div[data-align='right'] {
    justify-content: flex-end;
}
.ProseMirror:not(.dragging) .ProseMirror-selectednode {
    outline: none !important;
    border-radius: 0.2rem;
    transition: background-color 0.2s;
    box-shadow: none;
}

.noneDrag .drag-handle {
    display: none;
}

.drag-handle {
    position: absolute;
    top: 0;
    opacity: 1;
    left: 0;
    will-change: transform;
    transition-property: all;
    transition-timing-function: ease;
    transition-duration: 100ms;
    cursor: grab;
    padding: 4px;
    border-radius: 4px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(51, 139, 194, 1)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
    background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
    background-repeat: no-repeat;
    background-position: center;
    width: 1.2rem;
    height: 1.5rem;
    z-index: 50;
}

.drag-handle.grabing {
    cursor: grabbing;
}

.editor-tooltip {
    position: absolute;
    width: max-content;
    max-width: 400px;
    word-break: break-all;
    display: flex;
    padding: 4px 8px;
    font-size: 13px;
    line-height: 19.5px;
    background-color: rgba(38, 38, 38, 0.8) !important;
    color: #fff;
    border-radius: 4px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100%);
        border-width: 6px 6px 0;
        border-color: transparent;
        border-top-color: initial;
        border-style: solid;
        color: rgba(38, 38, 38, 0.8);
    }
}

.editor-tooltip.left {
    &::after {
        left: 8px;
    }
}

.editor-tooltip.right {
    &::after {
        right: 8px;
    }
}

.hidden {
    opacity: 0;
}

.editor--fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    border-radius: 0 !important;
}

.editor--fullscreen .editor-menu {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    flex-wrap: wrap;
    border-radius: 0 !important;
}

.editor--fullscreen .editor-menu + div {
    height: 100%;
}
.editor--fullscreen .editor-content {
    height: 100%;
}

.tippy-box[data-theme='editor-button'] {
    white-space: nowrap;
}

.sub-menu .tippy-box[data-theme='editor-button'] {
    display: none;
}

.editor--fullscreen .editor-menu > div {
    max-width: 1242px;
}

.editor--fullscreen .editor-menu .editor-button {
    margin-right: 8px;
    margin-left: 8px;
    min-width: 30px !important;
    justify-content: center !important;

    @media (max-width: 1024px) {
        margin-right: 0;

        & + i {
            margin-left: -6px;
        }
    }
}

.editor--fullscreen .editor-menu .editor-button-label {
    display: none;
}

.editor--fullscreen .sub-menu .editor-button-label {
    display: flex;
}

.editor--fullscreen.editor--fullscreen--not--desktop .editor-menu .editor-button-label {
    display: block;
}

.editor--fullscreen .editor-content {
    flex-grow: 1;
    background-color: #fff;
}
/*
.editor--fullscreen .editor-content-wrapper {
    height: 100% !important;
} */

.tiptap .node-imageComponent {
    position: relative;
}

.tiptap figure {
    max-width: 100%;
    position: relative;
    margin-top: 18px;
    margin-bottom: 18px;
    user-select: none;
    -moz-user-select: none;
}

.tiptap figure img {
    max-width: 100%;
    margin-bottom: 0;
}

.tiptap figure img[data-keep-ratio]:not([data-keep-ratio='false']) {
    object-fit: contain;
}

.tiptap figure img[data-keep-ratio='false'] {
    object-fit: fill;
}

.tiptap figure .ghost {
    position: absolute;
    top: 0;
}

.tiptap figure .ghost > img {
    opacity: 0.3;
}

.tiptap p:has(span.node-imageComponent) {
    display: flex;
    flex-wrap: wrap;
}

/* these styles are required for aligning the image */
.tiptap p:has(span.node-imageComponent)[style*='text-align: center'] {
    justify-content: center;
}

.tiptap p:has(span.node-imageComponent)[style*='text-align: right'] {
    justify-content: flex-end;
}

.tiptap p:has(span.node-imageComponent)[style*='text-align: left'] {
    justify-content: flex-start;
}

.tiptap p:has(span.node-imageComponent)[style*='text-align: justify'] {
    justify-content: space-between;
}
.Popover {
    width: max-content;
    max-width: calc(100vw - 20px);
    background-color: white;
    border: 1px solid #ddd;
    font-size: 90%;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: left;
}

.Popover textarea {
    max-width: 100%;
}

.editor-content .tippy-box {
    background: #fff !important;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    text-align: left;
    font-size: 0.8rem;
    color: inherit;
}

.tippy-box[data-theme='listItems'],
.tippy-box[data-theme='table'] {
    background: transparent;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 5px;
    padding: 0;
    text-align: left;
    font-size: 0.8rem;
    color: inherit;
}

.tippy-box[data-theme='tableActionButtons'] {
    background: transparent;
    box-shadow: none;
    padding: 0;
}

.tippy-box[data-theme='tableCell'] {
    background: transparent !important;
    box-shadow: none;
    padding: 0;
}

@media (max-width: 768px) {
    .tippy-box[data-theme='tableCell'] {
        background: #fff;
    }
}

.tippy-box[data-theme='listItems'] .tippy-content {
    padding: 0;
}

.editor-content .tippy-content {
    padding: 0;
}

.drag-block-wrapper {
    display: flex;
    gap: 6px;
    width: 100%;
    position: relative;
}

.drag-block-section {
    display: flex;
    padding-top: 4px;
    align-items: flex-start;
}

.drag-block-wrapper .drag-button {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: -webkit-grab;
    cursor: grab;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    opacity: 0;
    width: 18px;
    height: 18px;
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter,
        -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.drag-block-wrapper:hover .drag-button {
    opacity: 1;
}
.drag-block-wrapper .drag-button:hover {
    background-color: rgba(39, 155, 217, 0.1);
}

.dropdown {
    background-color: #fff;
    z-index: 50;
    padding: 12px;
    border-radius: 4px;
}

.dropdown .dropdown-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.dropdown .dropdown-content .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    line-height: 21px;
    margin: 0;
    outline: 0;
    padding: 6px;
}

.dropdown .dropdown-content .button:hover {
    color: rgb(51, 139, 194);
    background-color: rgba(39, 155, 217, 0.1);
}

.trigger-button {
    width: 12px;
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
@media (max-width: 1024px) {
    .trigger-button {
        width: 30px;
        height: 30px;
        box-shadow: 0 8px 20px 0 #0000001f;
    }
}

.ScrollbarsCustom-TrackY {
    width: 3px !important;
    background-color: transparent !important;
}

.ScrollbarsCustom-ThumbY {
    background-color: rgba(79, 89, 96, 0.3) !important;
    border-radius: 100px !important;
}

@media (max-width: 1024px) {
    .ScrollbarsCustom {
        width: 100% !important;
        height: 281px !important;

        .ScrollbarsCustom-Content {
            padding-bottom: 41px !important;
        }
    }
}

/*.ProseMirror.callout-active section.callout + p {*/
/*    height: 1px;*/
/*}*/

i.disabled svg path {
    fill: #262626;
    fill-opacity: 0.5;
}

.tiptap .ProseMirror-trailingBreak {
    height: 0;
}
.tiarm {
    position: relative;
    display: inline-block;
    background: #eaedf3;
    border: 1px solid #7b8f9c;
    border-radius: 3px;
    transition: all 0.3s ease;
    padding-left: 1px;
    padding-right: 1px;
    cursor: pointer;
}
.tiarm:hover {
    background: rgba(18, 128, 206, 0.25);
    border: 1px solid #1280ce;
}
