.news {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    * {
        word-break: break-word;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }

    .top {
        position: relative;

        .status {
            position: absolute;
            top: 8px;
            left: 8px;
            padding: 4px;
            min-width: 110px;
            border-radius: 5px;
            color: #262626;
            font-size: 10px;
            font-weight: bold;
            line-height: 10px;
            text-align: center;
            text-transform: uppercase;

            &.gray {
                background: #D4D4D4;
            }

            &.yellow {
                background: #FFE05A;
            }

            &.red {
                background: #E06169;
            }
        }

        .image {
            height: 128px;
            background-size: cover;
            border-radius: 4px 4px 0 0;
            overflow: hidden;
            cursor: pointer;
        }

        .company {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding: 4px 8px;
            background: #FFFFFF;
            border-radius: 0 5px 0 0;

            .image {
                margin-right: 8px;
                min-width: 18px;
                width: 18px;
                min-height: 18px;
                height: 18px;
                background-size: cover;
                border-radius: 18px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .projectTitle {
                height: 19px;
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 19px;
                overflow: hidden;
            }
        }
    }

    .body {
        padding: 16px 24px 24px 24px;

        .title {
            margin: 0 0 24px 0;
            height: 48px;
            color: #262626;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            cursor: pointer;
        }

        .description {
            margin-bottom: 24px;
            height: 64px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            opacity: 0.8;
            overflow: hidden;
            cursor: pointer;
        }

        .author {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            // margin-top: 24px;

            .container {
                display: flex;

                .image {
                    margin-right: 8px;
                    width: 40px;
                    height: 40px;
                    background-size: cover;
                    border-radius: 40px;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    p {
                        color: #262626;
                        font-size: 13px;
                        font-weight: normal;
                        line-height: 19px;
                        opacity: 0.5;
                    }

                    a {
                        color: #1280CE;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;
                    }
                }
            }

            span {
                padding-bottom: 2px;
                color: #262626;
                font-size: 13px;
                font-weight: normal;
                line-height: 19px;
                opacity: 0.5;
            }
        }

        .highPriority {
            display: flex;
            flex-direction: row;
            padding: 4px;
            position: absolute;
            height: 18px;
            left: 8px;
            top: 8px;
            background: #FFE05A;
            border-radius: 5px;
            font-weight: bold;
            font-size: 10px;
            line-height: 10px;
            color: #262626;
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 24px;
        border-top: 1px solid #EAEDF3;

        @media (max-width: 1024px) {
            display: none;
        }
        .container {
            button {
                margin-right: 22px;
            }

            .favorite {
                svg path {
                    fill: #1280ce;
                }
            }
        }
    }
}
