@import "src/mixins/index.scss";

.glossaryModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;

  .glossaryModal {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    max-width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transform: none !important;

    .glossaryModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h2 {
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .glossaryModalBody {
      padding: 32px;

      .glossaryModalBodyRow {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        &.glossaryModalBodyRowError {
          .reactTagsInput {
            border: 1px solid #FF6973 !important;
          }
          label {
            color: #FF6973;
          }
          span {
            color: #FF6973;
          }
        }
        .reactTagsInput {
          .reactTagsInputTag {
            display: inline-flex;
            align-items: center;
            margin: 0 6px 6px 0;
            padding: 7px 6px;
            background: #F5F5F5;
            border: none;
            border-radius: 3px;

            span {
              margin: 0;
              color: #262626;
              font-size: 13px;
              font-weight: 500;
              line-height: 14px;
            }
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 1px;
              margin-left: 6px;
              width: 14px;
              height: 14px;
              background: #1280CE;
              border-radius: 14px;
              cursor: pointer;

              svg path {
                fill: #FFFFFF;
              }
            }
          }
        }
        label {
          margin-bottom: 4px;
          color: rgba(38, 38, 38, 0.75);
          font-size: 13px;
          line-height: 18px;
        }
        span {
          margin-top: 4px;
          color: rgba(38, 38, 38, 0.5);
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    .glossaryModalFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;

      div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .glossaryImportModal {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    max-width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transform: none !important;

    .glossaryImportModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h2 {
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .glossaryImportModalBody {
      padding: 32px;

      .glossaryImportModalBodyStage0 {
        display: flex;
        flex-direction: column;

        .glossaryImportModalBodyDropzone {
          width: 100%;
          height: 180px;
          background: #FFFFFF;
          border: 1px dashed #EAEDF3;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          cursor: pointer;

          & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            p {
              margin-top: 8px;
              width: 100%;
              max-width: 320px;
              font-size: 13px;
              line-height: 150%;
              color: rgba(38, 38, 38, 0.55);
              text-align: center;
            }
          }
        }
        .glossaryImportModalBodySeparator {
          margin: 24px 0;
          width: 100%;
          height: 1px;
          background: #EAEDF3;
        }
        .glossaryImportModalBodySeparator + p {
          margin-bottom: 16px;
          color: #262626;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
        .glossaryImportModalBodyTemplate {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          background: #FBFBFD;
          border-radius: 5px;

          .glossaryImportModalBodyTemplateContainer {
            display: flex;
            align-items: center;

            div {
              display: flex;
              flex-direction: column;
              margin-left: 12px;

              p {
                margin-bottom: 8px;
                color: #262626;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
              }
              span {
                color: rgba(38, 38, 38, 0.5);
                font-size: 13px;
                line-height: 100%;
              }
            }
          }
          button svg path {
            fill: #1280CE;
          }
        }
      }
      .glossaryImportModalBodyStage1 {
        display: flex;

        & > div {
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          width: 100%;

          p {
            margin-bottom: 8px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
          }
          span {
            margin-top: 8px;
            color: rgba(38, 38, 38, 0.5);
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
      .glossaryImportModalBodyStage2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin-top: 32px;
          text-align: center;
        }
      }
    }
    .glossaryImportModalFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;
    }
  }
  .glossaryExportModal {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    max-width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transform: none !important;

    .glossaryExportModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h2 {
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .glossaryExportModalBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;

      p {
        margin-top: 32px;
        text-align: center;
      }
    }
    .glossaryExportModalFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;
    }
  }
}

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);
}

.title {
  @include header-large;
  width: 100%;
  padding-bottom: rem(24px);
}

.tableColumnTitle {
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 8px;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }
  span {
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px;
    line-height: 150%;
  }
}
