@import "src/mixins/index.scss";

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);
}

.title {
  @include header-large;
  width: 100%;
  padding-bottom: rem(24px);
}

.table {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .filter {
    @include platform-spacing(padding);
    border-bottom: 1px solid #EAEDF3;
  }


  .pagination {
    padding: 16px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-radius: 0 0 5px 5px;
  }

  .roleTitle {
    width: 100%;
    color: #262626 !important;

    &:hover {
      color: #1280CE !important;
    }
  }
}

.subheader {
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #262626;
  opacity: 0.5;
  margin-bottom: rem(6px);
}