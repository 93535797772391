@import "src/mixins/index.scss";

label.uploadLabel input[type="file"] {
  position: absolute;
  top: -1000px;
}

.base {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: rem(24px);
  height: 100%;

  .uploadTitle {
    @include header-large;
    width: 100%;
    padding-bottom: rem(24px);
  }
}

.dropZone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px dashed #EAEDF3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  cursor: pointer;
}
.mobileView {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    @media (max-width: 1024px) {
        display: flex;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        background: #E5F3FC;
        border-radius: 54px;

        svg {
            width: 24px;
            height: 24px;

            path {
                fill: #7B8F9C;
            }
        }
    }
    p {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
    }
}
.desktopView {
    display: none;

    @media (min-width: 1025px) {
        display: flex;
    }
}
