.lockOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;
}

.lockConflictModal {
  position: relative;
  width: 100%;
  max-width: 770px;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .lockConflictModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;

    h3 {
      margin-bottom: 0;
      color: #262626;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .lockConflictModalBody {
    position: relative;
    padding: 32px;

    .lockConflictModalBodyDescription {
      margin-bottom: 24px;
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    .lockConflictModalBodyItem {
      margin-bottom: 24px;
    }
  }

  .lockConflictModalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: 1px solid #EAEDF3;

    div:first-child {
      margin-right: 16px;
    }
  }
}

.lock {
  position: relative;
  width: 100%;
  max-width: 570px;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .roles {
    width: 100%;
    max-width: 170px;

    input {
      opacity: 0 !important;
    }

    & > div {
      & > div:first-of-type {
        min-height: 20px;
        height: 20px;
        border: none;
        box-shadow: none !important;
        cursor: pointer;

        & > div {
          justify-content: flex-end;
          padding: 0;

          & > div {
            margin: 0;
            color: #262626;
            font-size: 13px;
            font-weight: normal;
            line-height: 20px;
            text-align: right;

            & + div {
              padding: 0;
            }
          }

          & + div {
            div {
              padding: 0;
              margin-left: 6px;
              color: #7B8F9C;

              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      & > div:nth-child(3) {
        top: calc(100% + 15px);
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        & > div {
          padding: 0;

          div {
            cursor: pointer;
          }

          div:first-child {
            border-radius: 5px 5px 0 0;
          }

          div:last-child {
            border-radius: 0 0 5px 5px;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #EAEDF3;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h3 {
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .bottom {
      display: flex;
      padding: 32px 32px 0 32px;

      .search {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 16px;
        padding: 0 12px;
        width: 100%;
        max-width: 370px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;

        input[type=text] {
          width: 100%;
          height: 20px;
          border: none;
          color: #262626;
          font-size: 14px;
          line-height: 19px;

          &::placeholder {
            color: #262626;
            opacity: 0.5;
          }
        }

        .roles {
          & > div {
            & > div:first-of-type {
              border: none !important;

              & > div {
                & > div {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .resultUser {
          display: flex;
          align-items: center;
          padding: 6px;
          background: #F5F5F5;
          border-radius: 100px;

          .avatar {
            margin-right: 6px;
            width: 16px;
            height: 16px;
            background: #EAEDF3;
            border-radius: 100px;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
            }

            img {
              width: 100%;
              object-fit: cover;
            }
          }

          p {
            margin-right: 6px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 14px;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 14px;
            height: 14px;
            background: #1280CE;
            border: none;
            border-radius: 100px;

            svg {
              margin-right: 0;
              width: 6px;
              height: 6px;

              path {
                fill: #FFFFFF;
              }
            }
          }
        }

        .searchResult {
          position: absolute;
          top: 46px;
          left: 0;
          width: 100%;
          height: 275px;
          background: #FFFFFF;
          border: 1px solid #E8E8E8;
          border-radius: 5px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          overflow: auto;
          z-index: 1;

          .title {
            padding: 20px 8px 10px;
            font-weight: 600;
          }

          li {
            display: flex;
            align-items: center;
            padding: 12px;
            cursor: pointer;

            &:first-child {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &:last-child {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            &:hover {
              background: #1280CE;

              div {
                p, span {
                  color: #FFFFFF;
                }

                span {
                  opacity: 1;
                }
              }
            }

            .avatar {
              margin-right: 8px;
              width: 24px;
              height: 24px;
              background: #EAEDF3;
              border-radius: 100px;

              img {
                width: 100%;
                object-fit: cover;
              }
            }

            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              p {
                color: #262626;
                font-size: 13px;
                line-height: 20px;

                b {
                  font-weight: bolder;
                }
              }

              span {
                color: #262626;
                font-size: 10px;
                line-height: 15px;
                opacity: 0.5;

                b {
                  font-weight: bolder;
                }
              }
            }
          }
        }
      }

      .button {
        width: 100%;
        max-width: 120px;
      }
    }

    .warning {
      padding: 4px 32px 16px 32px;
      color: #FF6973;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
    }
  }

  .body {
    position: relative;
    padding: 16px 24px;
    height: calc(100vh - 368px);
    overflow: auto;

    .lockLoader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      z-index: 10;
    }

    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 0 52px 0;

      img {
        margin-bottom: 24px;
      }

      h4 {
        margin-bottom: 4px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: center;
      }

      p {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        opacity: 0.65;
      }
    }

    .filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 18px;

      .searchDefault {
        display: flex;
        align-items: center;
        width: 50%;
        cursor: pointer;

        i {
          margin-right: 8px;

          svg path {
            fill: #1280CE;
          }
        }

        span {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
        }
      }

      .search {
        position: relative;

        i {
          position: absolute;
          top: 14px;
          left: 14px;

          svg path {
            fill: #1280CE;
          }
        }

        input {
          padding-left: 34px;
          height: 40px;
          border: 2px solid #1280CE;
          border-radius: 5px;
          color: #262626;
          font-size: 14px;
          line-height: 19px;
        }
      }

      .sort {
        display: flex;
        align-items: center;

        span {
          margin-right: 4px;
          color: #262626;
          font-size: 13px;
          line-height: 13px;
          opacity: 0.5;
        }

        .sortSeparator {
          width: 1px;
          height: 20px;
          background: #EAEDF3;
        }

        & > div {
          width: auto;
          max-width: none;

          & > div {
            & > div:first-of-type {
              min-height: inherit;
              border: none !important;
              cursor: pointer;

              & > div:first-child {
                & > div {
                  display: contents;
                  color: #1280CE;

                  input {
                    pointer-events: none;
                  }
                }
              }

              & > div:nth-child(2) {
                display: none;
              }
            }
          }
        }
      }
    }

    .searchEmpty {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;

      i {
        margin-bottom: 12px;

        svg path {
          fill: #7B8F9C;
        }
      }

      p {
        color: #262626;
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        opacity: 0.5;
      }
    }

    .groups {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      h4 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .group {
        &:hover, &.groupOpen {
          .row {
            background: rgba(18, 128, 206, 0.05);
            border-radius: 5px;

            .left {
              .arrow {
                transform: rotate(180deg);
              }

              p {
                color: #1280CE;
              }
            }

            .right > div > div > div {
              background: transparent;
              transition: none;
            }

            .right > div > div > div + div {
              background: #FFFFFF;
            }
          }
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 8px;
          cursor: pointer;

          .left {
            display: flex;
            align-items: center;

            .arrow {
              transform: rotate(90deg);
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 8px 0 20px;
              width: 24px;
              height: 24px;
              background: #EAEDF3;
              border-radius: 24px;
            }

            p {
              color: #262626;
              font-size: 14px;
              font-weight: 500;
              line-height: 13px;
            }
          }

          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 50%;

            & > div:first-child {
              & > div > div:nth-child(2) {
                background: transparent;
                border: none !important;
              }

              &.disabled > div > div > div:nth-child(2) {
                display: none;
              }
            }

            .separator {
              margin: 0 16px;
              width: 1px;
              height: 32px;
              background: #EAEDF3;
              border-radius: 1px;
            }

            .treeIcon {
              margin-right: 16px;
            }

            .treeIcon:hover {
              svg path {
                fill: #1280CE;
              }
            }
          }
        }

        .items {
          display: flex;
          flex-direction: column;
          padding: 0 38px;

          .item {
            display: flex;
            align-items: center;
            padding: 12px 0;

            .avatar {
              margin-right: 8px;
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background: #EAEDF3;

              img {
                width: 100%;
                border-radius: 24px;
                object-fit: cover;
              }
            }

            p {
              color: #262626;
              font-size: 14px;
              font-weight: 500;
              line-height: 13px;
            }
          }
        }
      }
    }

    .users {
      display: flex;
      flex-direction: column;

      h4 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        .left {
          display: flex;
          align-items: center;
          width: 50%;

          .avatar {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: #EAEDF3;
            border-radius: 24px;

            img {
              width: 100%;
              border-radius: 24px;
              object-fit: cover;
            }
          }

          p {
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 13px;
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 50%;

          & > div:first-child {
            & > div > div:nth-child(2) {
              background: #FFFFFF;
              border: none !important;
            }

            &.disabled > div > div > div:nth-child(2) {
              display: none;
            }
          }

          span {
            color: #262626;
            font-size: 13px;
            line-height: 19px;
            opacity: 0.5;
          }

          .separator {
            margin: 0 16px;
            width: 1px;
            height: 32px;
            background: #EAEDF3;
            border-radius: 1px;
          }

          .treeIcon {
            cursor: pointer;
          }

          .treeIcon:hover {
            svg path {
              fill: #1280CE;
            }
          }

          .treeIcon {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: 1px solid #EAEDF3;
  }
}