.trainingTestQuestion {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .trainingTestQuestionHead {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EAEDF3;

    .trainingTestQuestionHeadLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      width: 100%;

      .trainingTestQuestionHeadLeftContainer {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 4px;
          color: #262626;
          font-size: 24px;
          font-weight: 600;
          line-height: 33px;
        }
        p {
          margin-bottom: 0;
          color: rgba(38, 38, 38, 0.5);
          font-size: 13px;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
    .trainingTestQuestionHeadRight {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      width: 100%;
      max-width: 280px;
      height: 100%;
      border-left: 1px solid #EAEDF3;

      .trainingTestQuestionHeadRightContainer {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        span {
          margin-bottom: 4px;
          color: rgba(38, 38, 38, 0.5);
          font-size: 13px;
          line-height: 150%;
        }
        p {
          margin-bottom: 0;
          color: #262626;
          font-size: 24px;
          line-height: 33px;
        }
      }
    }
  }
  .trainingTestQuestionBody {
    display: flex;
    align-items: center;
    height: calc(100vh - 164px);

    .trainingTestQuestionBodyLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;

      .trainingTestQuestionBodyLeftTop {
        display: flex;
        flex-direction: column;
        padding: 32px 24px 24px 24px;
        border-bottom: 1px solid #EAEDF3;

        .trainingTestQuestionBodyLeftCounter {
          margin-bottom: 8px;
          color: #1280CE;
          font-size: 11px;
          font-weight: 500;
          line-height: 100%;
        }
        .trainingTestQuestionBodyLeftTimer {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          i {
            margin-right: 8px;
          }
          span {
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
          }
        }
        img {
          margin-top: 8px;
          margin-bottom: 24px;
          width: 100%;
          max-width: 460px;
          border-radius: 5px;
        }
        h3 {
          color: #262626;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
        }
      }
      .trainingTestQuestionBodyLeftBottom {
        padding: 24px;

        .trainingTestQuestionBodyLeftBottomDescription {
          margin-bottom: 24px;
          color: rgba(38, 38, 38, 0.5);
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
        }
        .trainingTestQuestionBodyLeftBottomClear {
          padding-left: 32px;
          color: #1280CE;
          font-size: 14px;
          font-weight: 500;
          line-height: 100%;
          cursor: pointer;
        }
        .trainingTestQuestionBodyLeftBottomMany,
        .trainingTestQuestionBodyLeftBottomOne {
          ul {
            display: flex;
            flex-direction: column;

            li {
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              label {
                margin-right: 8px;
                margin-bottom: 0;
                width: 24px;
                height: 24px;

                div {
                  width: 100%;
                  height: 100%;

                  &:after {
                    top: 3px;
                    left: 8px;
                    width: 6px;
                    height: 12px;
                  }
                }
              }
              span {
                color: #262626;
                font-size: 14px;
                line-height: 150%;
              }
            }
          }
        }
        .trainingTestQuestionBodyLeftBottomOpen {
          textarea {
            padding: 8px;
            width: 480px;
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            border-radius: 5px;
          }
        }
        .trainingTestQuestionBodyLeftBottomMatch {
          ul {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            li {
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
              span {
                margin-right: 24px;
                color: #262626;
                font-size: 14px;
                line-height: 19px;
              }
              & > div {
                width: 100%;
                max-width: 280px;
              }
            }
          }
        }
        .trainingTestQuestionBodyLeftBottomNext {
          & > div {
            display: flex;
            align-items: center;

            span {
              margin-right: 16px;
              color: #262626;
              font-size: 14px;
              line-height: 19px;
            }
            & > div {
              width: 100%;
              max-width: 240px;
            }
          }
        }
        .trainingTestQuestionBodyLeftBottomSort {
          .trainingTestQuestionBodyLeftBottomSortItem {
            display: flex;
            align-items: center;
            padding: 16px;
            width: 100%;
            max-width: 460px;
            background: #FFFFFF;
            border: 1px solid #EAEDF3;
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            i {
              margin-right: 8px;
            }
            span {
              color: #262626;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
    .trainingTestQuestionBodyRight {
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      width: 100%;
      max-width: 280px;
      height: 100%;
      background: #FBFBFB;
      border-left: 1px solid #EAEDF3;
      overflow: auto;

      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          padding: 8px 32px;
          border-left: 2px solid transparent;
          cursor: pointer;

          &:hover, &.trainingTestQuestionBodyActive {
            background: rgba(39, 155, 217, 0.05);
            border-left: 2px solid #1280CE;

            i svg path {
              fill: #1280CE;
            }
            span {
              color: #1280CE;
            }
          }
          &.trainingTestQuestionBodyRightCompleted {
            i svg path {
              fill: #1280CE;
            }
          }
          i {
            margin-right: 8px;

            svg path {
              fill: #7B8F9C;
            }
          }
          span {
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
    }
  }
  .trainingTestQuestionFooter {
    display: flex;
    align-items: center;
    border-top: 1px solid #EAEDF3;

    .trainingTestQuestionFooterLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      width: 100%;

      .trainingTestQuestionFooterLeftContainer {
        display: flex;
        align-items: center;

        & > div {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .trainingTestQuestionFooterRight {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      width: 100%;
      max-width: 280px;
      height: 100%;
      border-left: 1px solid #EAEDF3;

      .trainingTestQuestionFooterRightContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        span {
          color: #262626;
          font-size: 13px;
          line-height: 150%;
        }
      }
      .trainingTestQuestionFooterRightLoader {
        width: 100%;
        height: 8px;
        background: #EAEDF3;
        border-radius: 6px;

        div {
          height: 8px;
          background: #1280CE;
          border-radius: 6px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingTestQuestion {
    .trainingTestQuestionHead {
      .trainingTestQuestionHeadLeft {
        padding: 12px;
      }
      .trainingTestQuestionHeadRight {
        padding: 12px;
      }
    }
    .trainingTestQuestionBody {
      .trainingTestQuestionBodyLeft {
        .trainingTestQuestionBodyLeftTop {
          padding: 12px;
        }
        .trainingTestQuestionBodyLeftBottom {
          padding: 12px;
        }
      }
      .trainingTestQuestionBodyRight {
        padding: 12px 0;
        max-width: 140px;

        ul {
          li {
            padding: 8px 12px;
          }
        }
      }
    }
    .trainingTestQuestionFooter {
      flex-direction: column-reverse;

      .trainingTestQuestionFooterLeft {
        padding: 12px;

        .trainingTestQuestionFooterLeftContainer {
          margin-right: 8px;
        }
      }
      .trainingTestQuestionFooterRight {
        padding: 12px;
        max-width: 100%;
        border-left: none;
      }
    }
  }
}