.normal {
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;

    span {
      margin-right: 5px;
      color: #89898A;
      font-size: 14px;
    }

    &:hover svg path {
      fill: #1280CE;
    }
  }
}

.active {
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;

    span {
      margin-right: 5px;
      color: #89898A;
      font-size: 14px;
    }

    svg path {
      fill: #1280CE;
    }
  }
}

.light {
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background: #EDF1F5;
    border: 1px solid #EDF1F5;
    border-radius: 20px;

    span {
      margin-right: 5px;
      color: #89898A;
      font-size: 14px;
    }

    &:hover {
      border: 1px solid #FFFFFF;
    }
  }
}

.dark {
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    padding: 0;
    background: #EDF1F5;
    border: 2px solid #EDF1F5;
    border-radius: 20px;

    span {
      margin-right: 5px;
      color: #89898A;
      font-size: 14px;
    }

    &:hover {
      border: 2px solid #60B480;
    }
  }
}

.table {
  button {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    svg path {
      fill: #1280CE;
    }

    span {
      margin-left: 8px;
      color: #262626;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.red {
  button {
    position: relative;

      .status {
          position: absolute;
          bottom: 8px;
          left: 8px;
          min-width: 15px;
          min-height: 15px;
          background: #E06169;
          border-radius: 7.5px;
          z-index: 1;
          color: white;
          font-size: 9px;
          padding: 1px 5px;
          text-align: center;
          margin: 0;
      }
  }
}

.hover-red {
  button {
    position: relative;
    &:hover svg path {
      fill: #E06169 !important;
    }
  }
}

.green {
  button {
    position: relative;

      .status {
          position: absolute;
          bottom: 8px;
          left: 8px;
          min-width: 15px;
          min-height: 15px;
          background: #60B480;
          border-radius: 7.5px;
          z-index: 1;
          color: white;
          font-size: 9px;
          padding: 1px 5px;
          text-align: center;
          margin: 0;
      }
  }
}

.fullWidth {
  width: 100%;
  button {
    width: 100%;
  }
}
