.trainingTree {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;

  .trainingTreeSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 1rem;
    height: 39px;
    background: #FFFFFF;
    border-bottom: 1px solid #E8E8E8;

    input {
      width: 100%;
      border: 0;
      color: rgba(38, 38, 38, 0.5);
      font-size: 14px;
      line-height: 19px;
    }
  }
  .trainingTreeExpander {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    height: 24px;

    .trainingTreeExpanderButton {
      display: flex;
      align-items: center;
      color: #1280CE;
      font-size: 11px;
      font-weight: 500;
      line-height: 11px;
      cursor: pointer;

      &:first-child {
        margin-right: 16px;
      }
      &:last-child {
        padding-left: 16px;
      }
      i {
        margin-top: 2px;
        margin-right: 8px;
      }
    }
    .trainingTreeExpanderButton + .trainingTreeExpanderButton {
      border-left: #EAEDF3 1px solid;
    }
  }
  .trainingTreeCreate {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 24px;

    .trainingTreeCreateIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 18px;
      height: 18px;
      background: #1280CE;
      border-radius: 100px;
    }
    span {
      color: #1280CE;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      cursor: pointer;
    }
  }
  .trainingTreeSizerContainer {
    flex-grow: 1;
  }
}