@import "src/mixins/index.scss";

.project {
    position: relative;
    background: #FFFFFF;

    .head {
        display: flex;
        padding: 25px;

        .avatar {
            margin-right: 23px;
            min-width: 120px;
            width: 120px;
            height: 120px;
            background-size: cover;
            border-radius: 120px;
            flex-shrink: 0;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                h1 {
                    margin: 0 0 8px 0;
                    color: #262626;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 33px;
                }
                .navigation {
                    display: flex;
                    align-items: center;

                    @media (min-width: 768px) and (max-width: 1024px) {
                        margin-right: 24px;
                    }
                    div {
                        margin-right: 20px;

                        &:first-child {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            background: #1280CE;
                            border: 1px solid #1280CE;
                            border-radius: 20px;

                            &:hover {
                                svg path {
                                    fill: #FFFFFF;
                                }
                                &:hover {
                                    background: #188FE3;
                                    box-shadow: 0 2px 4px rgba(18, 128, 206, 0.35);
                                }
                                &:active {
                                    background: #0F7AC7;
                                    box-shadow: none;
                                }
                            }
                        }

                        &:last-child {
                            margin-right: 0;

                            &:not(:first-child) svg path {
                                fill: #FF6973;
                            }
                        }
                    }
                }
            }

            p {
                margin-bottom: 16px;
                color: #262626;
                font-size: 14px;
                line-height: 21px;
            }

            .manager {
                display: flex;
                flex-direction: column;

                span {
                    margin-bottom: 2px;
                    color: #262626;
                    font-size: 13px;
                    line-height: 19px;
                    opacity: 0.5;
                }

                a {
                    color: #1280CE;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                }
            }
        }
    }
}

.mobileProject {
    position: relative;
    padding: rem(24px) rem(16px);
    background-color: var(--color-white);

    .titleBlock {
        display: flex;
        max-width: calc(100% - 28px);

        .avatar {
            width: 32px;
            height: 32px;
            background: #EAEDF3;
            background-size: cover;
            border-radius: 120px;
            margin-right: rem(10px);
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            display: flex;
            align-items: center;
            color: #262626;
        }
    }

    .desc {
        margin-top: rem(8px);
    }

    .manager {
        display: flex;
        flex-direction: column;
        margin-top: rem(16px);

        span {
            margin-bottom: 2px;
            color: #262626;
            font-size: 13px;
            line-height: 19px;
            opacity: 0.5;
        }

        a {
            color: #1280CE;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
        }
    }

    .navigationMenu {
        position: absolute;
        top: 24px;
        right: 16px;
    }
}

ul.projectMenu {
    position: sticky;
    top: 0;
    display: flex;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;
    z-index: 10;

    #scriptsTab {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    li {
        position: relative;
        padding: 13px 24px;
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 12px;
        opacity: 0.5;
        cursor: pointer;

        &:hover, &.active {
            color: #1280CE;
            opacity: 1;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #1280CE;
                border-radius: 5px 5px 0 0;
            }
        }
    }
}
