@import "src/mixins/index.scss";

.tabScripts {
    display: flex;
    flex-direction: column;
    @include platform-spacing(padding);
    padding-top: 24px;
    overflow-x: hidden;

    .loader {
        // position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
    .titleBlock {
        width: 100%;
        color: #262626 !important;

        &.titleMobile p {
            margin-bottom: 8px;
        }
        &:hover {
            p {
                color: #1280CE;
            }
        }
        .description {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
        }
        .description {
            font-weight: 400;
            -webkit-line-clamp: 3;
        }
    }
    .deleteActionButton {
        svg path {
            fill: #E06169 !important;
        }
    }
}
.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}
