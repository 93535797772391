@import "src/mixins/index.scss";

.tabArticles {
    display: flex;
    flex-direction: column;
    @include platform-spacing(padding);
    padding-top: rem(24px);
    overflow-x: hidden;
    position: relative;
    flex-grow: 1;

    .loader {
        // position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }
    .loaderContainer > * {
        background-color: transparent;
    }
    .articles {
        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include platform-spacing(margin-bottom);

            h2 {
                margin: 0;
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .table {
            background: #FFFFFF;
            border: 1px solid #EAEDF3;
            border-radius: 5px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.6);
                z-index: 1;
            }
            .filter {
                padding: 24px;
                border-bottom: 1px solid #EAEDF3;
            }
            .pagination {
                padding: 16px 24px;
                background: #FFFFFF;
                border-top: 1px solid #EAEDF3;
                border-radius: 0 0 5px 5px;
            }
        }
    }
    .titleBlock {
        width: 100%;
        color: #262626 !important;

        &.titleMobile p {
            margin-bottom: 8px;
        }
        &:hover {
            p {
                color: #1280CE;
            }
        }
        p, span {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
        }
        p {
            -webkit-line-clamp: 2;
        }
        span {
            -webkit-line-clamp: 3;
            font-weight: 400;
        }
    }
    .subheader {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #262626;
        opacity: 0.5;
        margin-bottom: rem(6px);
    }
}
.removingListItem {
    display: flex;
    align-items: center;
    word-break: break-word;

    &:not(:last-of-type) {
        padding-bottom: 20px;
    }
    label {
        margin-right: 8px;
    }
}
