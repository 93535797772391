@import 'src/mixins/index.scss';
@import 'src/uikit/_styles/common-styles';

.field {
    margin-bottom: rem(8px);
}

.formGroup {
    margin-bottom: 10px;
}

.label {
    display: block;
    margin-bottom: rem(2px);
    font-size: rem(12px);
    line-height: rem(14px);
    color: var(--color-gray);

    :first-child {
        float: left;
    }

    :nth-child(2) {
        visibility: hidden;
    }

    :last-child {
        clear: both;
    }

    .required {
        visibility: visible;
    }
}

.logoWrapper {
    position: relative;

    .logoOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    }

    .cover {
        z-index: 1;
        width: 100%;
        height: calc((100vw - 168px) / 4);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .logoBtnWrapper {
        position: absolute;
        right: 16px;
        bottom: 16px;
        display: flex;
        z-index: 3;
    }

    .logoBtn {
        margin-left: 16px;
    }
}

.form {
    margin-top: rem(24px);
    padding-bottom: 0;
}

.blockText {
    font-weight: 600;
    color: var(--color-textBlack);
}

.fieldGroup {
    margin-bottom: rem(16px);
}

.editorLabel {
    margin-bottom: rem(4px);
    display: flex;
    justify-content: space-between;

    .editorLabel {
        @include inputLabel;

        .requiredField {
            color: var(--color-red);
        }
    }

    .openLink {
        cursor: pointer;
        font-size: 13px;
        color: var(--color-blue);
        display: flex;
        align-items: center;

        i svg path {
            fill: var(--color-blue);
        }

        i + div {
            margin-left: 4px;
        }
    }
}

.blockDates {
    display: flex;

    & > div {
        flex-basis: 50%;
    }

    & > div:first-child {
        padding-right: 0.5rem;
    }

    & > div:last-child {
        padding-left: 0.5rem;
    }
}

.blockTags {
    display: flex;
    flex-direction: column;

    .tagItem {
        margin-top: 16px;
    }
}

.toolDivider {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 0 !important;
}

.mobile {
    .blockDates {
        flex-direction: column;

        & > div {
            padding: 0;
        }

        & > div:first-child {
            margin-bottom: rem(12px);
        }
    }

    .logoWrapper {
        .cover {
            height: 125px;
        }
    }
}

.dueto {
    align-items: center;
    display: flex;

    .switch {
        margin: {
            left: 0.5rem;
            bottom: 0;
        }
    }
}

.pickerTitle {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > div {
        padding-right: 8px;
    }
}

// exactQueryGroups
.exactQueryGroupsBlock,
.exactQueryGroupsDataBlock {
    display: flex;
    flex-direction: column;
}
.exactQueryGroupsBlock {
    gap: 2rem;
}
.exactQueryGroupsItemBlock {
    display: flex;
    gap: 1rem;
}
.exactQueryGroupsIconBlock {
    padding-top: 33px;
}
.exactQueryGroupsDataBlock {
    gap: 1rem;
    flex-grow: 1;
}
.fieldLabel {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    color: var(--color-textBlack);
    opacity: 0.75;
}
.fieldLabelTop {
    margin-bottom: 0.25rem;
}
.fieldLabelBottom {
    margin-top: 0.25rem;
}
.exactQueryGroupsAddBtn {
    color: #1280ce !important;
}
