@import 'src/mixins/mixins';

.editor {
    padding: 0 !important;
    margin: 0;

    font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 16px;
    line-height: 1.5;

    height: 100%;
    color: #2d3a4a;

    * {
        box-sizing: border-box;
    }

    a {
        color: #38a5ee;
    }

    @include content();

    header .centered {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 8em;
    }

    header h1 a {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #2d3a4a;
        text-decoration: none;
    }

    header h1 img {
        display: block;
        height: 64px;
    }

    header nav ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    header nav ul li {
        display: inline-block;
    }

    header nav ul li + li {
        margin-left: 1em;
    }

    header nav ul li a {
        font-weight: bold;
        text-decoration: none;
        color: #2d3a4a;
    }

    header nav ul li a:hover {
        text-decoration: underline;
    }

    main .message {
        padding: 0 0 var(--ck-sample-base-spacing);
        background: var(--ck-sample-color-green);
        color: var(--ck-sample-color-white);
    }

    main .message::after {
        content: '';
        z-index: -1;
        display: block;
        height: 10em;
        width: 100%;
        background: var(--ck-sample-color-green);
        position: absolute;
        left: 0;
    }

    main .message h2 {
        position: relative;
        padding-top: 1em;
        font-size: 2em;
    }

    .centered {
        /* Hide overlapping comments. */
        overflow: hidden;
        max-width: var(--ck-sample-container-width);
        margin: 0 auto;
        padding: 0 var(--ck-sample-base-spacing);
    }

    .row {
        display: flex;
        position: relative;
    }

    .btn {
        cursor: pointer;
        padding: 8px 16px;
        font-size: 1rem;
        user-select: none;
        border-radius: 4px;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
        background-color: var(--ck-sample-color-button-blue);
        border-color: var(--ck-sample-color-button-blue);
        color: var(--ck-sample-color-white);
        display: inline-block;
    }

    .btn--tiny {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    footer {
        margin: calc(2 * var(--ck-sample-base-spacing)) var(--ck-sample-base-spacing);
        font-size: 0.8em;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
    }
}

.error {
    border: 1px solid var(--color-red);
}

.errorText {
    color: var(--color-red);
    font-size: rem(12px);
    line-height: rem(16px);
    margin-top: 4px;
}
