@import "src/mixins/index.scss";

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);
}

.title {
  @include header-large;
  width: 100%;
  padding-bottom: rem(24px);
}

.table {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .filter {
    @include platform-spacing(padding);
    border-bottom: 1px solid #EAEDF3;
  }


  .pagination {
    padding: 16px 24px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;
    border-radius: 0 0 5px 5px;
  }
}

.employeeCell {
  display: flex;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    background-color: #eee;
    margin-right: 8px;
  }
}

.modal {
  position: relative;
  padding: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 568px;
  background-color: #fff;
  border: 1px solid #EAEDF3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.modalOverlay {
  position: fixed;
  padding: rem(32px);
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  background: rgba(38, 38, 38, 0.65);
  z-index: 101;

  &::before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
}

.mobileManagerInfo {
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  div {
    margin-left: rem(8px);
    font-weight: 500;
    font-size: rem(14px);
  }
}

.managerInfo {
  display: flex;
  align-items: center;
  color: #262626 !important;
  &:hover {
    color: #1280CE !important;
  }
}

.subheader {
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #262626;
  opacity: 0.5;
  margin-bottom: rem(6px);
}