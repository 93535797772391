@import "src/mixins/index.scss";

.crumbsTip {
    font-size: rem(13px) !important;
    font-weight: 400 !important;
    line-height: rem(19.5px) !important;
    text-align: center !important;
}
.crumbs {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--color-blue) !important;
    font-size: rem(11px) !important;
    font-weight: 500 !important;
    line-height: rem(14px) !important;
    overflow: hidden;
    
    span {
        margin-bottom: rem(4px) !important;
        color: var(--color-blue) !important;
        font-size: rem(11px) !important;
        font-weight: 500 !important;
        line-height: rem(14px) !important;
        opacity: 1 !important;
        cursor: pointer !important;

        &.crumb {
            display: -webkit-box;
            word-break: break-all;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;

            i {
                position: absolute;
                top: 0;
                right: -9px;
            }
        }
        &.crumbLine {
            position: relative;
            display: table;
            overflow: auto;

            i {
                right: -12px;
            }
        }
        &.medium {
            font-size: rem(14px) !important;
        }
    }

    &.crumbsFree {
        overflow: visible;
        display: block;

        span {
            &.crumb {
                display: inline;
                position: relative;

                i {
                    position: static;
                }
            }
        }
    }
}
