.form {
    margin-top: 20px;

    & > div + div {
        margin-top: 10px;
    }
}

.buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;

    & > div + div {
        margin-left: 8px;
    }
}

.accessManagement {
    .subheader {
        font-weight: 600;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #262626;
        opacity: 0.5;
        margin-bottom: rem(6px);
    }
    .accessManagementTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .addBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 16px;
            background: #50B678;
            border: 1px solid transparent;
            border-radius: 5px;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            &:hover {
                background: #52C57F;
                box-shadow: 0 2px 4px rgba(80, 182, 120, 0.35);
            }
            &:focus {
                background: #42B36E;
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 6px;
                width: 16px;
                height: 16px;
                background: #FFFFFF;
                border-radius: 16px;

                svg path {
                    fill: #50B678;
                }
            }
        }
    }
    & .filter {
        padding: 25px;

        & > div:first-child {
            text-align: end;
        }
    }
}
@media (max-width: 768px) {
    .accessManagement {
        .accessManagementTitle {
            flex-direction: column;

            h1 {
                margin-bottom: 12px;
                text-align: center;
            }
        }
    }
}