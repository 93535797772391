@import "src/mixins/index.scss";

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding-top: rem(24px);
  padding-left: rem(24px);
  padding-right: rem(24px);

  h3 {
    font-size: rem(22px);
    font-weight: 500;
  }
}

.modalBody {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: rem(32px);

  video {
    width: 100%;
  }

  .inputWrapper {
    width: 100%;
    padding-bottom: rem(24px);
  }

  .nameCell, .fileWrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .iconWrapper {
      padding-right: rem(12px);
    }

    .descWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    .changeFile {
      display: flex;
      align-items: center;
      color: #1280CE;
      cursor: pointer;

      i {
        margin-right: 6px;

        svg {
          fill: #1280CE;
        }
      }
    }

    .fileName {
      padding-bottom: rem(8px);
    }

    .fileDescription {
      color: rgba(#262626, 0.5);

      .fileType {
        text-transform: uppercase;
      }
    }
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: rem(16px) rem(24px);
  border-top: 1px solid #EAEDF3;

  > *:not(:last-child) {
    margin-right: rem(16px);
  }
}
