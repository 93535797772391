@import 'src/mixins/index.scss';

.modalContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 26px 32px 32px;
    width: 570px;
    height: 490px;

    .title {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        line-height: 30px;
        padding-bottom: 32px;
    }

    .dropZone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #eaedf3;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        cursor: pointer;

        & p {
            width: 100%;
            max-width: 360px;
            text-align: center;
            margin-top: 8px;
            font-size: 13px;
            line-height: 150%;
            color: rgba(#262626, 0.5);
        }
    }

    .uploading {
        transform: translateY(50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
