@import "src/mixins/index.scss";

.contentWrapper {
  @include platform-spacing(padding);
  padding-top: rem(24px);
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}

.aboutWrapper {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 24px;

  .versionWrapper {
    .versionTitle {
      padding-bottom: 16px;
      font-weight: 600;
    }
  }

  .contactsWrapper {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #EAEDF3;
  }

  .supportTitle {
    font-weight: 600;
    padding-bottom: 16px;
  }

  .supportWrapper {
    display: flex;

    & > div:first-of-type {
      padding-right: 32px;
    }

    .supportSubTitle {
      color: rgba(#262626, 0.5);
      padding-bottom: 8px;
    }

    .supportText {
      display: block;
      color: #1280CE;
      padding-bottom: 8px;
    }
  }
}