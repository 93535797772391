@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(38, 38, 38, 0.65);
    z-index: 10;

    & > div {
        position: relative;
    }
}

.formGroup {
    margin-bottom: 10px;
}

.label {
    display: block;
    margin-bottom: rem(2px);
    font-size: rem(12px);
    line-height: rem(14px);
    color: var(--color-gray);

    :first-child {
        float: left;
    }

    :nth-child(2) {
        visibility: hidden;
    }

    :last-child {
        clear: both;
    }

    .required {
        visibility: visible;
    }
}

.logoWrapper {
    position: relative;

    .logoOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    }

    .cover {
        z-index: 1;
        width: 100%;
        height: calc((100vw - 168px) / 4);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .logoBtnWrapper {
        position: absolute;
        right: 16px;
        bottom: 16px;
        display: flex;
        z-index: 3;
    }

    .logoBtn {
        &:not(:last-child) {
            margin-right: 16px;
        }

        button {

        }
    }
}

.form {
    @include platform-spacing(margin);
    padding-bottom: 0;
}

.blockText {
    font-weight: 600;
    color: var(--color-textBlack);
}

.fieldGroup {
    margin-bottom: rem(16px);
}

.editorLabel {
    margin-bottom: rem(4px);
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .editorLabel {
        @include inputLabel;
    }

    .openLink {
        font-size: 13px;
        color: var(--color-blue);
        display: flex;
        align-items: center;

        i svg path {
            fill: var(--color-blue);
        }

        i + div {
            margin-left: 4px;
        }
    }
}

.newsPickerTitle {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > div {
        padding-right: 8px;
    }
}

.blockNewsTicker {

}

.blockNewsTickerSelects {
    display: flex;
    justify-content: space-between;

    & > div {
        width: calc(50% - 8px);
    }
}

.blockDates {
    display: flex;

    & > div {
        flex-basis: 50%;
    }

    & > div:first-child {
        padding-right: 0.5rem;
    }

    & > div:last-child {
        padding-left: 0.5rem;
    }
}

.blockTags {
    display: flex;
    flex-direction: column;

    .tagItem {
        margin-top: 16px;
    }
}

.dataLine {
    display: flex;
    justify-content: space-between;
    gap: rem(16px);
    margin: rem(14px) 0;
}

.header {
    display: flex;
    align-items: center;
}

.avatarContainer {
    display: flex;
    @include platform-spacing(padding-right);
    @include platform-spacing(padding-left);

    .avatarImage {
        width: rem(120px);
        height: rem(120px);
        background-color: #EAEDF3;
        border-radius: rem(60px);
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .avatarPanel {
        display: flex;
        align-items: center;
        padding: rem(12px) 0;

        img {
            width: rem(120px);
            height: rem(120px);
            border-radius: 50%;
        }

        .infoContainer {
            display: flex;
            margin-left: rem(16px);
            position: relative;
            cursor: pointer;

            input[type=file] {
                display: none;
            }

            & > div {
                &:first-child button {
                    margin-right: rem(16px);
                }

                &:nth-child(2) button i svg path {
                    fill: #FF6973;
                }
            }
        }
    }
}

//: temp
.notifSettingsContainer {
    .modeContainer {
        display: flex;
        flex-direction: column;

        .modeItem {
            display: flex;
            padding-top: rem(32px);
            padding-bottom: rem(32px);
            border-bottom: 1px solid #EAEDF3;

            .controlContainer {
                margin-top: rem(4px);
                margin-right: rem(16px);
            }

            .descContainer {
                display: flex;
                flex-direction: column;

                .label {
                    font-size: rem(14px);
                    line-height: 150%;
                }

                .desc {
                    margin-top: rem(8px);
                    font-size: rem(13px);
                    line-height: 150%;
                    opacity: 0.5;
                }
            }
        }
    }

    .rulesTable {
        margin-top: rem(32px);
        width: 556px;

        @media (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
        }
        tr {
            height: 40px;
            line-height: 40px;

            th, td {
                &:not(:first-of-type) {
                    text-align: center;
                }
            }

            td label {
                display: initial;
            }
        }
    }
}

.section {
    border-bottom: 1px solid #EAEDF3;
    padding: 32px 0 18px 0;

    &:first-child {
        padding-top: 0;
    }
}

.mainDataContainer {

    .dataLine {
        display: flex;
        justify-content: space-between;
        margin: rem(14px) 0;

        .baseInput {
            padding-right: rem(16px);
        }

    }
}

.buttonContainer {
    display: flex;
    margin-top: 30px;

    div {
        margin-right: rem(16px);
    }
}

.addProjectContainer {
    display: flex;
    flex-direction: column;

    .addButton {
        display: flex;
        align-items: center;
        height: rem(56px);
        background: rgba(39, 155, 217, 0.1);
        border: 1px solid rgba(39, 155, 217, 0.15);
        border-radius: 5px;
        cursor: pointer;
        padding: rem(18px) rem(16px);
        width: 100%;
        margin-top: rem(14px);
        margin-bottom: rem(16px);

        p {
            color: #1280CE;
            font-size: 14px;
            line-height: 19px;
            margin-left: rem(8px);
        }
    }

    .projectsContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(16px);

        .projectContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 0;
            // height: rem(56px);
            border-bottom: 1px solid #EAEDF3;

            .projectInfo {
                display: flex;
                align-items: center;

                .projectAvatar {
                    margin-right: 8px;
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    border-radius: 24px;
                    background: #EAEDF3;

                    img {
                        width: 100%;
                        border-radius: 24px;
                        object-fit: cover;
                    }
                }

                .projectName {
                    font-size: 13px;
                    line-height: 150%;
                }
            }

            .projectButtons {
                display: flex;
                align-items: center;

                p {
                    min-width: 100px;
                    width: 100px;
                    font-size: 13px;
                    line-height: 100%;
                    margin-left: rem(8px);
                }

                .buttonsContainer {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid #EAEDF3;
                    margin-left: 16px;
                    height: 32px;

                    .iconButton {
                        margin-left: 16px;
                    }
                }
            }
        }

    }


    .addProjectCard {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(5px);
        width: rem(200px);
        height: rem(40px);
        background-color: var(--color-primary-blue);
        color: white;
        margin-right: 17px;
        cursor: pointer;
    }

    .projectCard {
        display: flex;
        width: rem(200px);
        height: rem(40px);
        background: #D4D4D4;
        border-radius: rem(5px);
        align-items: center;
        padding-top: rem(5px);
        padding-bottom: rem(5px);
        padding-left: rem(10px);
        margin-right: 17px;
        cursor: pointer;

        .avatarContainer {
            img {
                border-radius: 50%;
                width: rem(24px);
                height: rem(24px);
            }
        }

        .infoContainer {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            white-space: nowrap;

            .headingText {
                color: var(--color-gray-dark);
            }

            .smallText {
                color: var(--color-input-border-focus);
            }
        }

        .actionContainer {
            width: 100%;
            display: none;

            margin-left: 5px;
            justify-content: flex-end;

            div {
                margin-right: 5px;
            }
        }

        &:hover {
            .actionContainer {
                display: flex;
            }
        }
    }
}

.accordionButton {
    flex-wrap: wrap;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    text-align: left;
    border: none;

    &.lastAccordion {
        border-bottom: none !important;
    }

    .errorText {
        display: inline-block;
        width: 100%;
        padding-top: 4px;
        color: var(--color-red);
        font-size: rem(12px);
        line-height: rem(16px);
    }
}

.accordionButtonActive {
    color: #1280CE;
}

.accordionButton:hover {

}

.accordionButton:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordionButton[aria-expanded='true']::before,
.accordionButton[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordionPanel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.controlsContainer {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 1rem 0;

    > *:not(:first-child) {
        margin-left: rem(16px);
    }
}

.pageTitle {
    @include header-large;
    @include platform-spacing(margin);
}

.systemAccessContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: rem(32px);
    border-bottom: 1px solid #EAEDF3;

    .controlContainer {
        margin-top: rem(4px);
    }

    .descContainer {
        display: flex;
        flex-direction: column;

        .label {
            font-size: rem(14px);
            line-height: 150%;
        }
    }

    .desc {
        width: 100%;
        padding-left: rem(20px);
        list-style: initial;
        margin-top: rem(8px);
        font-size: rem(13px);
        line-height: 150%;
        opacity: 0.5;
    }
}

.toolDivider {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 0 !important;
    margin-top: 0;
}

.mobile {
    .dataLine {
        flex-direction: column;
    }
    .controlsContainer {
        flex-direction: column-reverse;
        button {
            margin-right: 0;
        }
        & > :not(:first-child) {
            margin-bottom: rem(16px);
        }
    }
}

.userLink {
    font-weight: 500;
    font-size: rem(13px);
    color: var(--color-blue);
}

.topDivider {
    margin-bottom: 0;
}
