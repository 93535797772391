.trainingTestViewContent {
  ul {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: #FFFFFF;

    li {
      display: flex;
      align-items: center;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
      img {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
      }
      div {
        display: flex;
        flex-direction: column;

        span {
          color: rgba(38, 38, 38, 0.5);
          font-size: 13px;
          line-height: 19px;
        }
        p {
          color: #262626;
          font-size: 13px;
          line-height: 19px;
        }
        a {
          color: #1280CE;
          font-size: 13px;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
  .trainingTestViewContentImage {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .trainingTestViewContentDescription {
    padding: 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;
    color: #262626;
    font-size: 14px;
    line-height: 21px;
  }
  .trainingTestViewContentQuestions {
    padding: 24px;

    .trainingTestViewContentQuestion {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 24px;
      background: #FFFFFF;
      border: 1px solid #EAEDF3;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
      &:last-child {
        margin-bottom: 0;
      }
      img {
        margin-bottom: 16px;
        width: 160px;
        height: 90px;
        border-radius: 5px;
        object-fit: cover;
      }
      h3 {
        margin-bottom: 8px;
        color: #262626;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      .trainingTestViewContentQuestionDelimiter {
        margin: 8px 0;
        width: 100%;
        height: 1px;
        background: #EAEDF3;
      }
      p {
        margin-top: 8px;
        color: #000000;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;

        span:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .trainingTestViewContent {
    ul {
      flex-direction: column;

      li {
        img {
          display: none;
        }
        div {
          flex-direction: row;

          span {
            margin-right: 4px;
          }
        }
      }
    }
    .trainingTestViewContentDescription {
      padding: 12px;
    }
    .trainingTestViewContentQuestions {
      padding: 12px;
    }
  }
}