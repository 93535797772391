@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.base {
  @include inputWrapper;

  .inputWrapper {
    position: relative;

  }
}

.label {
  @include inputLabel;
}

.input {
  @include input;
  height: inherit;
  min-height: 40px;
  padding-top: rem(9.2px);
  padding-bottom: rem(9.2px);
  font-family: 'NotoSans', sans-serif;
}

.input:focus {
  border-width: 1px;
  outline: 1px solid var(--color-blue);
}

.description {
  color: rgba(#262626, 0.5);
}

.error {

  .input {
    // @include inputError;
    border-color: var(--color-red);
    outline: 1px solid var(--color-red);
  }

  .error-message {
      margin-top: 4px;
      color: #ff6973;
      font-size: 13px;
      line-height: 18px;
  }
}
