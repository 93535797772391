@import "src/mixins/index.scss";

.content {
    padding: rem(24px);
    width: 100%;

    .card {
        margin-top: 96px;
    }

    .headCard {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        height: 6rem;
        background-color: white;
        width: 100%;
        padding: 16px 24px;

        .headContainer {
            display: flex;
            align-items: center;

            .name {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                margin-left: 18px;
            }
        }

        .buttonHeadContainer {
            display: flex;

            .leftIcon {
                margin-right: 16px;
            }
        }

    }

    .pageTitle {
        @include header-large;
        margin-top: 0;
    }

    .field {
        margin-bottom: rem(8px);
    }

    .formGroup {
        margin-bottom: 10px;
    }

    .titleBlock {
        font-weight: 600;
        font-size: rem(14px);
        line-height: 150%;
        margin-bottom: 1rem;
    }

    @mixin designedInput {
        height: 40px;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
    }

    .blockLocation {
        @include designedInput;
    }

    .divider {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 0;
    }

    .tool {
        display: flex;
        padding-top: 1rem;

        button {
            margin-right: 1rem;
        }
    }

    .addProjectContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: rem(14px);
        cursor: pointer;

        .errorText {
            padding-top: 4px;
            color: var(--color-red);
            font-size: rem(12px);
            line-height: rem(16px);
        }

        .addButton {
            display: flex;
            align-items: center;
            height: rem(56px);
            background: rgba(39, 155, 217, 0.1);
            border: 1px solid rgba(39, 155, 217, 0.15);
            border-radius: 5px;
            cursor: pointer;
            padding: rem(18px) rem(16px);

            p {
                color: #1280CE;
                font-size: 14px;
                line-height: 19px;
                margin-left: rem(8px);
            }
        }

        .projectsContainer {
            margin-top: rem(16px);
            display: flex;
            flex-direction: column;

            .projectContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: rem(56px);
                border-bottom: 1px solid #EAEDF3;

                .projectInfo {
                    display: flex;
                    align-items: center;

                    .prijectName {
                        font-size: 13px;
                        line-height: 150%;
                    }

                    img {
                        width: rem(24px);
                        height: rem(24px);
                        border-radius: 50%;
                        margin-right: rem(8px);
                    }
                }

                .projectButtons {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 13px;
                        line-height: 100%;
                        margin-left: rem(8px);
                    }

                    .buttonsContainer {
                        display: flex;
                        align-items: center;
                        border-left: 1px solid #EAEDF3;
                        margin-left: 16px;
                        height: 32px;

                        .iconButton {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 650px;
    overflow: hidden;
    padding-top: rem(26px);

    @media (min-width: 600px) {
        width: 570px;
    }

    .title {
        display: flex;
        justify-content: space-between;
        height: rem(30px);
        font-size: 22px;
        padding-left: rem(32px);
        padding-right: rem(32px);
    }

    .container {
        display: flex;
        flex-direction: column;
        padding-left: rem(32px);
        padding-right: rem(32px);
        width: 100%;
        overflow: auto;

        .userContainer {
            display: flex;
            align-items: center;
            min-height: rem(48px);
            width: 100%;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover, &.userContainerChecked {
                background: #1280CE0D;
            }
            .userInfo {
                display: flex;
                align-items: center;
                margin-left: rem(8px);

                img {
                    margin-right: rem(8px);
                    width: rem(24px);
                    height: rem(24px);
                    border-radius: 50%;
                }
                p {
                    width: 100%;
                    color: #262626;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 13px;
                    word-break: break-all;
                }
            }
        }
    }

    .stretched {
        flex-basis: 100%;
    }

    .buttons {
        display: flex;
        padding: rem(16px);
        align-self: flex-end;

        div + div {
            margin-left: rem(16px);
        }
    }
    &.modalMobile {
        .title {
            padding-left: rem(24px);
            padding-right: rem(24px);
        }
        .searchContainer {
            margin-left: rem(24px);
            margin-right: rem(24px);
            .search {
                margin-top: rem(24px);
            }
        }
        .container {
            padding-left: rem(24px);
            padding-right: rem(24px);
        }
        .divider {
            margin-top: rem(24px);
            margin-bottom: rem(24px);
        }
    }
}

.searchContainer {
    margin-left: rem(32px);
    margin-right: rem(32px);

    .search {
        display: flex;
        align-items: center;
        padding: 10px 14px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        margin-top: rem(32px);


        .searchButton {
            display: inherit;
            margin-right: 10px;
        }

        input {
            width: 100%;
            border: 0;
            color: #262626;
            font-size: 14px;
            outline: none;

            &::placeholder {
                color: #262626;
                opacity: 0.4;
            }
        }
    }
}
