.chatGroupAddOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(38, 38, 38, 0.65);
  z-index: 10;
}

.chatGroupAdd {
  position: relative;
  width: 100%;
  max-width: 570px;
  height: calc(100vh - 160px);
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .chatGroupAddHeader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #EAEDF3;

    .chatGroupAddHeaderTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h3 {
        margin-bottom: 0;
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .chatGroupAddHeaderBottom {
      display: flex;
      padding: 32px 32px 16px 32px;

      .chatGroupAddHeaderBottomSearch {
        position: relative;
        width: 100%;

        i {
          position: absolute;
          top: 14px;
          left: 14px;
        }

        input {
          padding-left: 34px;
        }
      }
    }
  }

  .chatGroupAddBody {
    margin-top: 143px;
    padding: 26px 32px;
    height: calc(100% - 216px);
    overflow: auto;

    .chatGroupAddBodyEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      i {
        margin-bottom: 16px;
      }

      p {
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
      }
    }

    .chatGroupAddBodyGroups, .chatGroupAddBodyUsers {
      h4 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .chatGroupAddBodyGroupsItems, .chatGroupAddBodyUsersItems {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .chatGroupAddBodyGroupsItemsItem, .chatGroupAddBodyUsersItemsItem {
          display: flex;
          align-items: center;
          padding: 12px 0;
          cursor: pointer;

          label {
            margin-top: -2px;
            margin-right: 8px;
          }

          .chatGroupAddBodyGroupsItemsItemAvatar, .chatGroupAddBodyUsersItemsItemAvatar {
            margin-right: 8px;
            width: 24px;
            height: 24px;
            background: #EAEDF3;
            border-radius: 24px;

            img {
              width: 100%;
              border-radius: 24px;
              object-fit: cover;
            }
          }

          p {
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
          }
        }
      }

      .chatGroupAddBodyUsersItems {
        margin-bottom: 0;
      }
    }
  }

  .chatGroupAddFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid #EAEDF3;

    button:first-child {
      margin-right: 16px;
    }
  }
}
