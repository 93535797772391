@import "src/mixins/index.scss";

.filtersOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(26, 26, 26, .65);
  z-index: 110;
}

.filters {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 420px;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 5px 0 0 5px;
  box-shadow: -35px 0 50px rgba(0, 0, 0, 0.03);
  overflow: auto;

  .filtersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;

    h3 {
      margin-bottom: 0;
      margin-left: 0;
      color: #262626;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .filtersBody {
    padding: 32px 24px;

    .row {
      margin-bottom: 24px;

      &:last-child:after {
        display: none;
      }

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #EAEDF3;
      }

      h4 {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .filtersField {
        margin-bottom: 16px;

        & > div > div {
          height: auto;
        }

        & > div {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .range {
        .rangeInputs {
          display: flex;
          align-items: center;
          padding-bottom: 40px;

          .rangeInputsFrom {
            margin-right: 16px;
          }

          .rangeInputsTo {
            margin-left: 16px;
          }

          .rangeInputsTo + div > div {
            width: 80px;
            margin-left: auto;
            border: none !important;
            box-shadow: none;

            & > div:nth-child(2) > span {
              display: none;
            }
          }
        }
      }

      .rangeSlider {
        padding-bottom: 24px;
      }

      .reset {
        margin-bottom: 24px;
        padding: 0;
        background: transparent;
        border: none;
        color: #1280CE;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  .filtersFooter {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    padding: 12px 24px;
    width: 420px;
    background: #FFFFFF;
    border-top: 1px solid #EAEDF3;

    div:first-child {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.sortBody {
  padding: 32px 32px 16px;

  h4 {
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(21px);
  }

  .divider {
    background: #EAEDF3;
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }

  .sortOrder {
    padding-bottom: rem(16px);
  }
}

.filter {
  display: flex;
  justify-content: space-between;

  .filterBtn {
    i {
      margin-right: 6px;
    }
  }
  .filterLeft {
    display: flex;
    align-items: center;
    width: 100%;

    @media (min-width: 768px) and (max-width: 1024px) {
      max-width: 260px;
    }
    & > div {
      margin-right: 8px;
    }
    .filterLeftSelect {
      width: 100%;
      max-width: 140px;
    }
  }
  .filterRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    > :not(:last-child) {
      margin-right: 8px;
    }
    .filterRightImport i svg path {
      fill: #1280CE;
    }
    .filterRightExport i svg path {
      fill: #1280CE;
    }
  }
}

.mobileFilter {
  display: flex;
  flex-direction: column;

  .controls {
    display: flex;

    .search {
      flex-grow: 1;
      max-width: 100%;
    }

    .filterBtn, .sortBtn {
      width: 40px;
      padding: 0;
      margin-left: rem(16px);
      flex-shrink: 0;

      i {
        margin-right: 0;
      }
    }
  }
  .addBtn {
    margin-top: rem(16px);
    width: 100%;

    &--mobile-inline {
      margin-top: 0;
      width: auto;
    }
  }

  .addBtnWrapper{
    margin-left: rem(16px);
  }

  .filtersFooter {
    width: 100%;
  }
}
.filterBtn, .sortBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #F3F9FD;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    color: #1280CE;
  }

  &:active {
    background: #ECF5FB;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    color: #1280CE;
  }

  i {
    margin-right: 6px;

    svg path {
      fill: #1280CE;
    }
  }
}
.sortBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40px;

  i {
    margin-right: 0;
  }
}
.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  background: #50B678;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background: #52C57F;
    box-shadow: 0 2px 4px rgba(80, 182, 120, 0.35);
  }

  &:focus {
    background: #42B36E;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border-radius: 16px;

    svg path {
      fill: #50B678;
    }
  }
}
.search {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  width: 100%;
  max-width: 360px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  height: 40px;

  .searchButton {
    display: inherit;
    margin-right: 8px;
  }

  input {
    width: 100%;
    border: 0;
    color: #262626;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
    outline: none;

    &::placeholder {
      color: #262626;
      opacity: 0.4;
    }
  }

  &:hover {
    border: 1px solid #1280CE;
  }

  &:focus-within {
    border: 2px solid #1280CE;
    padding: 9px 13px;
  }
}
