@import "src/mixins/index.scss";

.search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchInput {
        display: flex;
        align-items: center;
        margin-right: 16px;
        padding: 0 12px;
        height: 40px;
        width: 100%;
        background: #EFF2F6;
        border: 2px solid transparent;
        border-radius: 3px;
        transition: all 0.1s ease-in-out;
        cursor: text;

        &:hover, &.searchInputActive {
            border: 2px solid #1280CE;
        }
        .searchIcon {
            margin-right: rem(10px);
        }
        input {
            width: 100%;
            background: transparent;
            border: 0;
            color: #262626;
            font-size: rem(14px);
            font-weight: normal;
            line-height: rem(19px);
            outline: none;

            &::placeholder {
                color: #262626;
                opacity: 0.5;
            }
        }
    }
}

.searchModeContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: rem(16px);
    min-width: 193px;
    cursor: pointer;

    .modeSelector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .text {
            white-space: nowrap;
        }

        .icon {
            margin-left: 8px;
        }
    }

    .selectorItems {
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        top: 37px;
        z-index: 5;

        .item {
            padding: 12px;

            p {
                white-space: nowrap;
            }

            &:hover {
                background: #E8F0FE;
            }
        }
    }
}

.filterContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: rem(16px);

    .countFilter {
        cursor: pointer;
    }
    .desktopIndicator {
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobileIndicator {
        position: absolute;
        bottom: 8px;
        left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        min-height: 14px;
        background: #1280CE;
        border-radius: 100%;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;

        @media (min-width: 769px) {
            display: none;
        }
    }
}

.mobileOverlay {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    background-color: rgba(38, 38, 38, 0.65);

    &.mobileOverlayTicker {
        top: 84px
    }
}

.autoComleteContainer {
    position: absolute;
    top: rem(60px);
    left: 84px;
    background-color: white;
    width: calc(100% - 223px);
    z-index: 20;
    border: 1px solid #EAEDF3;
    box-sizing: border-box;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    &.autoComleteContainerTicker {
        top: rem(84px);
    }
    .navigationContainer {
        display: flex;
        align-items: center;
        margin-left: rem(10px);
        margin-top: 14px;

        .navigatinItem {
            display: flex;
            align-items: center;
            margin-right: rem(32px);

            .chartContainer {
                background: var(--color-iconGray);
                border-radius: rem(5px);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: rem(8px);

                .imgContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: rem(18px);
                    height: rem(18px);
                }

                .textContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;
                    font-size: rem(10px);
                    line-height: rem(10px);
                    height: rem(18px);
                    padding: 0 rem(6px);
                }
            }

            .navigationLabel {
                font-size: rem(13px);
                line-height: rem(20px);
                opacity: 0.5;
            }
        }

    }

    .resultContainer {
        margin-top: rem(32px);

        .resultHeader {
            font-size: rem(13px);
            line-height: rem(20px);
            opacity: 0.5;
            margin-left: rem(10px);
        }

        .resultItems {
            margin-top: rem(22px);

            .resultItem {
                display: flex;
                align-items: center;
                height: rem(48px);
                cursor: pointer;
                font-size: rem(14px);
                line-height: 150%;

                .searchIcon {
                    margin-left: 18px;
                    display: flex;
                    align-items: center;
                }

                strong {
                    font-weight: 600;
                }

                &:hover {
                    background: var(--color-input-border-focus);
                    color: white;
                }
            }

            .active {
                background: var(--color-input-border-focus);
                color: white;
            }
        }
    }

    .allMatchesContainer {
        p {
            display: flex;
            align-items: center;
            height: rem(48px);
            font-size: rem(13px);
            line-height: rem(20px);
            color: var(--color-input-border-focus);
            cursor: pointer;


            &:hover {
                background: var(--color-input-border-focus);
                color: white;
            }
        }

        .active {
            background: var(--color-input-border-focus);
            color: white;
        }
    }
}

.filtersOverlay {
    position: fixed;
    top: 0;
    left: inherit;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    z-index: 110;
}

.filters {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 420px;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: -35px 0 50px rgba(0, 0, 0, 0.03);
    overflow: auto;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 24px 0 24px;

        h3 {
            margin-bottom: 0;
            margin-left: 0;
            color: #262626;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
        }
    }

    .body {
        padding: 32px 24px 110px 24px;

        .row {
            margin-bottom: 24px;

            &:last-child:after {
                display: none;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #EAEDF3;
                margin-top: 24px;
            }

            h4 {
                margin-bottom: 4px;
                font-size: 13px;
                line-height: 18px;
                color: #262626;
                opacity: 0.75;
            }

            .main {
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #262626;
                margin-bottom: 16px;
            }

            .actions > div > div, .users > div > div {
                height: auto;
            }

            .reset {
                padding: 0;
                background: transparent;
                border: none;
                color: #1280CE;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                margin-top: 16px;
            }

            .filterAction {
                display: flex;
                align-items: center;
                padding: 0;
                background: transparent;
                border: none;
                color: #1280CE;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                margin-bottom: 16px;
                text-align: left;
                .textFilterAction {
                    margin-left: 6px;
                }

            }

            .tagItem {
                margin-bottom: 4px;
            }
            .textInfoTag {
                font-size: 13px;
                line-height: 18px;
                color: #262626;
                opacity: 0.5;
                display: block;
            }
            .Radio {
                margin-bottom: 16px;
                display: block;
            }
        }
    }

    .footer {
        display: flex;
        position: fixed;
        bottom: 0;
        padding: 12px 24px;
        width: 100%;
        border-top: 1px solid #EAEDF3;
        background: #FFFFFF;

        div {
            width: 100%;

            button {
                width: 180px;
                height: 40px;
                font-weight: 500;
                font-size: 14px;
            }

            button:first-child {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

    &.mobileFilters {
        .body {
            padding-bottom: 137px;
        }
        .footer {
            flex-wrap: wrap;
            div {
                button {
                    width: 100%;
                }
            }
        }
    }

    .actionsSearch {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #1280CE;
        margin-bottom: 16px;
        cursor: default;
        span {
            cursor: pointer;
        }
    }
}

.mobile {
    .autoComleteContainer {
        width: auto;
        position: fixed;
        left: 3px;
        right: 3px;
        .resultContainer {
            margin-top: rem(24px);
        }
    }
}
