@import "src/mixins/index.scss";

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  .projectWrapper {
    flex-grow: 1;
    width: 100%;
    @include platform-spacing(padding);

    @media (min-width: 768px) {
      max-width: calc(100% / 2);
    }

    @media (min-width: 1600px) {
      max-width: calc(100% / 3);
    }

    & > div {
      max-width: 100%;
    }
  }
}
