.trainingQuestionModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(39, 86, 119, 0.65);
  z-index: 10;

  .trainingQuestionModal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 570px;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .trainingQuestionModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0 24px;

      h2 {
        color: #262626;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .trainingQuestionModalBody {
      padding: 32px;
      height: 700px;
      overflow: auto;

      & > div > div:nth-child(1) {
        border: 1px solid #E8E8E8;
        border-radius: 5px;
      }
      & > div > div:nth-child(2) {
        display: none;
      }
      & > div > div:nth-child(3) {
        display: none;
      }
    }
    .trainingQuestionModalFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #EAEDF3;

      div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}