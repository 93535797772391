.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  height: 100%;

  &--mt0 {
      margin-top: 0px;
  }

  i {
    margin-bottom: 16px;
  }
  h3 {
    margin-bottom: 4px;
    color: #262626;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
  }
  p {
    color: #262626;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    opacity: 0.65;
  }
  .buttons {
    display: flex;
    margin-top: 16px;
    padding: 8px;
    background: rgba(18, 128, 206, 0.04);
    border-radius: 5px;

    div {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
    button {
      display: flex;
      align-items: center;
      padding: 9px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-left: none;
      border-right: none;

      &:hover {
        background: #1280CE;

        svg path {
          fill: #FFFFFF;
        }
        span {
          color: #FFFFFF;
        }
      }
      &:first-child {
        border: 1px solid #E8E8E8;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border: 1px solid #E8E8E8;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      i {
        margin-bottom: 0;
        margin-right: 6px;
        width: 100%;
        height: auto;
        max-width: 13px;

        svg path {
          fill: #1280CE;
        }
      }
      span {
        color: #262626;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}