@import "src/mixins/index.scss";

.tableWrapper {
    width: 100%;
    padding: 24px;

    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 24px 16px;
    }
}

.activeIcon {
    svg path {
        fill: #1280CE;
    }
}

.table {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);


    .loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: 1;
    }

    .filter {
        padding: rem(24px);
        border-bottom: 1px solid #EAEDF3;
    }

    .pagination {
        padding: 16px 24px;
        background: #FFFFFF;
        border-top: 1px solid #EAEDF3;
        border-radius: 0 0 5px 5px;
    }
}

.titleCellWrapper {
    width: 100%;
    display: flex;

    &:hover {
        .articleTitle {
            color: #1280CE;
        }
    }
    .articleTitle {
        margin-bottom: 8px;
        color: #262626;
    }
    .articleDescription {
        color: rgba(#262626, 0.5);
    }
    .crumbs {
        margin-bottom: rem(8px);
    }
}

.mobileTitleCellWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    .logoContainer {
        margin-top: 8px;
        width: 100%;
        height: 77px;
        background: #EAEDF3;
        border-radius: 5px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .crumbs {
        margin-bottom: rem(8px);
    }
}

.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}
