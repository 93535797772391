@import 'src/mixins/index.scss';

.mobileContentWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 10000;
    display: flex;

    .menuButton {
        //position: absolute;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-white);
        //z-index: 100;
        border-right: 1px solid #eaedf3;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #eaedf3;
    }

    .head {
        width: calc(100% - 56px);
        //position: absolute;
        //top: 0;
        //left: 56px;
        height: 56px;
        background-color: var(--color-white);
        //z-index: 100;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #eaedf3;

        .search {
            flex-grow: 1;
            margin-right: rem(8px);
        }

        .notifBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: rem(24px);
            flex-shrink: 0;
        }

        .userInfo {
            flex-shrink: 0;
            img {
                border-radius: 50%;
                width: 28px;
                height: 28px;
            }
        }
    }
    //
    //.pseudoShadow {
    //  position: absolute;
    //  top: 56px;
    //  left: 0;
    //  width: 100%;
    //  height: 1px;
    //  background-color: #EAEDF3;
    //  z-index: 101;
    //}
}

.desktopHead {
    display: flex;
    align-items: center;
    padding: rem(8px) rem(20px);
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #eaedf3;
    z-index: 10;

    .search {
        width: 100%;
    }

    ul {
        display: flex;
        align-items: center;
        margin-left: 15px;

        li {
            margin-right: 30px;
            display: flex;
            align-items: center;

            a {
                display: inherit;
            }

            &:last-child {
                margin-right: 0;
            }

            &.separator {
                margin-left: -5px;
                margin-right: 25px;
                width: 1px;
                height: 36px;
                background: #eaedf3;
                border-radius: 1px;
            }

            .userInfo {
                display: flex;
                justify-content: center;
                cursor: pointer;
                align-items: center;

                img {
                    max-width: none;
                    width: rem(28px);
                    height: rem(28px);
                    background-size: contain;
                    border-radius: 50%;
                }

                div {
                    margin-left: 8px;
                    font-size: rem(13px);
                    font-weight: 500;
                }
            }
        }
    }
}

.tickerMobile {
    top: 28px;
}
