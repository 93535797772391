.trainingCatalog {
  display: flex;

  .trainingCatalogTree {
    min-width: 260px;
    height: calc(100vh - 61px);
    border-right: 1px solid #EAEDF3;
    overflow: auto;
  }
  .trainingCatalogContainer {
    width: 100%;
    height: 100%;
  }
}