.dropZoneWrapper {
  padding: 24px;
}

.dropZone {
  position: relative;
  width: 100%;
  height: 360px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #fff;
  border: 1px dashed #EAEDF3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  cursor: pointer;

  div {
    width: 100%;
    display: flex;
    justify-content: center;

    padding-bottom: 8px;
  }

  p {
    width: 100%;
    max-width: 320px;
    color: rgba(#262626, 0.55);
    text-align: center;
    line-height: 150%;
  }
}

.dropZoneHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;

  div:first-of-type {
    font-size: 22px;
    font-weight: 500;
  }
}

.loader {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}