@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.label {
  font-size: 13px;
  line-height: 18px;
  @include inputLabel;

  &.required::after {
    content: '\00a0*';
    display: inline-block;
    color: var(--color-red);
  }
}

.errorInput > div {
  margin-bottom: rem(4px);
  border: 1px solid var(--color-red) !important;
  background-color: var(--color-white) !important;

}

.errorMessage {
  color: var(--color-red);
  font-size: rem(12px);
  line-height: rem(16px);
}
