@import "src/mixins/index.scss";

.base {
    position: relative;
}

.menuItem {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    justify-content: space-between;
    margin-right: 0;

    svg path {
        fill: #1280CE;
    }

    .titleWrapper {
        display: flex;
        height: 100%;
        align-items: center;
        i {
            margin-right: 6px;
        }
    }

    div:not(.submenu):not(.titleWrapper) {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        line-height: 20px;
    }

    &:hover {
        background-color: var(--color-blueHover);
        color: #fff;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.submenu {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 165px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: absolute;
    right: -166px;
    top: 0;

    div {
        margin-right: 0;
    }
}
