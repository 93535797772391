@import "src/mixins/index.scss";

.progressWrapper {
  width: 100%;
  height: rem(24px);
  background-color: #f4f8fb;
  border-radius: 6px;
  overflow: hidden;
}

.progressBar {
  height: rem(24px);
  background: repeating-linear-gradient(45deg, #1280CE, #1280CE 16px, #298CD3 16px, #298CD3 40px) fixed;
}